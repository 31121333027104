import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTicket,
  faSignOut,
  faUsers,
  faGear,
  faDashboard,
  faNetworkWired,
  faAnchorLock,
} from "@fortawesome/free-solid-svg-icons";

const menu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: "/images/material-dashboard.svg",
    submenu: [],
    roleaccess: [1, 2],
  },
  {
    title: "Counter Staff Members",
    path: "#",
    icon: "/images/Iconmaterial-group.svg",
    submenu: [
      {
        title: "All Counters",
        path: "/counter",
      },
      {
        title: "Counter Staff Members",
        path: "/counter-staff",
      },
    ],
    roleaccess: [1],
  },
  {
    title: "Reports",
    path: "/reports",
    icon: "/images/Iconawesome-file-alt.svg",
    submenu: [],
    index: true,
    roleaccess: [1, 2],
  },
  // {
  //   title: 'Settings',
  //   path: '/setting',
  //   icon: <FontAwesomeIcon icon={faGear} />,
  //   submenu: [],
  //   index: true,
  //   roleaccess: [1],
  // },
  {
    title: "Configuration",
    path: "/configuration",
    icon: "/images/ionic-ios-settings.svg",
    submenu: [],
    index: true,
    roleaccess: [1],
  },
  {
    title: "Scroll Lock",
    path: "/scroll-lock",
    icon: "/images/awesome-anchor.svg",
    submenu: [],
    index: true,
    roleaccess: [1],
  },
  {
    title: "Holidays",
    path: "/holidays",
    icon: "/images/holiday.svg",
    submenu: [],
    index: true,
    roleaccess: [1],
  },
  {
    title: "Setting",
    path: "/setting",
    icon: "/images/configuration-icon.png",
    submenu: [],
    index: true,
    roleaccess: [1],
  },
  {
    title: "Billdesk Settlement Report",
    path: "/billdesk-settlement-report",
    icon: "/images/Iconawesome-file-alt.svg",
    submenu: [],
    index: true,
    roleaccess: [1],
  },
  {
    title: "Log out",
    path: "/login",
    icon: "/images/icon-logout.svg",
    submenu: [],
    roleaccess: [1, 2],
  },
];

export default menu;
