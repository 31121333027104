import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import {
  TextInput,
  Helmet,
  PasswordInput,
  DropdownInput,
} from "../../components/FormInputs";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader/index";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";

function AddCounterStaff() {
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const { state } = useLocation();
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordErr, setUserPasswordErr] = useState("");
  const [isShowLoader, setShowLoader] = useState(false);
  const [userId, setUserId] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [count, forceUpdate] = useState(0);
  const [title, setTitle] = useState("");
  const [designation, setDesignation] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [zooId, setZooId] = useState(null);
  const [employeeType, setEmployeeType] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [selectedCounter, setselectedCounter] = useState("");
  const [selectedCounterName, setselectedCounterName] = useState("");
  const [counterList, setCounterList] = useState([]);
  const [counterAdmin, setCounterAdmin] = useState("");
  const [counterAdminStatus, setCounterAdminStatus] = useState("");
  const [alreadyCounterAdmin, setAlreadyCounterAdmin] = useState("");
  const items = [
    { to: "/", label: "Dashboard" },
    { to: "/counter-staff", label: "Counter Staff" },
    { label: userId ? "Edit Counter Staff" : "Add Counter Staff" },
  ];

  const staffArray = [{
    label: 'Staff',
    value: 'Staff'
  }];

  useEffect(() => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(role);
    const zoo = customFunctions.getLocalStorage("Zoo_id");
    setZooId(zoo);
    if (state) {
      getUser(state);
      // getCounterList('edit');
      setUserId(Number(state));
      setTitle("Edit Counter Staff");
    } else {
      setTitle("Add Counter Staff");
      getCounterList("add");
    }

    getCounterAdminStatus();
  }, []);

  // useEffect(() => {
  //   getCounterList('edit');
  // }, [selectedCounter]);

  // useEffect(() => {
  //   getUser(state);
  // }, [state])
  const getUser = async (id) => {
    setShowLoader(true);

    const api = `${Api.users}?role_id=2&id=${id}`;
    const url = customFunctions.generateUrl(api);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (
      data &&
      data.data &&
      data.data.response &&
      data.data.response.rows &&
      data.data.response.rows.length > 0
    ) {
      setShowLoader(false);
      setUserName(data.data.response.rows[0].name);
      setUserMobile(data.data.response.rows[0].mobile);
      setUserEmail(data.data.response.rows[0].email);
      setDesignation(data.data.response.rows[0].designation);
      setCounterAdmin(
        data.data.response.rows[0].isCounterAdmin == "1" ? true : false
      );
      setAlreadyCounterAdmin(
        data.data.response.rows[0].isCounterAdmin == "1" ? true : false
      );
      setEmployeeType(data.data.response.rows[0].employeeType);
      setselectedCounter(Number(data.data.response.rows[0].counter_id));
      setselectedCounterName(data.data.response.rows[0].counter_name || "");
      if (data.data.response.rows[0].employeeType === "contract") {
        setEmployeeNumber(data.data.response.rows[0].vendorId);
      } else {
        setEmployeeNumber(data.data.response.rows[0].employeeId);
      }
      getCounterList(
        "edit",
        data.data.response.rows[0].counter_id,
        data.data.response.rows[0].counter_name || "-"
      );
      // var array = [...counterList];
      // array.push({
      //   label: 'Counte 3',
      //   id: Number(data.data.response.rows[0].counter_id),
      //   value: Number(data.data.response.rows[0].counter_id)
      // })
      // setCounterList([...counterList, ...array]);
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const getCounterList = async (type = "", countId = "", countName = "") => {
    let endPoint = `${Api.counterList}?remaining_counter=true`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success === true && data.data) {
      if (type === "edit") {
        getCounter(data.data.response, "edit", countId, countName);
      }
      if (type === "add") {
        getCounter(data.data.response, "add");
      }
    } else {
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getCounter = (data, type = "", count = "", countName = "") => {
    let options = [...counterList];
    if (type === "edit") {
      data &&
        data.map((counter) => {
          return options.push({
            label: counter.name,
            id: counter.id,
            value: counter.id,
          });
        });
      if (count !== 0) {
        var array = [...options];
        array.push({
          label: countName,
          id: count == 0 ? "" : Number(count),
          value: count == 0 ? "" : Number(count),
        });
        setCounterList([...array]);
        forceUpdate(count + 1);
      } else {
        setCounterList([...options]);
        forceUpdate(count + 1);
      }
    } else {
      data.map((counter) => {
        return options.push({
          label: counter.name,
          id: counter.id,
          value: counter.id,
        });
      });
      setCounterList(options);
      forceUpdate(count + 1);
    }
  };

  const addUser = async () => {
    setShowLoader(true);
    const postData = {
      name: userName.trim(),
      mobile: userMobile,
      email: userEmail,
      password: userPassword,
      designation: designation,
      role_id: Number(2),
      zoo_id: Number(zooId),
      employeeType,
      counter_id: Number(selectedCounter),
      isCounterAdmin: counterAdmin && Number(selectedCounter) > 0 ? "1" : "0",
    };
    if (employeeType === "permanent") {
      postData.employeeId = employeeNumber;
    } else {
      postData.vendorId = employeeNumber;
    }
    if (userId) {
      postData.id = userId;
      delete postData.password;
      delete postData.role_id;
      delete postData.zoo_id;
      delete postData.employeeId;
      delete postData.vendorId;
    }
    const api = `${Api.users}`;
    const url = customFunctions.generateUrl(api);
    const callback = userId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(url, postData);
    if (data) {
      setShowLoader(false);
      swal(
        userId
          ? "Counter staff updated successfully"
          : "Counter staff added successfully",
        "",
        "success"
      );
      setUserName("");
      setUserEmail("");
      setUserMobile(null);
      setUserId(null);
      navigate("/counter-staff");
      setDisableButton(false);
      validator.current.hideMessages();
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const getCounterAdminStatus = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      Api.counterAdminStatus
    );
    if (data && data.success === true) {
      if (data.data == "1") {
        setCounterAdminStatus(true);
      } else {
        setCounterAdminStatus(false);
      }
    } else {
      swal(message || "Something went wrong", "", "error");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (disableButton) return;

    setDisableButton(true);
    let isValid = true;
    let passErr = "";
    setUserPasswordErr("");

    if (!userId) {
      if (userPassword && userPassword.length <= 5) {
        passErr = "New password length should be greater than 5 digits";
        setUserPasswordErr(passErr);
        isValid = false;
      }
    }

    if (validator.current.allValid() && isValid) {
      addUser();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  validator.current.purgeFields();

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={6} className="">
                  <TextInput
                    onInputChange={(e) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setUserName(e.target.value);
                      }
                    }}
                    label="Full Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="userName"
                    value={userName}
                    placeholder="Enter user name"
                    errorMessage={validator.current.message(
                      "User name",
                      userName,
                      "required"
                    )}
                    autocomplete="new-userName"
                  />
                </Col>
                <Col sm={6} className="">
                  <TextInput
                    onInputChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                    label="Email Address"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="userEmail"
                    disabled={userId}
                    value={userEmail}
                    placeholder="Enter email address"
                    errorMessage={validator.current.message(
                      "User email",
                      userEmail,
                      "required|email"
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <TextInput
                    onInputChange={(e) => {
                      const newValue = customFunctions.numberMasking(
                        e.target.value
                      );
                      setUserMobile(newValue);
                    }}
                    label="Mobile Number"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="userMobile"
                    value={userMobile}
                    maxLength={10}
                    placeholder="Enter mobile number"
                    errorMessage={validator.current.message(
                      "User mobile",
                      userMobile,
                      "required|phone"
                    )}
                  />
                </Col>
                {/* <Col sm={6}>
                  <TextInput
                    onInputChange={(e) => {
                      setDesignation(e.target.value);
                    }}
                    label="Designation"
                    // asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="designation"
                    value={designation}
                    placeholder="Enter designation"
                  />
                </Col> */}
                <Col sm={6}>
                  <>
                    <Form.Label>Designation</Form.Label>
                    <div className="dropdown-report">
                      <Form.Select
                        value={designation}
                        selected={designation}
                        className="form-control"
                        onChange={(e) => {
                          setDesignation(e.target.value);
                        }}
                      >
                        <option value=" ">Select designation
                        </option>
                        {staffArray.map((type) => (
                          <option value={type.value}>{type.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  {!userId && (
                    <>
                      <div>
                        <div className="form-wrap">
                          <PasswordInput
                            onInputChange={(e) =>
                              setUserPassword(e.target.value)
                            }
                            name="userPassword"
                            containerClass="form-group"
                            label="Password"
                            value={userPassword}
                            type="password"
                            asterisk="*"
                            placeholder="Please enter old password"
                            errorMessage={
                              !userId
                                ? validator.current.message(
                                  "userPassword",
                                  userPassword,
                                  "required"
                                )
                                : null
                            }
                            switchElement="true"
                            autoComplete="new-password"
                          />
                          <span
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            {userPasswordErr}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Col>
                <Col sm={6}>
                  <div>
                    <Form.Label>
                      Employee Type
                      <span style={{ color: "red" }}> *</span>{" "}
                    </Form.Label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      disabled={userId}
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value={employeeType}
                      checked={employeeType === "permanent"}
                      onChange={(e) => {
                        // if (userId) {
                        //   setEmployeeNumber('')
                        // }
                        setEmployeeType("permanent");
                      }}
                    />
                    <label className="form-check-label" for="inlineRadio1">
                      Permanent
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      disabled={userId}
                      value={employeeType}
                      checked={employeeType === "contract"}
                      onChange={(e) => {
                        // if (userId) {
                        //   setEmployeeNumber('')
                        // }
                        setEmployeeType("contract");
                      }}
                    />
                    <label className="form-check-label" for="inlineRadio2">
                      Contract
                    </label>
                  </div>
                  <span className="text-danger" style={{ fontSize: "14px" }}>
                    {validator.current.message(
                      "Employee Type",
                      employeeType,
                      "required"
                    )}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <>
                    <Form.Label>Assign Counter</Form.Label>
                    <div className="dropdown-report">
                      <Form.Select
                        value={selectedCounter}
                        selected={selectedCounter}
                        disabled={counterList && counterList.length === 0}
                        className="form-control"
                        onChange={(e) => {
                          setselectedCounter(e.target.value);
                        }}
                      >
                        <option value=" ">
                          {" "}
                          {counterList && counterList.length === 0
                            ? "No counter is available"
                            : "Select counter"}{" "}
                        </option>
                        {counterList.map((type) => (
                          <option value={type.value}>{type.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </>
                </Col>

                <Col sm={6}>
                  {
                    employeeType && (
                      <div>
                        <TextInput
                          disabled={!employeeType || userId}
                          onInputChange={(e) => {
                            setEmployeeNumber(e.target.value);
                          }}
                          label={
                            employeeType === "permanent"
                              ? "Employee Id"
                              : "Vendor Id"
                          }
                          asterisk="*"
                          containerClass="form-group"
                          type="text"
                          name="employeeNumber"
                          value={employeeNumber}
                          placeholder={
                            employeeType === "permanent"
                              ? "Enter employee id"
                              : "Enter vendor id"
                          }
                          errorMessage={validator.current.message(
                            "Employee Number",
                            employeeNumber,
                            "required|alpha_num"
                          )}
                        />
                      </div>
                    )
                  }

                </Col>
              </Row>
              <Row className={employeeType ? 'mt-2' : 'mt-4'}>
                <Col sm={6}>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value={counterAdmin}
                      checked={counterAdmin && Number(selectedCounter) > 0}
                      id="flexCheckDefault"
                      onChange={(e) => setCounterAdmin(e.target.checked)}
                      disabled={
                        (counterAdminStatus && !alreadyCounterAdmin) ||
                        Number(selectedCounter) <= 0
                      }
                    />
                    <label class="form-check-label" for="flexCheckDefault">
                      Counter Admin
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="d-flex justify-content-end">
                <Col sm={6}>
                  <div style={{ float: "right" }} className="form-group mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={disableButton}
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setUserName("");
                        setUserEmail("");
                        setUserMobile(null);
                        setUserId(null);
                        navigate("/counter-staff");
                      }}
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
}

export default AddCounterStaff;
