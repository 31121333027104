import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Loader from "../../components/Loader/index";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import CustomFunctions from "../../helper/customFunctions";
import customFunctions from "../../helper/customFunctions";

const HolidayComponent = () => {
  const [holidayList, setHolidayList] = useState([]);
  const [isShowLoader, setShowLoader] = useState(false);

  useEffect(() => {
    getHoliday();
  }, []);

  const getHoliday = async () => {
    setShowLoader(true);
    let endpoint = `${Api.holiday}/list?limit=100`;
    const { data, message } = await AuthApi.getDataFromServer(endpoint);
    if (data && data.data && data.data.rows && data.data.rows.length > 0) {
      setShowLoader(false);
      setHolidayList(data.data.rows);
    } else {
      setShowLoader(false);
      setHolidayList([]);
      swal(message, "", "error");
    }
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Occasion</th>
            <th scope="col">Date</th>
          </tr>
        </thead>

        {holidayList && holidayList.length > 0 ? (
          <tbody>
            {holidayList?.map((hl) => (
              <tr class="trow ">
                <td class="tcol ">{hl.event}</td>
                <td class="tcol ">
                  <span>
                    {hl.date ? customFunctions.getDMYDate(hl.date) : "-"}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tr class="trow ">
            <td className="tcol text-center text-dark p-2" colSpan={2}>
              No record found
            </td>
          </tr>
        )}
      </table>
    </>
  );
};

export default HolidayComponent;
