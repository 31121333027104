import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import { TextInput, Helmet, PasswordInput } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader/index";
import { passwordStrength } from "check-password-strength";
import { useDispatch } from "react-redux";

const items = [{ to: "/", label: "Dashboard" }, { label: "Change Password" }];

function ManagePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validator = useRef(
    new SimpleReactValidator({
      messages: {
        in: "Passwords and Confirm Password need to match!",
      },
    })
  );
  const [password, setPassword] = useState("");
  const [passErr, setPassErr] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [count, forceUpdate] = useState(0);
  const [title, setTitle] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [isShowLoader, setShowLoader] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  useEffect(() => {
    setTitle("Change Password");
  }, []);

  const setNewPassword = async () => {
    setShowLoader(true);
    const payload = {
      // oldpassword: oldPassword,
      // newpassword: password,
      // confirmpassword: confirmPassword,

      oldpassword: customFunctions
        .encryptKey(oldPassword, "2A4274058E1725C")
        .toString(),
      newpassword: customFunctions
        .encryptKey(password, "2A4274058E1725C")
        .toString(),
      confirmpassword: customFunctions
        .encryptKey(confirmPassword, "2A4274058E1725C")
        .toString(),
    };

    const postData = customFunctions.cleanObject(payload);

    const { data, message } = await AuthApi.postDataToServer(
      `${Api.changePasswordUrl}`,
      postData
    );
    if (data && data?.success == true) {
      setShowLoader(false);
      swal("Password has been updated successfully!", "Please log in again with new password.", "success").then((result) => {
        // if (result) {
        // setDisableButton(false);
        validator.current.hideMessages();
        // navigate("/dashboard");
        dispatch({ type: "LOGIN", value: false });
        customFunctions.onLogout();
        navigate("/login");
        // }
      });
    } else {
      setShowLoader(false);
      swal(message, "", "error");
      // setDisableButton(false);
    }
  };

  const validateForm = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setNewPassword();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Change Password</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <div className="mt-3">
          <Card>
            <Card.Body>
              <Form autoComplete="off" onSubmit={(e) => validateForm(e)}>
                <p>
                  <b>Note : </b> Your new password must consist of a combination
                  of uppercase and lowercase letters, numbers, and special
                  characters, ensuring a minimum length of 10 characters.
                </p>
                <Row>
                  <Col className="col-md-4">
                    <PasswordInput
                      onInputChange={(e) => setOldPassword(e.target.value)}
                      name="oldPassword"
                      containerClass="form-group"
                      label="Old Password"
                      value={oldPassword}
                      type="password"
                      asterisk="*"
                      placeholder="Please enter old password"
                      errorMessage={validator.current.message(
                        "oldPassword",
                        oldPassword,
                        "required",
                        {
                          className: "text-danger",
                        }
                      )}
                      switchElement="true"
                    />
                  </Col>
                  <Col className="col-md-4">
                    <PasswordInput
                      onInputChange={(e) => {
                        setPassErr("");
                        setPassword(e.target.value);
                      }}
                      name="password"
                      containerClass="form-group"
                      inputClass="form-control"
                      label="New Password"
                      asterisk="*"
                      value={password}
                      type="password"
                      placeholder="Please enter new password"
                      fromComponent="changePassword"
                      passwordMessage={
                        password
                          ? `Password strength : ${
                              passwordStrength(password).value
                            }`
                          : ""
                      }
                      errorMessage={
                        passErr
                          ? passErr
                          : validator.current.message(
                              "new password",
                              password,
                              "required|min:10",
                              {
                                className: "text-danger",
                              }
                            )
                      }
                      switchElement="true"
                    />
                  </Col>
                  <Col className="col-md-4">
                    <PasswordInput
                      onInputChange={(e) => setConfirmPassword(e.target.value)}
                      name="confirmPassword"
                      containerClass="form-group"
                      inputClass="form-control"
                      label="Confirm Password"
                      asterisk="*"
                      value={confirmPassword}
                      type="password"
                      placeholder="Please enter confirm password"
                      errorMessage={validator.current.message(
                        "confirmPassword",
                        confirmPassword,
                        `required|in:${password}`,
                        {
                          className: "text-danger",
                        }
                      )}
                      switchElement="true"
                    />
                  </Col>
                </Row>
                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={passwordStrength(password).value != "Strong"}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    className="ms-4"
                    onClick={() => navigate("/dashboard")}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </section>
    </>
  );
}
export default ManagePassword;
