import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import { addDays } from "date-fns";
import "../../i18n";
import { Button } from "react-bootstrap";
import Loader from "../../../components/Loader";
import swal from "sweetalert";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

const TransactionFailcomponent = () => {
    const { t, i18n } = useTranslation();
    const [isShowLoader, setShowLoader] = useState(false);
    const { state } = useLocation();

    useEffect(()=>{
      swal(t("Payment has been failed"),"","error");
    },[])

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div className="admin-ticket-booking">
        <header>
          <div className="container-fluid">
            <div className="d-flex justify-content-center">
              <div className="me-3 main-logo">
                <img src="/images/pmclogo.png" alt="logo" />
              </div>
              <div className=" main-logo">
                <img src="/images/pune-zoo-logo.png" alt="logo" />
              </div>
            </div>
          </div>
        </header>
        <section className="position-relative w-100 thankyou-footer-bg">
          <div className="container">
          <section className="position-relative w-100 ">
              <div className="container">
                <div className="summery-header position-relative w-100 text-center">
                  <div className="langauge-block"></div>
                  <h1 className="mb-3">{t("title")}</h1>
                  <hr className="d-none d-md-block" />
                  <p className="d-md-block">
                    <h4 className="text-white text-decoration-none info">
                      {/* <a href="javascript:void(0)" style={{ cursor: 'auto' }} className="text-white text-decoration-none"> */}
                      {t("transactionfail")}
                    </h4>
                    {/* </a> */}
                  </p>
                  {/* <Button
                  className="btn btn-primary ml-500px"
                  onClick={() => {
                    navigate("/");
                    // onBackButtonEvent()
                  }}
                >
                  {t('Button')}
                </Button> */}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      let navigatePath;
                      if (state === "/ticket-booking/qr") {
                        navigatePath = "/ticket-booking/qr";
                      } else {
                        navigatePath = "/";
                      }
                      localStorage.removeItem("ticket_data");
                      window.location.href = navigatePath;
                    }}
                    className="btn btn-secondary"
                  >
                    <img src="/images/arrow-back.svg" alt="icon" />
                    {t("Button")}
                  </a>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  )
}

export default TransactionFailcomponent
