import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Card, Col, Row, Button, Accordion } from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Datepicker, Helmet, TextInput } from "../../components/FormInputs";
import SimpleReactValidator from "simple-react-validator";
import ViewAllTicket from "./viewAllTicketModal";
import { saveAs } from "file-saver";

function BilldeskReport() {
  const validator = useRef(new SimpleReactValidator());
  const fileRef = useRef();
  const [isShowLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [billdeskReportData, setBilldeskReportData] = useState([]);
  const [dailyReportData, setDailyReportData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [title, setTitle] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [count, forceUpdate] = useState(0);
  const [roleId, setRoleId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showErr, setShowErr] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [ticketDate, setTicketDate] = useState("");
  const items = [
    { to: "/dashboard", label: "Dashboard" },
    { label: "Billdesk Settlement Report" },
  ];
  const [file, setFile] = useState(null);

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };

  const [pagination, setPagination] = useState(initialPagination);

  const columns = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Settlement Date",
      id: "date",
    },
    {
      label: "Portal Ticket Count",
      id: "portalTicketCount",
    },
    {
      label: "Portal Amount",
      id: "portalAmount",
    },
    {
      label: "Billdesk Ticket Count",
      id: "billdeskTicketCount",
    },
    {
      label: "Billdesk Amount",
      id: "billdeskAmount",
    },
    {
      label: "Ticket Difference",
      id: "ticketDifference",
    },
    {
      label: "Amount Difference",
      id: "defference",
    },
    {
      label: "Action",
      id: "view",
    },
  ];

  useEffect(() => {
    setTitle("Billdesk Settlement Report");
    const roleId = customFunctions.getLocalStorage("role_id");
    setRoleId(roleId);
    const userId = customFunctions.getLocalStorage("id");
    setUserId(userId);
    getDailyReportData(1, true);
  }, []);

  const onSubmit = () => {
    if (validator.current.allValid()) {
      getDailyReportData(1, false);
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const getBilldeskReportData = async (pageNo, clearFilter) => {
    const page = pageNo || 1;
    setShowLoader(true);

    const params = {};

    if (!clearFilter && fromDate) {
      params.start_date = customFunctions.getDate(fromDate);
    }

    if (!clearFilter && toDate) {
      params.end_date = customFunctions.getDate(toDate);
    }

    const url = customFunctions.generateUrl(Api.billdeskReportDataUrl, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (
        data &&
        data?.data &&
        data?.data?.mergedDataArray &&
        data?.data?.mergedDataArray?.length
      ) {
        setBilldeskReportData(data?.data?.mergedDataArray);
      } else {
        setBilldeskReportData([]);
      }
    } else {
      setShowLoader(false);
      setBilldeskReportData([]);
      swal(message, "", "error");
    }
  };

  const getDailyReportData = async (pageNo, clearFilter) => {
    const page = pageNo || 1;
    setShowLoader(true);

    const params = {
      page,
      limit: 10,
      transaction_status: "success",
      isAll: clearFilter ? "true" : "false",
    };

    if (!clearFilter && fromDate) {
      params.start_date = customFunctions.getDate(fromDate);
    }

    if (!clearFilter && toDate) {
      params.end_date = customFunctions.getDate(toDate);
    }

    const url = customFunctions.generateUrl(Api.dailyReportDataUrl, params);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      setShowLoader(false);
      if (
        data &&
        data?.data &&
        data?.data?.newpayloads?.mergedData &&
        data?.data?.newpayloads?.mergedData?.length
      ) {
        setPagination({
          currentPage: Number(data?.data?.page),
          itemsPerPage: 10,
          totalItems: Number(data?.data?.count),
        });

        setDailyReportData(data?.data?.newpayloads?.mergedData);
        getBilldeskReportData(1, clearFilter);
      } else {
        setDailyReportData([]);
      }
    } else {
      setShowLoader(false);
      setDailyReportData([]);
      swal(message, "", "error");
    }
  };

  useEffect(() => {
    if (billdeskReportData?.length && dailyReportData?.length) {
      const updatedData = [];
      dailyReportData &&
        dailyReportData?.map((element) => {
          const sameDateReport =
            billdeskReportData &&
            billdeskReportData?.find(
              (billdeskData) =>
                billdeskData?.booking_date == element?.bookingDate
            );
          if (sameDateReport) {
            updatedData.push({
              portal_ticket_count: element?.count,
              portal_amount: element?.total_amount,
              billdesk_ticket_count: sameDateReport?.count,
              billdesk_amount: sameDateReport?.amount,
              date: sameDateReport?.booking_date,
            });
          }
        });
      if (updatedData?.length) {
        setAllData(updatedData);
      }
    }
  }, [billdeskReportData, dailyReportData]);

  useEffect(() => {
    if (allData?.length) {
      setTableData();
    }
  }, [allData]);

  const setTableData = () => {
    const rowsData = [];
    allData.map((dt, i) => {
      rowsData.push({
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        date: dt?.date
          ? customFunctions.getDMYDateForCalendar(new Date(dt?.date))
          : "-",
        portalTicketCount: dt?.portal_ticket_count
          ? dt?.portal_ticket_count
          : "-",
        portalAmount: dt?.portal_amount ? dt?.portal_amount : "-",
        billdeskTicketCount: dt?.billdesk_ticket_count
          ? dt?.billdesk_ticket_count
          : "-",
        defference:
          dt?.portal_amount > dt?.billdesk_amount
            ? dt?.portal_amount - dt?.billdesk_amount
            : dt?.billdesk_amount > dt?.portal_amount
            ? dt?.billdesk_amount - dt?.portal_amount
            : "-",
        ticketDifference:
          dt?.portal_ticket_count > dt?.billdesk_ticket_count
            ? dt?.portal_ticket_count - dt?.billdesk_ticket_count
            : dt?.billdesk_ticket_count > dt?.portal_ticket_count
            ? dt?.billdesk_ticket_count - dt?.portal_ticket_count
            : "-",
        billdeskAmount: dt?.billdesk_amount ? dt?.billdesk_amount : "-",
        view: (
          <>
            <a
              title="View Tickets"
              href="javascript:void(0)"
              className="underline"
              onClick={() => {
                setShowPopup(true);
                if (dt?.date) {
                  setTicketDate(dt?.date);
                }
              }}
            >
              View Tickets
            </a>
          </>
        ),
      });
    });
    setRows(rowsData);
  };

  const downloadCSVPDF = async (type) => {
    setShowLoader(true);
    const params = {
      file_type: "pdf",
      start_date: customFunctions.getDate(fromDate),
      end_date: customFunctions.getDate(toDate),
      transaction_status: "success",
    };

    const url = customFunctions.generateUrl(Api.dailyReportDataUrl, params);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data?.data && data?.data?.file) {
        // let filename = data?.data?.file?.split("/");
        // let len = filename[filename?.length - 1];
        // saveAs(data?.data?.file?.blob(), len, { forceSave: true });

        fetch(data?.data?.file)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.blob();
          })
          .then((blob) => {
            // Save the blob as a file using FileSaver.js
            saveAs(blob, "file.pdf"); // Set the desired file name for download
          })
          .catch((error) => {
            console.error("Error downloading PDF:", error);
          });
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const handelChange = (e) => {
    if (!e?.target?.value) return;
    if (
      e?.target?.value !== "" &&
      ["csv"].includes(
        typeof e.target.files[0].name === "string" &&
          e.target.files[0].name.split(".").pop()
      )
    ) {
      if (e.target.files[0].size >= 3000000) {
        setShowLoader(false);
        if (fileRef.current) {
          fileRef.current.value = null;
        }
        swal("File size is too large", " ", "error");
        return;
      } else {
        setFile(e.target.files[0]);
        setShowErr(false);
      }
    } else {
      if (fileRef.current) {
        fileRef.current.value = null;
      }
      swal("File type is not supported", "", "error");
    }
  };

  const uploadDocument = async (file) => {
    const formData = new FormData();
    formData.append("csvFile", file);
    setShowLoader(true);

    const endPoint = `${Api.uploadCSV}`;

    const callback = AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, formData);

    if (data && data.success === true) {
      setShowLoader(false);
      swal("File uploaded successfully!", "", "success");
      if (fileRef.current) {
        fileRef.current.value = null;
      }
      setFile(null);
      getDailyReportData(1, true);
    } else {
      setShowLoader(false);
      swal(message ? message : "Something went wrong!", "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="card-title mb-0">Upload CSV File</h5>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <span className="ml-2 mb-2" style={{ color: "#203442" }}>
                  <b>Note: </b>Upload CSV file up to 3 MB.
                </span>
                <Col sm={3}>
                  <input
                    type="file"
                    accept=".csv"
                    ref={fileRef}
                    onChange={handelChange}
                  />
                  <span className="text-danger">
                    {showErr ? "The file field is required." : ""}
                  </span>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      if (file) {
                        uploadDocument(file);
                      } else {
                        setShowErr(true);
                      }
                    }}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey="0" className="mt-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="card-title mb-0">Date Filter</h5>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col sm={3}>
                  <Datepicker
                    className="form-control"
                    selected={fromDate}
                    onChange={(e) => {
                      setFromDate(e);
                      setToDate(null);
                      // setRows([]);
                      // setBilldeskReportData([]);
                      // setDailyReportData([]);
                      // setAllData([]);
                    }}
                    autoComplete="off"
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                    placeholder={"From Date"}
                    onKeyDown={true}
                    minDate={new Date("11-10-2023")}
                    maxDate={
                      new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000)
                    }
                    errorMessage={validator.current.message(
                      "fromDate",
                      fromDate,
                      "required",
                      {
                        messages: {
                          default: "The from date field is required.",
                        },
                      }
                    )}
                  />
                </Col>
                <Col sm={3}>
                  <Datepicker
                    className="form-control"
                    selected={toDate}
                    onChange={(e) => {
                      setToDate(e);
                      // setRows([]);
                      // setBilldeskReportData([]);
                      // setDailyReportData([]);
                      // setAllData([]);
                    }}
                    autoComplete="off"
                    name="endDate"
                    dateFormat="dd/MM/yyyy"
                    placeholder={"To Date"}
                    onKeyDown={true}
                    minDate={new Date(fromDate)}
                    maxDate={
                      new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000)
                    }
                    // errorMessage={validator.current.message(
                    //   "toDate",
                    //   toDate,
                    //   "required",
                    //   {
                    //     messages: {
                    //       default: "The to date field is required.",
                    //     },
                    //   }
                    // )}
                  />
                </Col>
                <Col sm={6}>
                  <Button
                    className="mr-3"
                    onClick={(e) => {
                      e.preventDefault();
                      onSubmit();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setToDate("");
                      setFromDate("");
                      setRows([]);
                      setBilldeskReportData([]);
                      setDailyReportData([]);
                      setAllData([]);
                      validator.current.hideMessages();
                      forceUpdate(count + 1);
                      getDailyReportData(1, true);
                    }}
                  >
                    Clear
                  </Button>
                </Col>
                <Col></Col>
              </Row>
              {rows?.length ? (
                <>
                  <hr />
                  <Card>
                    <Table
                      columns={columns}
                      rows={rows}
                      showPagination
                      paginationProps={{
                        ...pagination,
                        activePage: pagination?.currentPage,
                        onPageChange: (pageNo) => {
                          getDailyReportData(pageNo, fromDate ? false : true);
                        },
                      }}
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    />
                  </Card>
                </>
              ) : null}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {showPopup && (
          <ViewAllTicket
            show={showPopup}
            popupClose={() => {
              setShowPopup(!showPopup);
            }}
            ticketDate={ticketDate}
          />
        )}
      </section>
    </>
  );
}

export default BilldeskReport;
