import React from "react";
import ReactApexChart from "react-apexcharts";

const MultipleArea = (props) => {
  const { monthIncome } = props;
  const options = {
    dataLabels: {
      enabled: false,
    },
    chart: {
      toolbar: {
        show: true,
        // offsetX: 0,
        // offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      // colors: ['#fb7b00', '#0895e1', '#156539'],
      width: 0,
      dashArray: 0,
    },
    colors: ["#fb7b00", "#0895e1", "#156539"],
    xaxis: {
      type: "month",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      // categories: month
      title: {
        text: "Months",
        font: {
          size: 14,
        },
      },
    },
    yaxis: {
      title: {
        text: "Collection Amount In Rupees",
        font: {
          size: 14,
        },
      },
    },
    tooltip: {
      x: {
        format: "month",
      },
    },
  };

  const series = [
    {
      name: "Online Collection amount",
      data: monthIncome.onlineIncome,
    },
    {
      name: "Offline Collection amount",
      data: monthIncome.offlineIncome,
    },
    {
      name: "QR Collection amount",
      data: monthIncome.qrIncome,
    },
  ];

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="250"
      />
    </>
  );
};

export default MultipleArea;
