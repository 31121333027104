import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  Image,
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
} from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import {
  Datepicker,
  Helmet,
  TextInput,
  DropdownInput,
} from "../../components/FormInputs";
import ReportPopup from "./reportPopUp";
import CountUp from "react-countup";

function Reports() {
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [bookingReportData, setBookingReportData] = useState([]);
  const [title, setTitle] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterErr, setFilterErr] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [userList, setUserList] = useState([]);
  const [counterList, setCounterList] = useState([]);
  const [count, forceUpdate] = useState(0);
  const [roleId, setRoleId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [zooId, setZooID] = useState(0);
  const [reportData, setReportData] = useState("");
  const [reportPopUp, setReportPopUp] = useState(false);
  const [applyToggle, setApplyToggle] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState("success");
  const [selectedBookingType, setSelectedBookingType] = useState("");
  const [counterstaffId, setCounterstaffId] = useState("");
  const [counterId, setCounterId] = useState("");
  const [totalTicketCount, setTotalTicketCount] = useState(0);
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const items = [{ to: "/", label: "Dashboard" }, { label: "Reports" }];
  const [selectedCounter, setSelectedCounter] = useState(null);

  const paymentType = [
    {
      label: "Cash",
      value: "cash",
    },
    {
      label: "UPI",
      value: "upi",
    },
    {
      label: "Card",
      value: "card",
    },
  ];

  const transactionStatus = [
    {
      label: "Success",
      value: "success",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Fail",
      value: "fail",
    },
  ];

  const bookingType = [
    {
      label: "Online",
      value: "online",
    },
    // {
    //   label: "Offline",
    //   value: "offline",
    // },
    {
      label: "QR",
      value: "qr",
    },
  ];

  const careType = [
    {
      label: "All",
      value: "0",
    },
    {
      label: "PMC Zoo",
      value: "2",
    },
    {
      label: "PMC Care",
      value: "13",
    },
  ];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };

  const [pagination, setPagination] = useState(initialPagination);
  const columns = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Ticket No.",
      id: "ticketNo",
    },
    {
      label: "Order ID",
      id: "orderID",
    },
    // {
    //   label: "Counter",
    //   id: "counterNo",
    // },
    {
      label: "Visit Date",
      id: "visitDate",
    },
    {
      label: "Booking Date",
      id: "bookingDate",
    },
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Mobile No.",
      id: "mobile",
    },
    {
      label: "Quantity",
      id: "qty",
    },
    {
      label: "Price",
      id: "price",
    },
    // {
    //   label: "Booking Type",
    //   id: "bookingType",
    // },
    // {
    //   label: "Payment Mode",
    //   id: "paymentMode",
    // },
    // {
    //   label: "Transaction Status",
    //   id: "payStatus",
    // },
    {
      label: "Redeem Date-Time",
      id: "redeemDate",
      return: (row) => {},
    },
    {
      label: "Redeem Status",
      id: "redeem_status",
    },
  ];

  useEffect(() => {
    setTitle("Reports");
    const roleId = customFunctions.getLocalStorage("role_id");
    setRoleId(roleId);
    const userId = customFunctions.getLocalStorage("id");
    setUserId(userId);
    // const zoo = customFunctions.getLocalStorage("Zoo_id");
    // setZooID(zoo);
    getCounterList();
  }, []);

  useEffect(() => {
    // if (!selectedBookingType) return;
    if (selectedBookingType === "offline") {
      setSelectedTransaction("success");
    } else {
      setSelectedTransaction("");
    }
  }, [selectedBookingType]);

  useEffect(() => {
    getReports(1);
    // getUserData();
  }, [roleId]);

  useEffect(() => {
    setTableData();
  }, [bookingReportData]);

  const getReports = async (pageNo, clearFilter = false) => {
    if (!roleId) return;
    const page = pageNo || 1;
    setShowLoader(true);

    // let role = roleId == 2 ? `&counterstaff_id=${Number(userId)}&start_date=${customFunctions.getDate(new Date)}` : ''
    let role = roleId == 2 ? `&counterstaff_id=${Number(userId)}` : "";
    let endpoint = `${Api.bookingReports}?page=${page}&limit=${Number(
      pagination.itemsPerPage
    )}&zoo_id=${zooId}${role}&transaction_status=success`;

    if (searchKeyword && !clearFilter) endpoint += `&search=${searchKeyword}`;
    if (fromDate && !clearFilter)
      endpoint += `&start_date=${customFunctions.getDate(fromDate)}`;
    if (toDate && !clearFilter)
      endpoint += `&end_date=${customFunctions.getDate(toDate)}`;
    if (selectedPaymentType && !clearFilter)
      endpoint += `&payment_type=${selectedPaymentType}`;
    // if (selectedTransaction && selectedTransaction !== ' ' && !clearFilter) endpoint += `&transaction_status=${selectedTransaction}`;
    if (selectedBookingType && selectedBookingType !== " " && !clearFilter)
      endpoint += `&booking_type=${selectedBookingType}`;
    if (counterstaffId && counterstaffId !== " " && !clearFilter)
      endpoint += `&counterstaff_id=${counterstaffId}`;
    if (
      counterId &&
      counterId !== " " &&
      !clearFilter &&
      selectedBookingType !== "online"
    )
      endpoint += `&counter_name=${counterId}`;
    if (
      selectedCounter &&
      selectedCounter.length > 0 &&
      !clearFilter &&
      selectedBookingType !== "online"
    ) {
      var counterIds = [];
      selectedCounter.map((select) => {
        counterIds.push(select.label);
      });
      endpoint += `&counter_name=${counterIds.join()}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endpoint);
    if (data && data.success === true) {
      if (
        data &&
        data.data &&
        data.data.response &&
        data.data.response.rows &&
        data.data.response.rows.length > 0
      ) {
        setShowLoader(false);
        setPagination({
          currentPage: data.data.page,
          itemsPerPage: Number(10),
          totalItems: data.data.response.count,
        });
        setBookingReportData(data.data.response.rows);
        setTotalTicketCount(data.data.response.count);
        setTotalVisitors(data.data.countArray[0].totalVisiter);
        setTotalIncome(data.data.countArray[0].Total_price);
        forceUpdate(count + 1);
      } else {
        setShowLoader(false);
        setBookingReportData([]);
        setTotalTicketCount(0);
        setTotalVisitors(0);
        setTotalIncome(0);
        setTotalTicketCount(0);
        forceUpdate(count + 1);
      }
    } else {
      setShowLoader(false);
      setBookingReportData([]);
      forceUpdate(count + 1);
      swal(message, "", "error");
    }
  };

  const setTableData = () => {
    const rowsData = [];
    bookingReportData.map((dt, i) => {
      rowsData.push({
        id: dt.id || "-",
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        mobile: dt.mobile_no || "-",
        name: dt.name || "-",
        qty: dt.total_counts[0].total_count,
        price: "₹" + dt.total_counts[0].total_amount,
        orderID: dt?.order_id ? dt?.order_id : "-",
        bookingDate: customFunctions.getDMYDate(dt.total_counts[0].createdAt),
        //  +
        // " " +
        // dt.total_counts[0].time_slot
        visitDate: dt.total_counts[0].booking_date,
        paymentMode: dt.payment_type
          ? customFunctions.capitalizeFirstLetter(dt.payment_type)
          : "-",
        payStatus: dt.transaction_status
          ? customFunctions.capitalizeFirstLetter(dt.transaction_status)
          : "-",
        bookingType:
          dt.booking_type !== "qr"
            ? customFunctions.capitalizeFirstLetter(dt.booking_type)
            : dt.booking_type === "qr"
            ? dt.booking_type.toUpperCase()
            : "-",
        counterNo: dt.counter_name || "-",
        ticketNo: (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title="View Report"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setReportData(dt);
                setReportPopUp(true);
              }}
            >
              {dt.ticket_no}
            </a>
          </>
        ),
        redeemDate: dt?.redeemDate
          ? `${customFunctions.getDMYDate(
              dt?.redeemDate
            )} ${dt?.redeemTimes}`
          : "-",
        redeem_status: dt?.redeem_status,
      });
    });
    setRows(rowsData);
  };

  const getCounterList = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.counterList}`
    );
    if (data && data.success === true) {
      const option = [];
      if (data.data && data.data.response) {
        data.data.response.map((res) => {
          option.push({
            value: res.id,
            label: res.name,
          });
        });
        setCounterList(option);
      }
    } else {
      swal(message || "Something went wrong", "", "error");
    }
  };

  // const getUserData = async () => {
  //   const endpoint = `${Api.users}?page=1&limit=25&role_id=2`;
  //   const { data, message } = await AuthApi.getDataFromServer(endpoint);
  //   if (
  //     data &&
  //     data.data &&
  //     data.data.response &&
  //     data.data.response.rows &&
  //     data.data.response.rows.length > 0
  //   ) {
  //     setShowLoader(false);
  //     setUserList(data.data.response.rows);
  //   }
  //   else {
  //     swal(message || 'Something went wrong', '', 'error');
  //   }
  // }

  const filterSearch = (e) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (
      customFunctions.checkIfEmpty(searchKeyword) &&
      customFunctions.checkIfEmpty(fromDate) &&
      customFunctions.checkIfEmpty(toDate) &&
      customFunctions.checkIfEmpty(selectedPaymentType) &&
      // customFunctions.checkIfEmpty(selectedTransaction) &&
      // customFunctions.checkIfEmpty(selectedBookingType) &&
      customFunctions.checkIfEmpty(zooId) &&
      customFunctions.checkIfEmpty(counterId) &&
      customFunctions.checkIfEmpty(selectedCounter, "A")
    ) {
      filterSearchErr = "Please select atleast one filter";
      setFilterErr(filterSearchErr);
      isValid = false;
    }

    if (!customFunctions.checkIfEmpty(fromDate)) {
      if (customFunctions.checkIfEmpty(toDate)) {
        filterSearchErr = "Please select To date";
        setFilterErr(filterSearchErr);
        isValid = false;
      }
    } else if (!customFunctions.checkIfEmpty(toDate)) {
      filterSearchErr = "Please select From date";
      setFilterErr(filterSearchErr);
      isValid = false;
    }
    if (isValid) {
      setFilterErr("");
      getReports(1);
    }
  };

  const onClear = (e) => {
    e.preventDefault();
    setFilterErr("");
    setFromDate(null);
    setToDate(null);
    setSearchKeyword("");
    setSelectedPaymentType("");
    setSelectedTransaction("");
    setSelectedBookingType("");
    setCounterId("");
    setZooID(0);
    setSelectedCounter(null);
    setApplyToggle(false);
    // setCntClearFilter(cntClearFilter + 1);
    getReports(1, true);
    forceUpdate(count + 1);
  };

  const downloadCSVPDF = async (type) => {
    setShowLoader(true);
    const postData = {
      start_date:
        applyToggle && fromDate ? customFunctions.getDate(fromDate) : "",
      end_date: applyToggle && toDate ? customFunctions.getDate(toDate) : "",
      zoo_id: Number(zooId),
      file_type: type,
      search: searchKeyword && applyToggle ? searchKeyword : "",
      findAll: Number(1),
      counterstaff_id: roleId == 2 ? userId : "",
      transaction_status: selectedTransaction,
      payment_type: selectedPaymentType,
      transaction_status: "success",
    };
    if (selectedBookingType && selectedBookingType !== " ") {
      postData.booking_type = selectedBookingType;
    }
    if (
      selectedCounter &&
      selectedCounter.length > 0 &&
      selectedBookingType !== "online"
    ) {
      var counterIds = [];
      selectedCounter.map((select) => {
        counterIds.push(select.label);
      });

      postData.counter_name = counterIds.join();
    }
    const payload = customFunctions.cleanObject(postData);
    const endPoint = Api.csvPdfDownload;
    const url = customFunctions.generateUrl(endPoint, payload);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data) {
      setShowLoader(false);
      window.open(data.data.file, "_blank");
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="card-title mb-0">Generate Report</h5>
            </Accordion.Header>
            <Accordion.Body>
              {/* <Card>
                <Card.Body> */}
              <Form onSubmit={(e) => filterSearch(e)}>
                {filterErr && (
                  <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                    <div className="img-text">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={(e) => onClear(e)}
                        src="/images/fail.svg"
                        alt="Success"
                      />{" "}
                      {filterErr}
                    </div>
                  </div>
                )}
                <Row>
                  <Col sm={3}>
                    <TextInput
                      label="Search"
                      value={searchKeyword}
                      placeholder="Search by Ticket no/Name/Mobile No"
                      onInputChange={(e) => {
                        setSearchKeyword(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={3} className="admin-datepicker">
                    <Form.Label>From Date</Form.Label>
                    <Datepicker
                      className="form-control"
                      selected={fromDate}
                      onChange={(e) => {
                        setFilterErr("");
                        setToDate(null);
                        setFromDate(e);
                      }}
                      autoComplete="off"
                      name="startDate"
                      dateFormat="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      // maxDate={new Date()}
                      // stopInputChange={(e) => { e.preventDefault(); }}
                    />
                  </Col>

                  <Col sm={3} className="admin-datepicker">
                    <Form.Label>To Date</Form.Label>
                    <Datepicker
                      className="form-control"
                      selected={toDate}
                      onChange={(e) => {
                        setFilterErr("");
                        setToDate(e);
                      }}
                      autoComplete="off"
                      minDate={fromDate}
                      name="startDate"
                      dateFormat="dd/MM/yyyy"
                      placeholder="DD/MM/YYYY"
                      // maxDate={new Date()}
                      disabled={!fromDate}
                      // stopInputChange={(e) => { e.preventDefault(); }}
                    />
                  </Col>

                  {/* <Col sm={3}>
                    <Form.Label>Booking Type</Form.Label>
                    <div className="dropdown-report">
                      <Form.Select
                        value={selectedBookingType}
                        selected={selectedBookingType}
                        className="form-control"
                        onChange={(e) => {
                          if (e.target.value === "offline") {
                            setSelectedCounter(null);
                          }
                          if (e.target.value === "online" || "qr") {
                            setSelectedCounter(null);
                          }
                          setSelectedBookingType(e.target.value);
                        }}
                        rows
                      >
                        <option value=" ">All</option>
                        {bookingType.map((type) => (
                          <option value={type.value}>{type.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </Col> */}
                  {/* <Col sm={3}> */}
                  {/* <DropdownInput
                      label="Counter"
                      placeholder="All"
                      options={counterList}
                      defaultValue={selectedCounter}
                      onSelectHandler={(select) => {
                        setSelectedCounter(select);
                      }}
                      isMulti
                      disabled={["online", "qr"].includes(selectedBookingType)}
                    /> */}
                  {/* <Form.Label>Counter</Form.Label>
                    <div className="dropdown-report">
                      <Form.Select
                        value={counterId}
                        selected={counterId}
                        className="form-control"
                        disabled={['online', 'qr'].includes(selectedBookingType)}
                        onChange={(e) => {
                          setCounterId(e.target.value)
                        }}
                      >
                        <option value=" ">All</option>
                        {
                          counterList.map((counter) => (
                            <option value={counter.name}>
                              {counter.name}
                            </option>
                          ))
                        }
                      </Form.Select>
                    </div> */}
                  {/* </Col> */}
                  {/* <Col sm={3}>
                    <Form.Label>Payment Type</Form.Label>
                    <div className="dropdown-report">
                      <Form.Select
                        value={selectedPaymentType}
                        selected={selectedPaymentType}
                        className="form-control"
                        onChange={(e) => {
                          setSelectedPaymentType(e.target.value)
                        }}
                      >
                        <option value=" ">All payment type</option>
                        {
                          paymentType.map((type) => (
                            <option value={type.value}>
                              {type.label}
                            </option>
                          ))
                        }
                      </Form.Select>
                    </div>
                  </Col> */}

                  <Col sm={3}>
                    <Form.Label>Choose Agent</Form.Label>
                    <div className="dropdown-report">
                      <Form.Select
                        value={zooId}
                        selected={zooId}
                        className="form-control"
                        onChange={(e) => {
                          setZooID(e.target.value);
                        }}
                        rows
                      >
                        {careType.map((type) => (
                          <option value={type?.value}>{type?.label}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between">
                  <Col sm={3}>
                    <div className="filter-btn pull-right filter-btn-report">
                      <Button
                        variant="primary"
                        type="submit"
                        className="ms-4"
                        onClick={() => setApplyToggle(true)}
                      >
                        Apply
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="ms-4"
                        onClick={(e) => onClear(e)}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
              {/* </Card.Body>
              </Card> */}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="mt-3">
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                  <h5 className="card-title mb-0">Reports Summary</h5>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <div className="card dashboard-block1 mb-0">
                    <div className="card-body">
                      <div class="d-flex">
                        <div class="flex-shrink-0">
                          <img src="images/ticket.svg" />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h4>Total Tickets</h4>
                          {/* <h2>{totalTicketCount}</h2> */}
                          <h2>
                            <CountUp
                              start={
                                Number(totalTicketCount) > 10
                                  ? Number(totalTicketCount) - 10
                                  : 0
                              }
                              end={totalTicketCount}
                            />
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card dashboard-block1 mb-0">
                    <div className="card-body">
                      <div class="d-flex">
                        <div class="flex-shrink-0">
                          <img src="images/visitor.svg" />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h4>Total Visitors</h4>
                          {/* <h2>{totalVisitors}</h2> */}
                          <h2>
                            <CountUp
                              start={
                                Number(totalVisitors) > 10
                                  ? Number(totalVisitors) - 10
                                  : 0
                              }
                              end={totalVisitors}
                            />
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="card dashboard-block1 mb-0">
                    <div className="card-body">
                      <div class="d-flex">
                        <div class="flex-shrink-0">
                          <img src="images/ticket.svg" />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h4>Total Sell</h4>
                          {/* <h2>{totalIncome}</h2> */}
                          <h2>
                            <span style={{ fontSize: "20px" }}>₹</span>
                            <CountUp
                              start={Number(totalIncome) > 0}
                              end={totalIncome}
                            />
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <Card>
          <Card.Header>
            <div className="">
              {bookingReportData && bookingReportData.length > 0 && (
                <a
                  href="javascript:void(0)"
                  className="underline"
                  style={{ marginLeft: "5px", float: "right" }}
                  onClick={() => downloadCSVPDF("pdf")}
                >
                  Download PDF
                </a>
              )}
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              rows={rows}
              showPagination
              paginationProps={{
                ...pagination,
                activePage: pagination.currentPage,
                onPageChange: (pageNo) => {
                  getReports(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
      {reportPopUp && (
        <ReportPopup
          reportPopUp={reportPopUp}
          popupClose={() => setReportPopUp(!reportPopUp)}
          reportData={reportData}
        />
      )}
    </>
  );
}

export default Reports;
