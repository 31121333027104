import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import customFunctions from "../../../helper/customFunctions";
import { saveAs } from "file-saver";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../../../components/Loader";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import "../../i18n";
import Api from "../../../helper/api";

function Ticket() {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [id, setId] = useState(null);
  const [isShowLoader, setShowLoader] = useState(false);
  const [ticketData, setTicketData] = useState(null);

  useEffect(() => {
    const params = Object.fromEntries([...queryParameters]);
    //new changes
    // if (params?.me !== null){
    setId(params.me);
    //  }
  }, [queryParameters]);

  const baseURL = Api.getNewUrl()
  
  useEffect(() => {
    if (!id) return;
    getTicketData();
  }, [id]);

  const getTicketData = async () => {
    let url = `${baseURL}order/payment/create/${id}`;
    setShowLoader(true);
    await axios
      .get(url)
      .then((res) => {
        setShowLoader(false);
        setTicketData(res?.data?.data);
      })
      .catch((error) => {
        setShowLoader(false);
        swal(
          error.response.data.message
            ? error.response.data.message
            : "Something went wrong! Please try again later",
          "",
          "error"
        ).then(() => {
          navigate("/");
        });
      });
  };

  useEffect(() => {
    const stateData = customFunctions.getDataFromLocalStorage(
      "ticket_data",
      true
    );
    if (stateData) {
      setTicketData(stateData);
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    let navigatePath;
    if (state === "/ticket-booking/qr") {
      navigatePath = "/ticket-booking/qr";
    } else {
      navigatePath = "/";
    }
    localStorage.removeItem("ticket_data");
    window.location.href = navigatePath;
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div className="admin-ticket-booking">
        <header>
          <div className="container-fluid">
            <div className="d-flex justify-content-center">
              <div className="me-3 main-logo">
                <img src="/images/pmclogo.png" alt="logo" />
              </div>
              <div className=" main-logo">
                <img src="/images/pune-zoo-logo.png" alt="logo" />
              </div>
            </div>
          </div>
        </header>
        <section className="position-relative w-100 thankyou-footer-bg">
          <div className="container">
            {/* {id === null ? "Transaction Fail" :  */}
            <div className="thank-you-section">
              <div className="row">
                <div className="thank-you-inner justify-content-center d-flex align-items-center">
                  <div className="tikcet-preview tikcet-bg-style">
                    <ul className="d-flex list-unstyled logo-wrapper justify-content-center">
                      <li>
                        <img
                          src="/images/pmclogo.png"
                          className="img-fluid"
                          alt="img"
                        />
                      </li>
                      <li>
                        <img
                          src="/images/pune-zoo-logo.png"
                          className="img-fluid"
                          alt="img"
                        />
                      </li>
                    </ul>
                    <h4 className="title">
                      {/* Rajiv Gandhi Zoological Park and Wildlife Research Center,
                      Pune */}
                      {t("title")}
                    </h4>
                    <div className="qr-block">
                      {/* <h6>*Scan to view your ticket</h6> */}
                      <h6
                        style={
                          customFunctions.getLocalStorage("lan") == "mr"
                            ? { marginLeft: "-10px" }
                            : null
                        }
                      >
                        {t("*Scan to view your ticket")}
                      </h6>
                      <img
                        src={
                          ticketData?.qr_code
                            ? ticketData?.qr_code
                            : "/images/qr-code.png"
                        }
                        alt="icon"
                      />
                    </div>
                    {/* <h6 className="date-text">
                Monday, 28th March 2022 | 10.00 AM
              </h6> */}
                    <h6 className="date-text">
                      {t("Valid For Date :")} {ticketData?.booking_date}{" "}
                      {"5:00 PM"}
                    </h6>
                    <h6 className="date-text">
                      {t("Booking Date :")} {ticketData?.currDate}{" "}
                      {ticketData?.booking_time}{" "}
                    </h6>
                    <h6 className="date-text">
                      {t("Ticket No :")} {ticketData?.ticket_no}
                    </h6>
                    <h6 className="date-text">
                      {t("Name :")} {ticketData?.name}
                    </h6>
                    {/* <div className="row">
                <div className="col-6">
                  <div className="booking-name">Name: {ticketData?.name}</div>
                </div>
                <div className="col-6">
                  <div className="ticket-number">
                    Ticket No: {ticketData?.ticket_no}
                  </div>
                </div>
              </div> */}

                    <table
                      className="ticket-summary"
                      style={{ width: "100%", height: "auto" }}
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <thead>
                        <tr>
                          <th>{t("tb_title")}</th>
                          <th>{t("tb_rate")}</th>
                          <th>{t("Qty")}</th>
                          <th>{t("tb_price")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ticketData?.items &&
                          ticketData?.items.length > 0 &&
                          ticketData?.items.map((tk, i) => (
                            <tr key={i}>
                              <td>{t(tk.type)}</td>
                              <td>₹{tk.ticketRate}</td>
                              <td>{tk.count}</td>
                              <td>₹{tk.total}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <table
                      className="ticket-summary ticket-subtotal"
                      style={{ width: "100%", height: "auto" }}
                      cellSpacing="0"
                      cellPadding="0"
                    >
                      <tbody>
                        <tr>
                          <td>{t("subtotal")}</td>
                          <td>₹{ticketData?.total_amount}</td>
                        </tr>
                        <tr>
                          <td>{t("tax")}</td>
                          <td>{t("₹")}0</td>
                        </tr>
                      </tbody>
                    </table>
                    <ul className="total-wrapper d-flex list-unstyled justify-content-end">
                      <li>{t("total")}</li>
                      <li style={{ textAlign: "right" }}>
                        ₹{ticketData?.total_amount}
                      </li>
                    </ul>
                    {/* <div>
              <h4 className="title">Thank You!</h4>
              </div> */}
                  </div>
                  <div className="thankyou-block w-100 position-relative text-center d-none d-md-block">
                    <div className="img-wrapper">
                      <img src="/images/right-check.svg" alt="icon" />
                    </div>
                    <h1>{t("Thank You!")}</h1>
                    <h5>
                      {/* Your payment of ₹{ticketData?.total_amount} has been DONE
                      and your payment ID is {ticketData?.ticket_no} */}
                      {localStorage.getItem("lan") == "mr"
                        ? customFunctions.getLocalStorage("PaymentSuccess") ==
                          "true"
                          ? null
                          : `तुमचे ₹${ticketData?.total_amount} चे पेमेंट झाले आहे आणि तुमचा तिकीट क्रमांक ${ticketData?.ticket_no} आहे.`
                        : customFunctions.getLocalStorage("PaymentSuccess") ==
                          "true"
                        ? null
                        : `Your payment of ₹${ticketData?.total_amount} has been DONE and your Ticket Number is ${ticketData?.ticket_no}`}
                    </h5>
                    <ul className="list-unstyled d-flex justify-content-center action-wrapper">
                      <li className="me-3">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            let navigatePath;
                            if (state === "/ticket-booking/qr") {
                              navigatePath = "/ticket-booking/qr";
                            } else {
                              navigatePath = "/";
                            }
                            localStorage.removeItem("ticket_data");
                            window.location.href = navigatePath;
                          }}
                          className="btn btn-secondary"
                        >
                          <img src="/images/arrow-back.svg" alt="icon" />
                          {t("Go Back")}
                        </a>
                      </li>
                      <li>
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            let filename = ticketData?.url.split("/");
                            let len = filename[filename.length - 1];
                            saveAs(ticketData?.url, len);
                            // window.open(ticketData.url, "_blank");
                          }}
                          className="btn btn-secondary"
                        >
                          {t("Download Ticket")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row d-block d-md-none text-center">
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    let filename = ticketData?.url.split("/");
                    let len = filename[filename.length - 1];
                    saveAs(ticketData?.url, len);
                    // window.open(ticketData?.url, "_blank");
                  }}
                  className="btn btn-praimary download-ticket "
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModal"
                >
                  Download Ticket{" "}
                </a>
              </div>
            </div>
            {/* } */}
          </div>
        </section>
        <div
          className="modal fade thank-you-mobile"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="img-wrapper">
                  <img src="/images/green-right-check.svg" alt="icon" />
                </div>
                <h2>Thank You!</h2>
                <p>
                  Your payment of Rs.8797987 is successful. The payment receipt
                  link has been sent to your contact number via SMS.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ticket;
