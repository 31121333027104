import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { TextInput, Helmet } from "../../components/FormInputs";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader/index";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";

function AddCounterComponent() {
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const { state } = useLocation();
  const [counterName, setCounterName] = useState("");
  const [title, setTitle] = useState("");
  const [isShowLoader, setShowLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [userId, setUserId] = useState(null);
  const [count, forceUpdate] = useState(0);

  const items = [
    { to: "/", label: "Dashboard" },
    { to: "/counter", label: "Counter" },
    { label: userId ? "Edit Counter" : "Add Counter" },
  ];

  useEffect(() => {
    if (state && state.uid) {
      getUser(state.uid);
      setUserId(state.uid);
      setTitle("Edit Counter");
    }
    else {
      setTitle("Add Counter");
    }
  }, [state]);

  const getUser = async (id) => {
    setShowLoader(true);

    const api = `${Api.counterList}?id=${id}`;
    const url = customFunctions.generateUrl(api);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (
      data &&
      data.data &&
      data.data.response.length > 0
    ) {
      setShowLoader(false);
      setCounterName(data.data.response[0].name);
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const addCounter = async () => {
    setShowLoader(true);
    const postData = {
      name: counterName.trim()
    };
    if (userId) {
      postData.id = userId;
    }
    const api = `${Api.counterList}`;
    const url = customFunctions.generateUrl(api);
    const callback = userId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(url, postData);
    if (data) {
      setShowLoader(false);
      swal(
        userId ? "Counter updated successfully" : "Counter  added successfully",
        "",
        "success"
      );
      setCounterName("");
      setUserId(null);
      navigate("/counter");
      setDisableButton(false);
      validator.current.hideMessages();
      forceUpdate(count + 1);
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (disableButton) return;
    setDisableButton(true);
    if (validator.current.allValid()) {
      addCounter();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={6} className="">
                  <TextInput
                    onInputChange={(e) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setCounterName(e.target.value);
                      }
                    }}
                    label="Counter Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="counterName"
                    value={counterName}
                    placeholder="Enter counter name"
                    errorMessage={validator.current.message(
                      "Counter name",
                      counterName,
                      "required|alpha_num_space"
                    )}
                    autocomplete="new-counterName"
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-start">
                <Col sm={6}>
                  <div className="form-group mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={disableButton}
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        validator.current.hideMessages();
                        forceUpdate(count + 1);
                        setCounterName("");
                        setUserId(null);
                        navigate("/counter");
                      }}
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
}

export default AddCounterComponent;
