import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table'

const ScrollPopupComponent = (props) => {
  const { scrollData } = props;

  return (
    <>
      <div>
        <Modal
          className={'filter-popup'}
          size="md"
          centered
          onHide={props.popupClose}
          show={props.scrollPopUp}
        >
          <div className="add-task-popup schedule-filter-popup">
            <Modal.Header className="col-12">
              <span style={{ fontWeight: 'bold' }}>
                Scroll Lock Details
              </span>
              <button onClick={() => props.popupClose()} type="button" class="btn-close"></button>
            </Modal.Header>
            <Modal.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      Counter
                    </th>
                    <th>
                      Ticket Count
                    </th>
                    <th>
                      Assignee Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    scrollData.counter.map((lock) => (
                      <tr>
                        <>
                          <td>
                            {lock.counter_name}
                          </td>
                          <td>
                            {lock.count}
                          </td>
                          <td>
                            {lock.assign_counter}
                          </td>
                        </>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>

            </Modal.Body>
          </div>
        </Modal>
      </div >
    </>
  )
}

export default ScrollPopupComponent;