import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import swal from "sweetalert";
import { Button } from "react-bootstrap";
import { enc } from "crypto-js";
import CustomFunctions from "../../../helper/customFunctions";
import AuthApi from "../../../helper/authApi";
import Api from "../../../helper/api";
import { Helmet } from "../../../components/FormInputs";
import Loader from "../../../components/Loader";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const [count, forceUpdate] = useState(0);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [title, setTitle] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [switchItem, setSwitchItem] = useState(false);
  const [isShowLoader, setShowLoader] = useState(false);

  const checkRememberMe = () => {
    let emailLocal = "";
    if (CustomFunctions.getLocalStorage("email")) {
      const decryptedEmailString = CustomFunctions.decryptKey(
        CustomFunctions.getLocalStorage("email"),
        CustomFunctions.getAESKey()
      );
      emailLocal = decryptedEmailString.toString(enc.Utf8);
    }

    let passwordLocal = "";
    if (CustomFunctions.getLocalStorage("password")) {
      const decryptedStringPasswordString = CustomFunctions.decryptKey(
        CustomFunctions.getLocalStorage("password"),
        CustomFunctions.getAESKey()
      );
      passwordLocal = decryptedStringPasswordString.toString(enc.Utf8);
    }
    const checkboxLocal = CustomFunctions.getLocalStorage("checkbox");
    if (checkboxLocal && !CustomFunctions.checkIfEmpty(emailLocal)) {
      setUsername(emailLocal);
      setRemember(true);
      setPassword(passwordLocal);
    }
  };
  // While use in project comment this code and use above Useeffect
  useEffect(() => {
    setTitle("Login");
    checkRememberMe();
    const token = CustomFunctions.getLocalStorage("access_token");
    if (token) {
      dispatch({ type: "LOGIN", value: true });
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.location.href = "/login";
  };

  const loginUser = async () => {
    setShowLoader(true);
    if (remember && !CustomFunctions.checkIfEmpty(username)) {
      CustomFunctions.setLocalStorage("checkbox", remember);
      CustomFunctions.setLocalStorage("email", username);
    } else {
      CustomFunctions.removeLocalStorage("email");
      CustomFunctions.removeLocalStorage("checkbox");
    }
    const postData = {
      email: CustomFunctions
      .encryptKey(username, "2A4274058E1725C")
      .toString(),
      password: CustomFunctions
      .encryptKey(password, "2A4274058E1725C")
      .toString(),
      
      // email: username,
      // password: password,
    };

    const { data, message } = await AuthApi.postDataToServer(
      Api.loginUrl,
      postData
    );

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data && data.data.token) {
        if (data.data.roleid !== 1) {
          setDisableButton(false);
          swal("Permission denied!", "", "error");
          return;
        }
        const envryptedPassword = CustomFunctions.encryptKey(
          password,
          CustomFunctions.getAESKey()
        );
        const envryptedUsername = CustomFunctions.encryptKey(
          data.data.email,
          CustomFunctions.getAESKey()
        );

        CustomFunctions.setLocalStorage("access_token", data.data.token);

        await CustomFunctions.setDataToLocalStorage(
          "userData",
          data && data.data,
          true
        );
        CustomFunctions.setLocalStorage("email", envryptedUsername.toString());
        CustomFunctions.setLocalStorage("id", data.data.userID);
        CustomFunctions.setLocalStorage(
          "name",
          `${data.data.firstname ? data.data.firstname : ""} ${
            data.data.lastname ? data.data.lastname : ""
          }`
        );
        CustomFunctions.setLocalStorage("role_id", data.data.roleid);
        CustomFunctions.setLocalStorage("Zoo_id", data.data.zoo_id);
        CustomFunctions.setLocalStorage(
          "password",
          envryptedPassword.toString()
        );
        setDisableButton(false);
        const timer = setInterval(async () => {
          const token = CustomFunctions.getLocalStorage("access_token");
          if (token) {
            clearInterval(timer);
            window.location.href = "/dashboard";
          }
        }, 100);
      }
    } else {
      setShowLoader(false);
      setDisableButton(false);
      swal(message, "", "error");
    }
  };

  const validateForm = (e) => {
    e.preventDefault();
    if (disableButton) return;

    setDisableButton(true);
    if (validator.current.allValid()) {
      loginUser();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  const handlePasswordView = (e) => {
    e.preventDefault();
    setSwitchItem(!switchItem);
  };

  return (
    <>
      <Helmet title={title} />
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div className="login-page d-flex align-items-center justify-content-center flex-column">
        <div className="logo text-center d-flex">
          <img
            src="/images/pmclogo.png"
            className="header-brand-img desktop-logo svg"
            alt="img"
          />
          <img
            src="/images/pune-zoo-logo.png"
            className="header-brand-img desktop-logo svg"
            alt="img"
          />
        </div>
        <form
          className="login-form"
          autoComplete="off"
          onSubmit={(e) => validateForm(e)}
        >
          <div className="title text-center">
            <h1>
              Welcome to Rajiv Gandhi Zoological Park & Wildlife Research
              Center, Pune
            </h1>
          </div>
          <div className="form-group">
            <img src="images/user1.svg" alt="Username" />
            <input
              className="form-control form-control-lg"
              type="text"
              name="email"
              value={username || ""}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Email"
              maxLength="150"
            />
            {validator.current.message("Email", username, "required|email", {
              className: "text-danger",
            })}
          </div>

          <div className="form-group m-0">
            <div className="">
              <img src="images/password.svg" alt="Username" />
              <input
                className="form-control form-control-lg"
                type={switchItem ? "text" : "password"}
                name="password"
                value={password || ""}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                // maxLength="12"
                minLength="1"
              />
              {password && (
                <img
                  style={{ cursor: "pointer" }}
                  src={
                    !switchItem
                      ? "/images/visibility.svg"
                      : "/images/eyePass.svg"
                  }
                  alt="Username"
                  className="showpass"
                  onClick={(e) => handlePasswordView(e)}
                />
              )}
              {validator.current.message("Password", password, "required", {
                className: "text-danger",
              })}
            </div>
          </div>

          <div className="form-row d-flex align-items-center justify-content-between rememberme-password">
            <label className="login__rememberme">
              <input
                onChange={(e) => setRemember(e.target.checked)}
                name="remember"
                type="checkbox"
                checked={remember}
                id="remember"
                value={remember}
              />
              <span className="ms-2" style={{ color: "#156539" }}>
                Remember me
              </span>
            </label>
            <p className="lost_password">
              <Link to="/forget-password">Forgot password ?</Link>
            </p>
          </div>
          <div className="text-center mt-3">
            <Button
              variant="btn btn-lg btn-primary"
              type="submit"
              className="ms-4"
              disabled={disableButton}
            >
              Log in
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
