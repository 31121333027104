import React from "react";
import Loader from "../../components/Loader/index";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import { TextInput, Helmet, Datepicker } from "../../components/FormInputs";
import { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";

const MaintenanceSetting = () => {
  const [title, setTitle] = useState("Maintenance");
  const validator = useRef(new SimpleReactValidator());
  const [isShowLoader, setShowLoader] = useState(false);
  const [statusToggle, setStatusToggle] = useState(false);
  const [actveteMaintenanceFalse, setActveteMaintenanceFalse] = useState(false);
  const [reason, setReason] = useState("");
  const [zooId, setZooId] = useState(2);

  const [count, forceUpdate] = useState(0);

  const items = [
    { to: "/", label: "Dashboard" },
    { to: "/setting", label: "Maintenance" },
  ];
  // useEffect(() => {
  //   if (!actveteMaintenanceFalse) return;
  //   addMaintenance();
  // }, [actveteMaintenanceFalse]);

  useEffect(() => {
    getSetting();
  }, []);

  const getSetting = async () => {
    setShowLoader(true);

    const api = `${Api.settingUrl}?zoo_id=2`;
    const url = customFunctions.generateUrl(api);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data?.success == true) {
      setShowLoader(false);
      setStatusToggle(data?.data?.setting_value == "true" ? true : false);
      // setZooId(data?.data?.zoo_id);
      setReason(data?.data?.description);
    } else {
      setShowLoader(false);
      setStatusToggle(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const addMaintenance = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const postData = {
      // description: reason ?? reason.trim(),
      setting_type: "maintenance",
      id: 1,
      setting_value: statusToggle + "",
    };
    const api = `${Api.settingUrl}`;
    const url = customFunctions.generateUrl(api);

    const { data, message } = await AuthApi.putDataToServer(url, postData);
    if (data) {
      setShowLoader(false);
      swal("Maintenance status updated successfully", "", "success");
      validator.current.hideMessages();
      forceUpdate(count + 1);
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (validator.current.allValid()) {
      addMaintenance(e);
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area mt-3">
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <h5>Turn on the maintenance mode</h5>
              </Col>

              <Col>
                <div className="switch-check col-1">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={statusToggle}
                    onChange={(e) => {
                      setStatusToggle(e.target.checked);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <hr />

            <Form
              autoComplete="off"
              onSubmit={(e) => {
                // if (statusToggle == true) {
                //   onSubmit(e);
                // } else {
                  addMaintenance(e);
                // }
              }}
            >
              {/* {statusToggle && (
                <Row>
                  <Col sm={6} className="">
                    <TextInput
                      onInputChange={(e) => {
                        setReason(e.target.value);
                      }}
                      label="Maintenance Reason"
                      containerClass="form-group"
                      as="textarea"
                      row={30}
                      asterisk="*"
                      columns={50}
                      name="Description"
                      value={reason}
                      placeholder="Enter Maintenance Reason"
                      autocomplete="new-counterName"
                      errorMessage={validator.current.message(
                        "Maintenance Reason",
                        reason,
                        "required"
                      )}
                    />
                  </Col>
                </Row>
              )} */}
              <Row className="d-flex justify-content-start">
                <Col sm={6}>
                  <div className="form-group mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      // disabled={disableButton}
                    >
                      Submit
                    </Button>
                    {/* {statusToggle && (
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setReason("");
                        }}
                        variant="secondary"
                        className="ms-2"
                      >
                        Clear
                      </Button>
                    )} */}
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default MaintenanceSetting;
