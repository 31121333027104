import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { TextInput, Helmet, Datepicker } from "../../components/FormInputs";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader/index";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";

function AddHoliday() {
  const navigate = useNavigate();
  const validator = useRef(new SimpleReactValidator());
  const { state } = useLocation();
  const [event, setEvent] = useState("");
  const [reason, setReason] = useState("");
  const [title, setTitle] = useState("Add Holiday");
  const [isShowLoader, setShowLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [holidayId, setHolidayId] = useState(null);
  const [count, forceUpdate] = useState(0);
  const [date, setDate] = useState(null);
  const zooId = customFunctions.getLocalStorage("Zoo_id");
  const firstDateofYear = new Date(new Date().getFullYear(), 0, 1);
  const lastDateofYear = new Date(new Date().getFullYear(), 11, 31)


  const items = [
    { to: "/", label: "Dashboard" },
    { to: "/holidays", label: "Holidays" },
    { label: holidayId ? "Edit Holiday" : "Add Holiday" },
  ];

  useEffect(() => {
    if (state && state.id) {
      getData(state.id);
      setHolidayId(state.id);
      setTitle("Edit Holiday");
    }
  }, [state]);

  const getData = async (id) => {
    setShowLoader(true);
    const api = `${Api.holiday}/list?id=${id}`;
    const url = customFunctions.generateUrl(api);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.data && data.data.rows.length > 0) {
      setShowLoader(false);
      setEvent(data.data.rows[0].event);
      setReason(data.data.rows[0].reason);
      setDate(new Date(data.data.rows[0].date));
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const holidayAdd = async () => {
    setShowLoader(true);
    const postData = {
      event: event,
      date: customFunctions.getDate(date),
      reason: reason,
      zoo_id: Number(zooId),
    };
    if (holidayId) {
      postData.id = holidayId;
    }
    delete postData.zoo_id;
    const api = `${Api.holiday}`;
    const url = customFunctions.generateUrl(api);
    const callback = holidayId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(url, postData);
    if (data) {
      setShowLoader(false);
      swal(
        holidayId
          ? "Holiday updated successfully"
          : "Holiday  added successfully",
        "",
        "success"
      ).then(() => {
        navigate("/holidays");
        setCounterName("");
        setHolidayId(null);
        setDisableButton(false);
        validator.current.hideMessages();
        forceUpdate(count + 1);
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (disableButton) return;
    setDisableButton(true);
    if (validator.current.allValid()) {
      holidayAdd();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={6} className="">
                  <TextInput
                    onInputChange={(e) => {
                      setEvent(e.target.value);
                    }}
                    label="Holiday Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="Event"
                    row={30}
                    columns={50}
                    value={event}
                    placeholder="Enter holiday name"
                    errorMessage={validator.current.message(
                      "Holiday name",
                      event,
                      "required"
                    )}
                    autocomplete="new-eventName"
                  />
                </Col>
                <Col sm={6} className="">
                  <Datepicker
                    label="Date"
                    className="form-control"
                    selected={date}
                    minDate={firstDateofYear}
                    showMonthDropdown
                    maxDate={lastDateofYear}
                    asterisk="*"
                    placeholder="DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                    onChange={(e) => {
                      setDate(e);
                    }}
                    errorMessage={validator.current.message(
                      "date",
                      date,
                      "required"
                    )}
                  />
                </Col>
                <Col sm={6} className="">
                  <TextInput
                    onInputChange={(e) => {
                      setReason(e.target.value);
                    }}
                    label="Description"
                    containerClass="form-group"
                    as="textarea"
                    row={30}
                    columns={50}
                    name="Description"
                    value={reason}
                    placeholder="Enter description"
                    autocomplete="new-counterName"
                  />
                </Col>
              </Row>

              <Row className="d-flex justify-content-start">
                <Col sm={6}>
                  <div className="form-group mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={disableButton}
                    >
                      {holidayId ? "Update" : "Submit"}
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/holidays");
                        validator.current.hideMessages();
                        forceUpdate(count + 1);
                        setCounterName("");
                        setHolidayId(null);
                      }}
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
}

export default AddHoliday;
