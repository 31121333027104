import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SweetAlert from 'react-bootstrap-sweetalert';
import { Card, Form, Col, Row, Button, Accordion, Image } from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import {
  Helmet,
  TextInput,
} from '../../components/FormInputs';

function Counter() {
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [counterData, setCounterData] = useState([]);
  const [title, setTitle] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [count, forceUpdate] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [counterId, setCounterId] = useState(null);
  const [statusToggle, setStatusToggle] = useState(false);
  const [statusId, setStatusId] = useState('');
  const items = [{ to: "/", label: "Dashboard" }, { label: "Counters" }];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);
  const columns = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Counter Name",
      id: "name",
    },
    {
      label: "Counter Staff",
      id: "counterStaff",
    },
    // {
    //   label: "Delete",
    //   id: "action",
    // }
    {
      label: "Status",
      id: "status",
    }
  ];

  useEffect(() => {
    setTitle("Counters");
    const roleId = customFunctions.getLocalStorage('role_id');
    setRoleId(roleId);
  }, []);

  useEffect(() => {
    getCounter(1);
  }, [roleId]);

  useEffect(() => {
    setTableData();
  }, [counterData]);

  const getCounter = async (pageNo, clearFilter = false) => {
    if (!roleId) return;
    const page = pageNo || 1;
    setShowLoader(true);
    let endpoint = `${Api.counterList}?page=${page}&limit=${pagination.itemsPerPage}`;
    const { data, message } = await AuthApi.getDataFromServer(endpoint);
    if (
      data &&
      data.data &&
      data.data.response &&
      data.data.response &&
      data.data.response.length > 0
    ) {
      setShowLoader(false);
      setPagination({
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 3,
      });
      setCounterData(data.data.response);
      forceUpdate(count + 1);
    } else {
      setShowLoader(false);
      setCounterData([]);
      swal(message, "", "error");
    }
  };

  const setTableData = () => {
    const rowsData = [];
    counterData.map((dt, i) => {
      rowsData.push({
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        name: (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title="Edit Counter Staff"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/edit-counter", { state: { uid: dt.id } });
              }}
            >{dt.name}
            </a>
          </>
        ),
        counterStaff: dt.assign_counter || '-',
        action: (
          <>
            <a
              href="/"
              className=""
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowDelete(true);
                setCounterId(dt.id);
              }}
            >
              <Image style={{ height: '20px', width: '15px' }} src='./images/delete.png' title="Delete" />
            </a>
          </>
        ),
        status: (
          <>
            <div className="switch-check">
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={dt.isActive == '1'}
                onChange={() => {
                  setStatusToggle(true);
                  setStatusId(dt.isActive == '1' ? 'Inactive' : 'Active');
                  setCounterId(dt.id)
                }}
                title={dt.isActive == '1' ? 'Active' : 'Inactive'}
              />
            </div>
          </>
        )

      });
    });
    setRows([...rowsData]);
  }

  const deleteCounter = async () => {
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.counterList}?id=${cunterId}`
    );
    if (data) {
      setShowDelete(false);
      swal("Counter deleted successfully", "", "success", {
        closeOnClickOutside: false,
        allowOutsideClick: false
      }).then(() => {
        setShowDelete(false);
        setCounterId(null);
        getCounter(1);
      });
    } else {
      setShowDelete(false);
      swal(message, "", "error", {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
    }
  }

  const changeStatus = async () => {
    if (!counterId) return;
    const isActive = statusId == 'Active' ? '1' : '0';
    const endpoint = `${Api.counterActiveInactive}?id=${counterId}&aiCounter=${isActive}`;
    const { data, message } = await AuthApi.putDataToServer(endpoint);
    if (data) {
      swal("Counter status changed successfully", "", "success", {
        closeOnClickOutside: false,
        allowOutsideClick: false
      }).then(() => {

        setStatusToggle(false);
        setCounterId(null);
        setStatusId('');
        getCounter(1);
      }
      )
    }
    else {
      setShowDelete(false);
      swal(message, "", "error", {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
    }
  }

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      {/* {showDelete && (
        <div className="sweetalert">
          <SweetAlert
            title='Are you sure that you want to delete counter ?'
            error
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteCounter();
            }}
            onCancel={() => {
              setShowDelete(false)
              setCounterId(null);
            }}
          />
        </div>
      )} */}
      {statusToggle && (
        <div className="sweetalert">
          <SweetAlert
            title='Are you sure, do you want to change the status ?'
            error
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              changeStatus();
            }}
            onCancel={() => {
              setStatusToggle(false)
              setCounterId(null);
            }}
          />
        </div>
      )}
      <section className="content-area">
        {/* <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header className='mb-0'><h5>Filters</h5></Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={(e) => filterSearch(e)}>
                {filterErr && (
                  <div className="p-3  bg-danger text-white bg-danger-main table-red-bg">
                    <div className="img-text">
                      <img style={{ cursor: 'pointer' }} onClick={(e) => onClear(e)} src="/images/fail.svg" alt="Success" /> {filterErr}
                    </div>
                  </div>
                )}
                <Row className="mt-3">
                  <Col sm={3}>
                    <TextInput
                      value={searchKeyword}
                      placeholder="Search by name and mobile"
                      onInputChange={(e) => {
                        setSearchKeyword(e.target.value);
                      }}
                    />
                  </Col>

                  <Col sm={3}>
                    <div className="filter-btn pull-right filter-btn-report">
                      <Button
                        variant="primary"
                        type="submit"
                        className="ms-4"
                      >
                        Apply
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="ms-4"
                        onClick={(e) => onClear(e)}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion> */}
        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="#"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/add-counter");
                }}
              >
                Add Counter
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              rows={rows}
              showPagination
              paginationProps={{
                ...pagination,
                activePage: pagination.currentPage,
                onPageChange: (pageNo) => {
                  getCounter(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
    </>
  );
}

export default Counter;