import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { Form, Button, Image } from 'react-bootstrap';
import swal from 'sweetalert';
import { TextInput } from '../../../components/FormInputs';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Loader from '../../../components/Loader';

const validator = new SimpleReactValidator();

function ForgetPassword() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({ email: '' });
  const [count, forceUpdate] = useState(0);
  const [isShowLoader, setShowLoader] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'LOGIN', value: false });
  }, [dispatch]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData((inputValues) => {
      return { ...inputValues, [name]: value };
    });
  };

  const getRecoveryPasswordData = async () => {
    setShowLoader(true);
    const payload = {
      email: userData.email,
    };

    const postData = customFunctions.cleanObject(payload, 'O');
    const { data, message } = await AuthApi.postDataToServer(
      `${Api.forgetPasswordUrl}`,
      postData
    );

    if (data) {
      setShowLoader(false);
      swal(data && data.data && data.data.message, '', 'success', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: true,
      }).then(() => {
        navigate('/login');
      });
    } else {
      setShowLoader(false);
      swal(message, '', 'error', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      getRecoveryPasswordData();
    } else {
      validator.showMessages();
      forceUpdate(count + 1);
    }
  };

  return (
    <div className="login-page recover-password">
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div className="logo text-center d-flex">
        <Image src="/images/pmclogo.png" />
        <Image src="/images/pune-zoo-logo.png" />
      </div>
      <Form className="login-form text-center">
        <div className="title text-center">
          <h1>Recover Password</h1>
        </div>
        <div className='forget-input'>
          <TextInput
            inputClass="form-control form-control-lg"
            type="email"
            name="email"
            onInputChange={(e) => handleInputChange(e)}
            value={userData.email}
            placeholder="Enter registered email address"
            errorMessage={validator.message(
              'email',
              userData.email,
              'required|email'
            )}
          />
        </div>
        <div className="text-center mt-3 mb-2">
          <Button
            type="submit"
            className="btn btn-lg btn-primary"
            onClick={(e) => submitForm(e)}
          >
            Reset
          </Button>
        </div>
        <a href="/login" className="back-to-login d-inline">
          Back to Login
        </a>
      </Form>
    </div>
  );
}

export default ForgetPassword;
