import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files

import translationEN from "./translation/english.json";
import translationHE from "./translation/hindi.json";
import translationMR from "./translation/marathi.json";
import customFunctions from "../helper/customFunctions";

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  hn: {
    translation: translationHE,
  },
  mr: {
    translation: translationMR,
  },
};

//i18N Initialization

i18n.use(initReactI18next).init({
  resources,
  lng: customFunctions.getDataFromLocalStorage('lan') ? customFunctions.getDataFromLocalStorage('lan') : 'mr', //default language
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
