import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import swal from 'sweetalert';
import SweetAlert from 'react-bootstrap-sweetalert';
import Api from '../../helper/api';
import AuthApi from '../../helper/authApi';
import customFunctions from '../../helper/customFunctions';
import Loader from '../../components/Loader';
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import ScrollPopupComponent from './scrollPopup';

const ScrollComponent = () => {

  const [counterListData, setCounterListData] = useState([]);
  const [zooId, setZooId] = useState(null);
  const [isShowLoader, setShowLoader] = useState(false);
  const [scrollToggle, setScrollToggle] = useState(false);
  const [scrollStatus, setScrollStatus] = useState('');
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState('');
  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);
  const [counterHistoryData, setCounterHistoryData] = useState([]);
  const [historyRows, setHistoryRows] = useState([]);
  const [scrollPopUp, setScrollPopUp] = useState(false);
  const [scrollData, setScrollData] = useState({});
  const [totalAmount, setTotalAmount] = useState('');

  const historyColumns = [
    {
      label: "Date and Time",
      id: "date",
    },
    {
      label: "Scoll Locker Name",
      id: "scrollLockerName",
    },
    {
      label: "Total Income",
      id: "ticketIncome",
    },
    {
      label: 'Details',
      id: 'Details'
    }
  ]

  const columns = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Counter Name",
      id: "counterName",
    },
    {
      label: "Assignee",
      id: "assignPerson",
    },
    {
      label: "Ticket Count",
      id: "ticketCount",
    }
  ];

  const items = [{ to: "/", label: "Dashboard" }, { label: "Scroll Lock" }];

  useEffect(() => {
    var zoo = customFunctions.getLocalStorage('Zoo_id');
    setZooId(Number(zoo));
    getScrollLockStatus();
  }, []);

  useEffect(() => {
    if (!zooId) return;
    getCounterData();
    getCounterHistory();
  }, [zooId]);

  const getCounterData = async () => {
    setShowLoader(true);
    const currentDate = customFunctions.getDate(new Date());
    const { data, message } = await AuthApi.getDataFromServer(`${Api.counterTicketCount}?zoo_id=${zooId}&start_date=${currentDate}`);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data.data && data.data.counter_count && data.data.counter_count.length > 0) {
        setCounterListData(data.data.counter_count);
        setTotalCount(data.data.total_count);
        setTotalAmount(data.data.total_amount);
      }
    }
    else {
      setShowLoader(false);
      swal(message || 'Something went wrong', '', 'error');
    }
  }

  useEffect(() => {
    setTableData();
  }, [counterListData]);

  const setTableData = () => {
    const rowsData = [];
    counterListData.map((dt, i) => {
      rowsData.push({
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        counterName: dt.counter_name,
        assignPerson: dt.assign_counter || '-',
        ticketCount: dt.count || 0,
      });
    });
    setRows([...rowsData]);
  }

  const getCounterHistory = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(`${Api.counterHistory}?page=1&limit=10`);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data && data.data.getScrollLockData && data.data.getScrollLockData.length > 0) {
        setCounterHistoryData(data.data.getScrollLockData);
      }
      else {
        setShowLoader(false);
        setCounterHistoryData([]);
      }
    }
    else {
      setShowLoader(false);
      swal(message || 'Something went wrong', '', 'error');
    }
  }

  useEffect(() => {
    sethistoryTableData();
  }, [counterHistoryData]);

  const sethistoryTableData = () => {
    let rowsData = [];
    counterHistoryData.map((dt, index) => {
      rowsData.push({
        date: `${dt.date} ${dt.time}`,
        ticketIncome: dt.total_amount || '0',
        scrollLockerName: dt.scroll_locker_name,
        Details: (
          <>
            <a href='javascript:void(0)'
              onClick={() => { setScrollData(dt); setScrollPopUp(true) }}
            >
              View
            </a>
          </>
        )
      });
    })
    setHistoryRows([...rowsData]);
  }

  const getScrollLockStatus = async () => {
    const { data, message } = await AuthApi.getDataFromServer(`${Api.scrollLockStatus}`);
    if (data && data.success === true) {
      if (data.data) {
        setScrollStatus(data.data.is_scroll_lock)
      }
    }
    else {
      swal(message || 'Something went wrong', '', 'error');
    }
  }

  const generateReport = async () => {
    if (!zooId) return;
    const postData = {
      start_date: customFunctions.getDate(new Date),
      end_date: customFunctions.getDate(new Date),
      zoo_id: Number(zooId),
      file_type: 'pdf',
      findAll: Number(1),
      transaction_status: 'success',
      booking_type: 'offline',
    }

    const payload = customFunctions.cleanObject(postData);
    const endPoint = Api.csvPdfDownload;
    const url = customFunctions.generateUrl(endPoint, payload);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data) {
      setShowLoader(false);
      window.open(data.data.file, '_blank');
      setScrollToggle(false);
      sentCounterData();
    }
    else {
      setShowLoader(false);
      swal(message || 'Something went wrong', '', 'error');
    }
  }

  const sentCounterData = async () => {

    const currentDate = customFunctions.getDate(new Date());
    const postData = {
      date: currentDate,
      name: await customFunctions.getLocalStorage('name'),
      counterdata: counterListData,
      is_scroll: '1',
      total_amount: totalAmount,
    }
    const endPoint = Api.scrollLock;
    const { data, message } = await AuthApi.postDataToServer(endPoint, postData);
    if (data && data.success == true) {
      swal(`Scroll locked, you can't book ticket for today`, '', 'success');
      getScrollLockStatus();
      setScrollToggle(false);
      getCounterHistory();
    }
    else {
      swal(message || 'Something went wrong', '', 'error');
    }
  }

  const removeScrollLock = async () => {
    const currentDate = customFunctions.getDate(new Date());

    const postData = {
      is_scroll: '0'
    }
    const endPoint = Api.scrollLock;
    const { data, message } = await AuthApi.postDataToServer(endPoint, postData);
    if (data && data.success === true) {
      swal(`Scroll locked removed, you can book ticket for today`, '', 'success');
      getScrollLockStatus();
      setScrollToggle(false);
      getCounterHistory()
    }
    else {
      swal(message || 'Something went wrong', '', 'error');
    }
  }

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      {scrollToggle && (
        <div className="sweetalert">
          <SweetAlert
            title=
            {scrollStatus === '0' ? 'Are you sure that you want to scroll lock for today ?' : 'Are you sure that you want to remove scroll lock for today ?'}

            error
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              if (scrollStatus == '1') {
                removeScrollLock();
              }
              else {
                generateReport();
              }
            }}
            onCancel={() => {
              setScrollToggle(false)
            }}
          />
        </div>
      )}
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">Scroll Lock</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <div className='section'>
        <Card className='mt-3'>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <h5 className='card-title mb-0'>Today's Counter Summary</h5>
              {
                Number(totalCount) > 0 && (
                  <h5 style={{ float: 'right' }} className='card-title mb-0'>
                    <a href='javascript:void(0)'
                      className='underline'
                      onClick={() => {
                        setScrollToggle(true);
                      }}
                    >
                      {scrollStatus == '0' ? 'Apply Scroll Lock' : 'Remove Scroll Lock'}
                    </a>
                  </h5>
                )
              }
            </div>
          </Card.Header>
          <Card.Body >
            <Table
              columns={columns}
              rows={rows}
              showPagination
              paginationProps={{
                ...pagination,
                activePage: pagination.currentPage,
                onPageChange: (pageNo) => {
                  getCounter(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>

        <Card className='mt-3'>
          <Card.Header>
            <div className="d-flex align-items-center justify-content-start">
              <h5 className='card-title mb-0'>Scroll Lock Summary</h5>
            </div>
          </Card.Header>
          <Card.Body >
            <Table
              columns={historyColumns}
              rows={historyRows}
              showPagination
              paginationProps={{
                ...pagination,
                activePage: pagination.currentPage,
                onPageChange: (pageNo) => {
                  getCounterHistory(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </div>
      {scrollPopUp && (
        <ScrollPopupComponent
          scrollPopUp={scrollPopUp}
          popupClose={() => setScrollPopUp(!scrollPopUp)}
          scrollData={scrollData}
        />
      )
      }
    </>
  )
}

export default ScrollComponent;