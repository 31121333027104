import React from "react";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const ReportPopup = (props) => {
  return (
    <>
      <div>
        <Modal
          className={"filter-popup"}
          size="md"
          centered
          onHide={props.popupClose}
          show={props.reportPopUp}
        >
          <div className="add-task-popup schedule-filter-popup">
            <Modal.Header className="col-12">
              <span style={{ fontWeight: "bold" }}>Ticket Details</span>
              <button
                onClick={() => props.popupClose()}
                type="button"
                class="btn-close"
              ></button>
            </Modal.Header>
            <Modal.Body>
              <>
                <Table>
                  <thead>
                    <tr>
                      <th>Visitor</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {props &&
                        props.reportData &&
                        props.reportData.adult_count > Number(0) && (
                          <>
                            <td>Adult</td>
                            <td>{props.reportData.adult_count}</td>
                          </>
                        )}
                    </tr>
                    <tr>
                      {props &&
                        props.reportData &&
                        props.reportData.child_count > Number(0) && (
                          <>
                            <td>Children</td>
                            <td>{props.reportData.child_count}</td>
                          </>
                        )}
                    </tr>
                    <tr>
                      {props &&
                        props.reportData &&
                        props.reportData.disabledperson_count > Number(0) && (
                          <>
                            <td>Disabled</td>
                            <td>{props.reportData.disabledperson_count}</td>
                          </>
                        )}
                    </tr>

                    <tr>
                      {props &&
                        props.reportData &&
                        props.reportData.foreigner_count > Number(0) && (
                          <>
                            <td>Foreigner</td>
                            <td>{props.reportData.foreigner_count}</td>
                          </>
                        )}
                    </tr>

                    {props &&
                      props.reportData &&
                      props.reportData.camera_counts &&
                      props.reportData.camera_counts.length > 0 && (
                        <>
                          <tr>
                            {props.reportData.camera_counts[0]
                              .steel_camera_count ? (
                              <>
                                <td>Still Camera</td>
                                <td>
                                  {
                                    props.reportData.camera_counts[0]
                                      .steel_camera_count
                                  }
                                </td>
                              </>
                            ) : (
                              <></>
                            )}
                          </tr>
                          <tr>
                            {props.reportData.camera_counts[0]
                              .video_camera_count ? (
                              <>
                                <td>Video Camera</td>
                                <td>
                                  {
                                    props.reportData.camera_counts[0]
                                      .video_camera_count
                                  }
                                </td>
                              </>
                            ) : (
                              <></>
                            )}
                          </tr>
                        </>
                      )}
                    {/* <tr>
                      {
                        props && props.reportData && props.reportData.total_counts && (
                          <>
                            <td className='report-total'>
                              Total Amount
                            </td>
                            <td className='report-total'>
                              {props && props.reportData && props.reportData.total_counts[0].total_amount}
                            </td>
                          </>
                        )
                      }
                    </tr> */}
                  </tbody>
                </Table>
              </>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ReportPopup;
