import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import { Datepicker } from "../../components/FormInputs";
import Api from "../../helper/api";
import authApi from "../../helper/authApi";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader";
import Table from "../../components/Table/simpletable.component";

const ViewAllTicket = (props) => {
  const { show, ticketDate } = props;
  const [ticketData, setTicketData] = useState([]);
  const [rows, setRows] = useState([]);
  const [isShowLoader, setShowLoader] = useState(false);

  const columns = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Order ID",
      id: "id",
    },
    {
      label: "Settlement Date",
      id: "settlementDate",
    },
    {
      label: "Ticket Amount",
      id: "amount",
    },
    {
      label: "Status",
      id: "status",
    },
  ];

  useEffect(() => {
    if (ticketDate) {
      getDailyReportData();
    }
  }, [ticketDate]);

  const getDailyReportData = async () => {
    setShowLoader(true);
    const params = {
      date: customFunctions.getDate(new Date(ticketDate)),
    };
    const url = customFunctions.generateUrl(Api.dailyTicketsUrl, params);
    const { data, message } = await authApi.getDataFromServer(url);
    if (data && data.success === true) {
      setShowLoader(false);
      if (
        data &&
        data?.data &&
        data?.data?.result &&
        data?.data?.result?.length
      ) {
        setTicketData(data?.data?.result);
      } else {
        setTicketData([]);
      }
    } else {
      setShowLoader(false);
      setTicketData([]);
      swal(message, "", "error");
    }
  };

  useEffect(() => {
    if (ticketData?.length) {
      setTableData();
    }
  }, [ticketData]);

  const setTableData = () => {
    const rowsData = [];
    ticketData.map((dt, i) => {
      rowsData.push({
        id: dt?.order_id ? dt?.order_id : "-",
        index: i + 1,
        settlementDate: dt?.settlement_date
          ? customFunctions.getDMYDateForCalendar(new Date(dt?.settlement_date))
          : "-",
        amount: dt?.netAmount ? dt?.netAmount : "-",
        status: dt?.payment_transaction_status
          ? dt?.payment_transaction_status
          : "-",
      });
    });
    setRows(rowsData);
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <Modal
        className={""}
        size={"lg"}
        show={show}
        onHide={props.popupClose}
        centered
      >
        <div className="add-task-popup">
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="title">
                <h5>{"All Tickets"}</h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: "350px", overflowY: "auto" }}>
            <Table
              columns={columns}
              rows={rows}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ViewAllTicket;
