import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Image } from "react-bootstrap";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import AuthApi from "../../../helper/authApi";
import Api from "../../../helper/api";
import SimpleReactValidator from "simple-react-validator";
import HelmetComponent from "../../../components/FormInputs/helmet.component";
import Loader from "../../../components/Loader";
import { passwordStrength } from "check-password-strength";
import customFunctions from "../../../helper/customFunctions";

const ResetPassword = (props) => {
  const navigate = useNavigate();

  const validator = useRef(new SimpleReactValidator());
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfPass] = useState("");
  const [cpassErr, setcpassErr] = useState("");
  const [count, forceUpdate] = useState(0);
  const [title, setTitle] = useState("");
  const [isShowLoader, setShowLoader] = useState(false);
  const [switchItem, setSwitchItem] = useState(false);

  // const dispatch = useDispatch();
  let { token } = useParams();

  // useEffect(() => {
  //   dispatch({ type: 'LOGIN', value: false });
  // }, [dispatch]);

  useEffect(() => {
    async function fetchMyAPI() {
      setTitle("Reset Password");
      if (!token) {
        window.location = '/login';
        return;
      } else {
        verifyTo(token);
      }
    }
    fetchMyAPI();
  }, []);

  const verifyTo = async (token) => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.verifyToken}?token=${token}`
    );
    if (data && data.data) {
      setEmail(data.data[0].email);
    } else {
      swal(message || "Invalid token", "", "error").then(() => {
        setTitle("");
        navigate('/login')
      });
    }
  };

  const validateForm = (e) => {
    e.preventDefault();
    let isValid = true;
    let cpassErr = "";

    if (password !== confirmPassword) {
      cpassErr = "Password did not match";
      isValid = false;
    }
    setcpassErr(cpassErr);
    if (validator.current.allValid() && isValid) {
      changePassword();
    } else {
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const changePassword = async (e) => {
    // e.preventDefault();
    setShowLoader(true);
    const payload = {
      token,
      newpassword: customFunctions
      .encryptKey(password, "2A4274058E1725C")
      .toString(),
      confirmpassword: customFunctions
      .encryptKey(confirmPassword, "2A4274058E1725C")
      .toString(),
    };

    const { data, message } = await AuthApi.putDataToServer(
      `${Api.resetPasswordUrl}`,
      payload
    );
    if (data) {
      setShowLoader(false);
      setTitle("");
      if (
        data &&
        data.data == "This Token has been expired, please created new Token."
      ) {
        swal(
          "This Token has been expired, please created new Token.",
          "",
          "error"
        ).then(() => {
          navigate("/login");
        });
      } else {
        swal("Password changed successfully!", "Please log in with new password.", "success").then(() => {
          navigate("/login");
        });
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const handlePasswordView = (e) => {
    e.preventDefault();
    setSwitchItem(!switchItem);
  };

  return (
    <>
      <div className="login-page recover-password">
        <div className="logo text-center d-flex">
          <Image src="/images/pmclogo.png" />
          <Image src="/images/pune-zoo-logo.png" />
        </div>
        <Form className="">
          <div className="title text-center">
            <h1>Recover Password</h1>
          </div>
          <p>
                <b>Note : </b> Your new password must consist of a combination of uppercase and lowercase letters, numbers, and special characters, ensuring a minimum length of 10 characters.
                </p>
          <div className="form-group">
            <img src="/images/user.svg" alt="Username" />
            <input
              className="form-control form-control-lg"
              type="email"
              name="email"
              minLength={6}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter registered email address"
              disabled={email}
            />
          </div>

          <div className="form-group m-0 mb-4">
            <div className="">
              <img src="/images/password.svg" alt="Username" />
              <input
                className="form-control form-control-lg"
                type={switchItem ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => {
                  setcpassErr("");
                  setPassword(e.target.value)}}
                placeholder="Password"
              />
              {password && (
                <img
                  style={{ cursor: "pointer" }}
                  src={
                    !switchItem
                      ? "/images/visibility.svg"
                      : "/images/eyePass.svg"
                  }
                  alt="Username"
                  className="showpass"
                  onClick={(e) => handlePasswordView(e)}
                />
              )}
              {password
                ? `Password strength : ${passwordStrength(password).value}`
                : ""}
                {
                  
                  validator.current.message(
                    "new password",
                    password,
                    "required|min:10",
                    {
                      className: "text-danger",
                    }
                  ) 
                }
            </div>
          </div>

          {/* <div className="form-group">
            <img src="/images/password.svg" alt="Username" />
            <input
              className="form-control form-control-lg"
              type="password"
              name="password"
              minLength={6}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter new password"
            />
            {validator.current.message('new password', password, 'required', {
              className: 'text-danger',
            })}
          </div> */}
          {/* <div className="form-group">
            <img src="/images/password.svg" alt="Username" />
            <input
              className="form-control form-control-lg"
              type="password"
              name="confirmPassword"
              minLength={6}
              value={confirmPassword}
              onChange={(e) => {
                setcpassErr("");
                setConfPass(e.target.value);
              }}
              placeholder="Re-enter the password"
            />
            {password !== "" && (
              <span>
                {validator.current.message(
                  "re-enter password",
                  confirmPassword,
                  "required",
                  { className: "text-danger" }
                )}
              </span>
            )}
            {confirmPassword !== "" && (
              <span className="text-danger">{cpassErr}</span>
            )}
          </div> */}

          <div className="form-group m-0 mb-4">
            <div className="">
              <img src="/images/password.svg" alt="Username" />
              <input
                className="form-control form-control-lg"
                type={switchItem ? "text" : "password"}
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => {
                  setcpassErr("");
                  setConfPass(e.target.value);
                }}
                placeholder="Re-enter the password"
              />
              {confirmPassword && (
                <img
                  style={{ cursor: "pointer" }}
                  src={
                    !switchItem
                      ? "/images/visibility.svg"
                      : "/images/eyePass.svg"
                  }
                  alt="Username"
                  className="showpass"
                  onClick={(e) => handlePasswordView(e)}
                />
              )}
              {password !== "" && (
                <span>
                  {validator.current.message(
                    "re-enter password",
                    confirmPassword,
                    "required",
                    { className: "text-danger" }
                  )}
                </span>
              )}
              {confirmPassword !== "" && (
                <span className="text-danger">{cpassErr}</span>
              )}
            </div>
          </div>

          <div className="text-center mt-3">
            <Button
              type="submit"
              className="btn btn-lg btn-primary"
              onClick={(e) => validateForm(e)}
              disabled={passwordStrength(password).value != "Strong"}
            >
              Reset
            </Button>
          </div>
          <a href="/login" className="back-to-login">
            Back to Login
          </a>
        </Form>
      </div>
    </>
  );
};

export default ResetPassword;
