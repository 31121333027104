import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import adminStore from './store';
import Interceptor from './services/Interceptor';
import 'react-datepicker/dist/react-datepicker.css';

const route = window.location.pathname;

if (route !== '/' && route !== '/ticket' && route !== '/ticket-booking/qr' && route !== "/ticket/fail" ) {
  Interceptor.setupInterceptors();
}

const store = createStore(adminStore);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
