import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core';
import menu from '../../helper/menu'
import UserRestrictions from '../UserRestrictions/userrestrictions.component'
import CustomFunctions from '../../helper/customFunctions'
import Api from '../../helper/api'
import AuthApi from '../../helper/authApi'
// import Loader from "../../../components/Loader";
import Loader from '../Loader'
import swal from 'sweetalert'

function Sidebar () {
  const navigate = useNavigate()
  const [activeMenu, setActiveMenu] = useState(0)
  const [activeSubPath, setActiveSubPath] = useState(0)
  const [userRole, setUserRole] = useState('')
  const [userId, setUserId] = useState(null)
  const [isShowLoader, setShowLoader] = useState(false)

  const onSubActiveClass = () => {
    if (activeSubPath !== window.location.pathname)
      setActiveSubPath(window.location.pathname)
  }
  const dispatch = useDispatch()
  // const event = new Event('Login', { isLogin: false });

  useEffect(() => {
    const uRole = CustomFunctions.getLocalStorage('role_id')
    setUserRole(Number(uRole))
    const id = CustomFunctions.getLocalStorage('id')
    setUserId(Number(id))
  }, [])

  const logOutUser = async () => {
    setShowLoader(true)
    const payload = {
      id: userId
    }

    const postData = CustomFunctions.cleanObject(payload, 'O')
    const { data, message } = await AuthApi.postDataToServer(
      `${Api.logoutUrl}`,
      postData
    )

    if (data && data.success) {
      setShowLoader(false)
      swal(data?.data, '', 'success', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: true
      }).then(() => {
        // document.dispatchEvent(event);
        dispatch({ type: 'LOGIN', value: false })
        CustomFunctions.onLogout()
        navigate('/login')
      })
    } else {
      setShowLoader(false)
      swal(message, '', 'error', {
        closeOnClickOutside: false,
        allowOutsideClick: false
      })
    }
  }

  return (
    <div className='main-sidebar main-sidebar-sticky side-menu ps'>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div className='sidemenu-logo'>
        <a className='main-logo' href='/dashboard'>
          <img
            src='/images/pmclogo.png'
            className='header-brand-img desktop-logo svg'
            alt='logo'
          />
        </a>
      </div>
      <div className='main-sidebar-body'>
        <ul className='nav'>
          {menu.map((menuItem, menuIndex) => {
            const hasSubMenu = menuItem.submenu && menuItem.submenu.length > 0
            const parentLiCls = `nav-item ${
              menuIndex === 0 || window.location.pathname === menuItem.path
                ? 'show'
                : ''
            }`
            const parentActiveCls =
              (activeMenu === menuIndex &&
                window.location.pathname === menuItem.path) ||
              window.location.pathname === menuItem.path ||
              (window.location.pathname.includes(menuItem.path) &&
                menuItem.path.length > 1)
                ? 'show active'
                : ''
            const parentLinkCls = `nav-link ${hasSubMenu ? 'with-sub' : ''}`

            return (
              <React.Fragment key={menuIndex}>
                <UserRestrictions
                  permittedUsers={menuItem.roleaccess}
                  roleId={userRole}
                >
                  <li className={`${parentLiCls} ${parentActiveCls} `}>
                    <Link
                      className={parentLinkCls}
                      to={
                        menuItem.redirect !== 'undefined' &&
                        menuItem.redirect === false
                          ? false
                          : menuItem.path
                      }
                      onClick={() => {
                        if (menuItem.title === 'Log out') {
                          logOutUser()
                          // navigate('/login')
                          // // document.dispatchEvent(event);
                          // dispatch({ type: 'LOGIN', value: false })
                        }
                        setActiveMenu(menuIndex)
                      }}
                    >
                      {/* <FontAwesomeIcon
                        icon={`${menuItem.icon}`}
                        color="white"
                      /> */}
                      <img src={menuItem.icon} />
                      <span className='sidemenu-label'>{menuItem.title}</span>
                    </Link>
                    {hasSubMenu && (
                      <ul className='nav-sub list-unstyled'>
                        {menuItem.submenu.map((submenuItem, index) => {
                          return (
                            <li // eslint-disable-line
                              onClick={() => {
                                onSubActiveClass()
                              }} // eslint-disable-line
                              className={`nav-sub-item ${
                                submenuItem.path === window.location.pathname
                                  ? 'active show'
                                  : ''
                              }`}
                              key={index}
                            >
                              <Link
                                className='nav-sub-link'
                                to={submenuItem.path}
                              >
                                {submenuItem.title}
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </li>
                </UserRestrictions>
              </React.Fragment>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
