import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function HelmetComponent(props) {
  const { title } = props;
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${title} |PMC ZOO`}</title>
          <meta name="description" content="" />
          <link rel="canonical" href="" />
        </Helmet>
      </HelmetProvider>
    </div>
  );
}
