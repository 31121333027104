import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { TextInput, Helmet } from "../../components/FormInputs";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader/index";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";

const validator = new SimpleReactValidator();

function AddUser() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordErr, setUserPasswordErr] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [userConfirmPasswordErr, setUserConfirmPasswordErr] = useState("");
  const [isShowLoader, setShowLoader] = useState(false);
  const [userId, setUserId] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [count, forceUpdate] = useState(0);
  const [title, setTitle] = useState("");
  const items = [
    { to: userId ? "/edit-user" : "/add-user", label: "Dashboard" },
    { label: userId ? "Add User" : "Edit User" },
  ];

  useEffect(() => {
    if (state && state.uid) {
      getUser(state.uid);
      setUserId(state.uid);
      setTitle("Edit User");
    }
    setTitle("Add User");
  }, []);

  const getUser = async (id) => {
    setShowLoader(true);

    const api = `${Api.users}?id=${id}`;
    const url = customFunctions.generateUrl(api);
    const { data } = await AuthApi.getDataFromServer(url);
    if (
      data &&
      data.data &&
      data.data.result &&
      data.data.result.rows &&
      data.data.result.rows.length > 0
    ) {
      setShowLoader(false);
      setUserName(data.data.result.rows[0].name);
      setUserMobile(data.data.result.rows[0].mobile);
      setUserEmail(data.data.result.rows[0].email);
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const addUser = async () => {

    setShowLoader(true);
    const postData = {
      name: userName,
      mobile: userMobile,
      email: userEmail,
      password: userPassword,
    };
    if (userId) {
      postData.id = userId;
    }
    const api = `${Api.users}`;
    const url = customFunctions.generateUrl(api);
    const callback = userId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(url, postData);
    if (data) {
      setShowLoader(false);
      swal(
        userId ? "User updated successfully" : "User added successfully",
        "",
        "success"
      );
      setUserName("");
      setUserEmail("");
      setUserMobile(null);
      setUserId(null);
      navigate("/user");
      setDisableButton(false);
      validator.hideMessages();
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (disableButton) return;

    setDisableButton(true);
    let isValid = true;
    let confirmPassErr = "";
    let passErr = "";
    setUserPasswordErr("");
    setUserConfirmPasswordErr("");

    if (!userPassword) {
      passErr = "Please enter new password";
      setUserPasswordErr(passErr);
      isValid = false;
    }

    if (userPassword && !userConfirmPassword) {
      confirmPassErr = "Please enter confirm password";
      setUserConfirmPasswordErr(confirmPassErr);
      isValid = false;
    }

    if (!userPassword && userConfirmPassword) {
      passErr = "Please enter new password";
      setUserPasswordErr(passErr);
      isValid = false;
    }

    if (
      userPassword &&
      userConfirmPassword &&
      userPassword !== userConfirmPassword
    ) {
      confirmPassErr = "New password and confirm password did not match!";
      setUserConfirmPasswordErr(confirmPassErr);
      isValid = false;
    }

    if (validator.allValid() && isValid) {
      addUser();
    } else {
      validator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  validator.purgeFields();

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="card-title mb-0 mt-2 ">{title}</h5>
            </div>
            <hr />
            <Form
              autoComplete="off"
              className="p-5"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={6} className="center-block">
                  <TextInput
                    onInputChange={(e) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setUserName(e.target.value);
                      }
                    }}
                    label="User Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="userName"
                    value={userName}
                    placeholder="Enter user name"
                    errorMessage={validator.message(
                      "User name",
                      userName,
                      "required"
                    )}
                  />

                  <TextInput
                    onInputChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                    label="User Email"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="userEmail"
                    value={userEmail}
                    placeholder="Enter user email"
                    errorMessage={validator.message(
                      "User email",
                      userEmail,
                      "required|email"
                    )}
                  />

                  <TextInput
                    onInputChange={(e) => {
                      const newValue = customFunctions.numberMasking(
                        e.target.value
                      );
                      setUserMobile(newValue);
                    }}
                    label="User Mobile Number"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="userMobile"
                    value={userMobile}
                    maxLength={10}
                    placeholder="Enter user mobile"
                    errorMessage={validator.message(
                      "User mobile",
                      userMobile,
                      "required"
                    )}
                  />
                  {!userId && (
                    <div>
                      <div className="form-wrap">
                        <TextInput
                          onInputChange={(e) => {
                            setUserPassword(e.target.value);
                          }}
                          label="Password"
                          asterisk="*"
                          containerClass="form-group"
                          type="text"
                          name="userPassword"
                          value={userPassword}
                          placeholder="Enter password"
                        // errorMessage={validator.message(
                        //   "Enter password",
                        //   userPasswordErr,
                        //   "required"
                        // )}
                        />
                        <span className="text-danger error-text">
                          {userPasswordErr}
                        </span>
                      </div>

                      <div className="form-wrap">
                        <TextInput
                          onInputChange={(e) => {
                            setUserConfirmPassword(e.target.value);
                          }}
                          label="Confirm Password"
                          asterisk="*"
                          containerClass="form-group"
                          type="text"
                          name="userConfirmPassword"
                          value={userConfirmPassword}
                          placeholder="Enter confirm password"
                        // errorMessage={validator.message(
                        //   "confirm password",
                        //   userConfirmPasswordErr,
                        //   "required"
                        // )}
                        />
                        <span className="text-danger  error-text">
                          {userConfirmPasswordErr}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="form-group text-center mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={disableButton}
                    >
                      {userId ? "Edit User" : "Add User"}
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setUserName("");
                        setUserEmail("");
                        setUserMobile(null);
                        setUserId(null);
                        navigate("/user");
                      }}
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
}

export default AddUser;
