import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Api from '../../helper/api'
import AuthApi from '../../helper/authApi'
import customFunctions from '../../helper/customFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut, faKey } from '@fortawesome/free-solid-svg-icons'
import swal from 'sweetalert'
import Loader from '../Loader'

function Header () {
  const date = new Date().toDateString().split(' ')
  const formattedDate = `${date[0]}, ${date[1]} ${date[2]} ${date[3]} `
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const event = new Event('Login', { isLogin: false })
  let token = customFunctions.getLocalStorage('access_token')
  let name = customFunctions.getLocalStorage('name')

  const eventMenu = new Event('MenuChange', { isUpdated: true })

  const [userId, setUserId] = useState(null)
  const [isShowLoader, setShowLoader] = useState(false)

  useEffect(() => {
    const id = customFunctions.getLocalStorage('id')
    setUserId(Number(id))
  }, [])

  useEffect(() => {
    if (!token) {
      const path = window.location.pathname.split('/')
      const extractedPath = path.length > 1 ? path[1].toString() : ''
      if (
        extractedPath !== 'login' &&
        extractedPath !== 'forget-password' &&
        extractedPath !== 'password' &&
        extractedPath !== '/ticket/fail' &&
        window.location.pathname !== '/'
      ) {
        navigate('/login')
      }
    } else {
      dispatch({ type: 'LOGIN', value: true })
    }
  }, [])

  const onMenuChange = () => {
    document.dispatchEvent(eventMenu)
    return true
  }

  const logOutUser = async () => {
    setShowLoader(true)
    const payload = {
      id: userId
    }

    const postData = customFunctions.cleanObject(payload, 'O')
    const { data, message } = await AuthApi.postDataToServer(
      `${Api.logoutUrl}`,
      postData
    )

    if (data && data.success) {
      setShowLoader(false)
      swal(data?.data, '', 'success', {
        closeOnClickOutside: false,
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: true
      }).then(() => {
        // document.dispatchEvent(event);
        document.dispatchEvent(event)
        dispatch({ type: 'LOGIN', value: false })
        customFunctions.onLogout()
        navigate('/login')
      })
    } else {
      setShowLoader(false)
      swal(message, '', 'error', {
        closeOnClickOutside: false,
        allowOutsideClick: false
      })
    }
  }

  return (
    <div className='main-header side-header sticky sticky-pin'>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div className='container-fluid d-flex align-items-center justify-content-end h-100'>
        <div className='main-header-right'>
          <div className='day-date'>
            <h5>{formattedDate}</h5>
          </div>

          <div className='profile-name-img d-flex align-items-center'>
            <h6>{name ? name : ''}</h6>
          </div>

          <div className='profile-name-img dropdown d-md-flex header-settings'>
            <div className='profile-img' style={{ cursor: 'pointer' }}>
              <a
                href={undefined}
                data-toggle='sidebar-right'
                data-target='.sidebar-right'
              >
                <img src={'/images/profile.svg'} alt='Edit Profile' />
              </a>
            </div>

            <div className='main-header-menu-icon'>
              <div className='dropdown-menu'>
                <ul className='m-0 list-unstyled p-0'>
                  <li>
                    <Link
                      className='dropdown-item'
                      to='/profile'
                      onClick={onMenuChange}
                    >
                      <img src='/images/user.svg' alt='User' className='svg' />{' '}
                      My Profile
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/change-password'>
                      <FontAwesomeIcon icon={faKey} className='svg' /> Change
                      Password
                    </Link>
                  </li>

                  <li>
                    <a
                      style={{ cursor: 'pointer' }}
                      className='dropdown-item'
                      href={undefined}
                      onClick={() => {
                        // customFunctions.clearLocalStorage();
                        logOutUser()
                        // dispatch({ type: "LOGIN", value: false });
                        // customFunctions.onLogout();
                        // document.dispatchEvent(event);
                        // navigate("/login");
                      }}
                    >
                      <FontAwesomeIcon icon={faSignOut} className='svg' /> Log
                      Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
