import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Image, Card, Form, Col, Row, Button } from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import {
  Helmet,
  TextInput,
} from '../../components/FormInputs';

function User() {
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [userData, setUserData] = useState([]);
  const [title, setTitle] = useState("");
  const [count, forceUpdate] = useState(0);
  const [filterErr, setFilterErr] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [roleId, setRoleId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [zooId, setZooId] = useState(null);
  const items = [{ to: "/", label: "Dashboard" }, { label: "Visitors" }];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };

  const [pagination, setPagination] = useState(initialPagination);
  const columns = [
    {
      label: "Sr. No.",
      id: "index",
    },
    {
      label: "Name",
      id: "name",
    },
    // {
    //   label: "Email",
    //   id: "email",
    // },
    {
      label: "Mobile No",
      id: "mobile",
    },
    {
      label: "Visit Date",
      id: "visitDate",
    }
  ];

  useEffect(() => {
    setTitle("Visitors");
    const roleId = customFunctions.getLocalStorage('role_id');
    setRoleId(roleId);
    const userId = customFunctions.getLocalStorage('id');
    setUserId(userId);
    const zoo = customFunctions.getLocalStorage('Zoo_id');
    setZooId(zoo);
  }, []);

  useEffect(() => {
    getUser();
  }, [roleId]);

  useEffect(() => {
    setTableData();
  }, [userData]);

  const getUser = async (pageNo, clearFilter = false) => {
    if (!roleId) return;
    const page = pageNo || 1;
    setShowLoader(true);
    const hasSearch = (searchKeyword !== '' && !clearFilter);
    let api = '';
    var counterstaff_id = roleId == 2 ? `&counterstaff_id=${Number(userId)}` : '';
    api = hasSearch ? `${Api.bookingReports}?page=${page}&limit=${pagination.itemsPerPage}&search=${searchKeyword}&zoo_id=${Number(zooId)}${counterstaff_id}` : `${Api.bookingReports}?page=${page}&limit=${pagination.itemsPerPage}&zoo_id=${Number(zooId)}${counterstaff_id}`;


    const url = customFunctions.generateUrl(api);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success === true) {
      if (
        data &&
        data.data &&
        data.data.response &&
        data.data.response.rows &&
        data.data.response.rows.length > 0
      ) {
        setShowLoader(false);
        setPagination({
          currentPage: data.data.page,
          itemsPerPage: Number(10),
          totalItems: data.data.response.count,
        });
        setUserData(data.data.response.rows);
      } else {
        setShowLoader(false);
        setUserData([]);
      }
    }
    else {
      setShowLoader(false);
      swal(message || "Something went wrong", '', 'error');
    }

  };

  const setTableData = () => {
    const rowsData = [];
    userData.map((dt, i) => {
      var date = dt && dt.total_counts && dt.total_counts[0] && dt.total_counts[0].booking_date && customFunctions.getDMYDate(dt.total_counts[0].booking_date);
      rowsData.push({
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        name: dt.name,
        email: dt.email,
        mobile: dt.mobile_no,
        visitDate: date,
        action: (
          <>
            <React.Fragment>
              <a
                href="/"
                className="pl-2 ms-1"
                title="Edit user"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate("/edit-user", { state: { uid: dt.id } });
                }}
              >
                <Image src="/images/edit.svg" />
              </a>
            </React.Fragment>
          </>
        ),
      });
    });
    setRows([...rowsData]);
  }

  const deleteUser = (id) => {
    if (id) {
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete the user?",
        icon: "warning",
        dangerMode: true,
        buttons: true,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result) {
          const { data, message } = await AuthApi.deleteDataFromServer(
            `${Api.users}?id=${id}`
          );
          if (data) {
            swal("User deleted successfully", "", "success", {
              closeOnClickOutside: false,
              allowOutsideClick: false,
              buttons: false,
              timer: 2000,
            }).then(() => {
              swal.close();
              getUser(pagination.currentPage);
            });
          } else {
            swal(message, "", "error", {
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
          }
        }
      });
    }
  };

  const filterSearch = (e) => {
    e.preventDefault();
    let filterSearchErr = '';
    let isValid = true;
    if (
      customFunctions.checkIfEmpty(searchKeyword)
    ) {
      filterSearchErr = 'Please enter text';
      setFilterErr(filterSearchErr);
      isValid = false;
    }

    if (isValid) {
      setFilterErr('');
      getUser();
    }
  };

  const onClear = (e) => {
    setFilterErr('');
    setSearchKeyword('');
    getUser(1, true);
    forceUpdate(count + 1);
  }

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="card-title mb-0 mt-2 ">Filters</h5>
            </div>
            <hr />
            <Card.Title>
              <Form onSubmit={(e) => filterSearch(e)}>
                {filterErr && (
                  <div className="p-3  bg-danger text-white bg-danger-main table-red-bg">
                    <div className="img-text">
                      <img style={{ cursor: 'pointer' }} onClick={(e) => onClear(e)} src="/images/fail.svg" alt="Success" /> {filterErr}
                    </div>
                  </div>
                )}
                <Row className="mt-3">
                  <Col sm={3}>
                    <TextInput
                      value={searchKeyword}
                      placeholder="Search by name and mobile"
                      onInputChange={(e) => {
                        setSearchKeyword(e.target.value);
                      }}
                    />
                  </Col>

                  <Col sm={3}>
                    <div className="filter-btn pull-right filter-btn-report">
                      <Button
                        variant="primary"
                        type="submit"
                        className="ms-4"
                      >
                        Apply
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="ms-4"
                        onClick={(e) => onClear(e)}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card.Title>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Table
              columns={columns}
              rows={rows}
              showPagination
              paginationProps={{
                ...pagination,
                activePage: pagination.currentPage,
                onPageChange: (pageNo) => {
                  getUser(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
    </>
  );
}

export default User;