import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { TextInput, Helmet } from "../../components/FormInputs";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import Loader from "../../components/Loader/index";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import axios from "axios";

const validator = new SimpleReactValidator();

function Profile() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isShowLoader, setShowLoader] = useState(false);
  const [userId, setUserId] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [count, forceUpdate] = useState(0);
  const [title, setTitle] = useState("");
  const [designation, setDesignation] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [zooId, setZooId] = useState(null);
  // const [profileData, setProfileData] = useState({});
  const items = [{ to: "/", label: "Dashboard" }, { label: "Profile" }];

  useEffect(() => {
    setTitle("Profile");
  }, []);

  useEffect(() => {
    const userData = customFunctions.getDataFromLocalStorage("userData", true);
    // setProfileData(userData);
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(role);
    const zoo = customFunctions.getLocalStorage("Zoo_id");
    setZooId(zoo);
    const user = customFunctions.getLocalStorage("id");
    setUserId(user);
  }, []);

  useEffect(() => {
    getUser();
  }, [userId]);

  const getUser = async () => {
    if (!userId) return;
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.users}?id=${userId}`
    );
    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data && data.data.response && data.data.response.rows) {
        let profileData = data.data.response.rows[0];
        setUserName(profileData.name);
        setUserMobile(profileData.mobile);
        setUserEmail(profileData.email);
        setDesignation(
          profileData && profileData.designation ? profileData.designation : ""
        );
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const addUser = async () => {
    setShowLoader(true);
    const postData = {
      id: Number(userId),
      name: userName,
      mobile: customFunctions
        .encryptKey(userMobile, "2A4274058E1725C")
        .toString(),
      email: customFunctions
        .encryptKey(userEmail.trim(), "2A4274058E1725C")
        .toString(),
      designation: designation,
      // image : image
    };

    if (roleId == 1) {
      delete postData.designation;
    }
    const api = `${Api.users}`;
    const url = customFunctions.generateUrl(api);
    const callback = AuthApi.putDataToServer
    const { data, message } = await callback(url, postData);
    if (data && data.success === true) {
      await customFunctions.setLocalStorage('name', userName);
      setShowLoader(false);
      swal("Profile updated successfully", "", "success").then(() => {
        setUserName("");
        setUserEmail("");
        setUserMobile(null);
        setUserId(null);
        navigate("/dashboard");
        setDisableButton(false);
        validator.hideMessages();
      })
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (disableButton) return;

    setDisableButton(true);
    if (validator.allValid()) {
      addUser();
    } else {
      validator.showMessages();
      forceUpdate(count + 1);
      setDisableButton(false);
    }
  };

  validator.purgeFields();

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <Card>
          <Card.Body>
            <Form
              autoComplete="off"
              onSubmit={(e) => {
                onSubmit(e);
              }}
            >
              <Row>
                <Col sm={6} className="">
                  <TextInput
                    onInputChange={(e) => {
                      const re = /^[a-zA-Z ]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setUserName(e.target.value);
                      }
                    }}
                    label="User Name"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="userName"
                    value={userName}
                    placeholder="Enter user name"
                    errorMessage={validator.message(
                      "User name",
                      userName,
                      "required"
                    )}
                    autoComplete="new-userName"
                  />
                </Col>
                <Col sm={6} className="">
                  <TextInput
                    onInputChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                    label="User Email"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="userEmail"
                    disabled
                    value={userEmail.trim()}
                    placeholder="Enter user email"
                    errorMessage={validator.message(
                      "User email",
                      userEmail.trim(),
                      "required|email"
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <TextInput
                    onInputChange={(e) => {
                      const newValue = customFunctions.numberMasking(
                        e.target.value
                      );
                      setUserMobile(newValue);
                    }}
                    label="User Mobile Number"
                    asterisk="*"
                    containerClass="form-group"
                    type="text"
                    name="userMobile"
                    value={userMobile}
                    maxLength={10}
                    placeholder="Enter user mobile"
                    errorMessage={validator.message(
                      "Mobile number",
                      userMobile,
                      "required|phone"
                    )}
                  />
                </Col>
              </Row>
              <Row>
                {roleId == 2 && (
                  <Col sm={6}>
                    <TextInput
                      onInputChange={(e) => {
                        setDesignation(e.target.value);
                      }}
                      label="Designation"
                      // asterisk="*"
                      containerClass="form-group"
                      type="text"
                      name="designation"
                      disabled={roleId == 2 ? true : false}
                      value={designation}
                      maxLength={10}
                      placeholder="Enter designation"
                      // errorMessage={validator.message(
                      //   "Designation",
                      //   designation,
                      //   "required"
                      // )}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <div style={{ float: "right" }} className="form-group mt-3">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={disableButton}
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setUserName("");
                        setUserEmail("");
                        setUserMobile(null);
                        setUserId(null);
                        navigate("/dashboard");
                      }}
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </section>
    </>
  );
}

export default Profile;
