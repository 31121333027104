import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import RouterFile from './routes';
import customFunctions from './helper/customFunctions';

let token = customFunctions.getLocalStorage('access_token');
const route = window.location.pathname;

function App() {
  const navigate = useNavigate();

  // useEffect(() => {
  //   if(window.location.pathname == '/'){
  //     document.querySelector('body').style.backgroundColor = '#00270E'
  //   }
  //   else{
  //     document.querySelector('body').style.backgroundColor = ''
  //   }
  //   var finalRoute = route.includes('password')
  //   if (route === '/login' || route === '/' || route === '/ticket' || route === '/ticket-booking/qr') return;
  //   if (!token && !finalRoute) {
  //     navigate('/');
  //   }
  // }, []);

  return (
    <Routes>
      <Route path="*" element={<RouterFile />} />
    </Routes>
  );
}

export default App;
