import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Helmet } from "../../components/FormInputs";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";

function BookTicket() {
  const [isShowLoader, setShowLoader] = useState(false);
  const [fullName, setFullName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [title, setTitle] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [userId, setUserId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [zooId, setZooId] = useState(null);
  const [tickets, setTickets] = useState([]);

  const paymentTypeArray = [{
    label: 'Cash',
    value: 'Cash',
  },
    // {
    //   label: 'Card',
    //   value: 'Card',
    // },
    // {
    //   label: 'UPI',
    //   value: 'UPI',
    // }
  ];

  const cardPaymentArray = [{
    label: 'Credit Card',
    value: 'creditCard',
  },
  {
    label: 'Debit Card',
    value: 'debitCard',
  }];

  const upiPaymentArray = [{
    label: 'Google Pay',
    value: 'gPay',
  },
  {
    label: 'Phone Pai',
    value: 'phonePai',
  },
  {
    label: 'Paytm',
    value: 'paytm',
  }];

  const [paymentType, setPaymentType] = useState('');
  const [paymentMode, setPaymentMode] = useState('Cash');

  const items = [
    { to: "/", label: "Dashboard" },
    { label: "Book Ticket" },
  ];

  useEffect(() => {
    const role = customFunctions.getLocalStorage("role_id");
    setRoleId(role);
    const userID = customFunctions.getLocalStorage("id");
    setUserId(userID);
    const zoo = customFunctions.getLocalStorage('Zoo_id');
    setZooId(zoo);
    setTitle("Book Ticket");
  }, []);

  useEffect(() => {
    getTicketFees();
  }, [roleId])

  const getTicketFees = async () => {
    if (!roleId) return;
    setShowLoader(true);
    const api = `${Api.ticketFees}/id`;
    const url = customFunctions.generateUrl(api);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (
      data &&
      data.data &&
      data.data.result &&
      data.data.result.length > 0
    ) {
      setShowLoader(false);
      data.data.result.map((dt) => {
        dt.quantity = '';
        // if (dt.price === 0) {
        //   dt.freeprice = "Free";
        // }
      });
      setTickets(data.data.result);
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const getSubtotal = () => {
    let subTotal =
      tickets &&
      tickets.length > 0 &&
      tickets.reduce(
        (prevValue, curValue) =>
          prevValue +
          (typeof curValue.price === "string"
            ? 0
            : curValue.price * curValue.quantity),
        0
      );
    setSubTotalPrice(Number(subTotal));

    let calculateTax = (subTotal * Number(tax)) / 100;
    setTotalPrice(subTotal + calculateTax);
  };

  const ticketRequest = async (e) => {
    e.preventDefault();

    setShowLoader(true);
    const payload = {
      name: fullName.trim(),
      mobile_no: mobileNo,
      adult_count: 0,
      adult_price: 0,
      child_count: 0,
      child_price: 0,
      foreigner_count: 0,
      foreigner_price: 0,
      steel_camera_count: 0,
      video_camera_count: 0,
      steel_camera_price: 0,
      video_camera_price: 0,
      disabledperson_count: 0,
      disabledperson_price: 0,
      total_count: 0,
      total_amount: Number(totalPrice),
      booking_date: new Date(),
      transaction_status: "success",
      booking_type: paymentMode == 'Cash' ? 'Offline' : 'Online',
      payment_type: paymentMode == 'Cash' ? 'Cash' : paymentType,
      zoo_id: Number(zooId),
      user_id: Number(userId),
      orderid: '',
    };
    if (roleId == 1) {
      payload.counterstaff_id = Number(0);
    }
    if (roleId == 2) {
      payload.counterstaff_id = Number(userId);
    }
    let totalCount =
      tickets &&
      tickets.length > 0 &&
      tickets.reduce((prevValue, curValue) => Number(prevValue) + Number(curValue.quantity), 0);
    payload.total_count = totalCount;

    const err = tickets.some((t) => t.quantity > 0);

    const result = tickets.filter((tk) => {
      if (tk.isCkecked && tk.quantity == '') {
        return tk;
      }
    });

    if (result && result.length > 0) {
      setShowLoader(false);
      swal('Please enter quantity', '', 'error');
      return;
    }
    else if (fullName === "") {
      setShowLoader(false);
      swal("Please enter your full name", "", "error");
      return;
    } else if (mobileNo === "" || mobileNo.length < 10) {
      setShowLoader(false);
      swal(
        `${mobileNo === ""
          ? "Please enter your mobile number"
          : "Please enter valid mobile number"
        }`,
        "",
        "error"
      );
      return;
    } else if (!err) {
      setShowLoader(false);
      swal("Please select atleast one ticket", "", "error");
      return;
    }
    else if (paymentMode !== 'Cash' && !paymentType) {
      setShowLoader(false);
      swal("Please select payment type", "", "error");
      return;
    }
    tickets &&
      tickets.length > 0 &&
      tickets.map((tk) => {
        switch (tk.name) {
          case "Adult":
            payload.adult_count = Number(tk.quantity);
            payload.adult_price = Number(tk.price);
            break;

          case "Children":
            payload.child_count = Number(tk.quantity);
            payload.child_price = Number(tk.price);
            break;

          case "Disabled":
            payload.disabledperson_count = Number(tk.quantity);
            payload.disabledperson_price = Number(0);
            break;

          case "Foreigner":
            payload.foreigner_count = Number(tk.quantity);
            payload.foreigner_price = Number(tk.price);
            break;

          case "Still Camera":
            payload.steel_camera_count = Number(tk.quantity);
            payload.steel_camera_price = Number(tk.price);
            break;

          case "Video Camera":
            payload.video_camera_count = Number(tk.quantity);
            payload.video_camera_price = Number(tk.price);
            break;
        }
      });

    const endpoint = Api.ticketBookingRequest;
    const { data, message } = await AuthApi.postDataToServer(endpoint, payload);
    if (data) {
      setShowLoader(false);
      swal("Ticket generated successfully", "", "success");
      setFullName("");
      setMobileNo("");
      setSubTotalPrice(Number(0));
      setTickets([]);
      getTicketFees();
      setPaymentType('Cash');
      setTotalPrice(Number(0));
    } else {
      setShowLoader(false);
      swal(
        message || "Something went wrong! Please try again later",
        "",
        "error"
      );
    }
  };

  const handleCheckBox = (e, i) => {
    if (e.target.checked == false) {
      tickets[i].quantity = '';
      setTickets([...tickets]);
    }
    tickets[i].isCkecked = e.target.checked;
    setTickets([...tickets]);
  }

  const handleTicketQuantity = (i, value) => {
    tickets[i].quantity = value;
    setTickets([...tickets]);
  }

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <div className="row">
          <div className="col-sm-9">
            <div className="row">
              <div className="col-sm-6">
                <div class="input-group mb-3">
                  <div className="mb-3 input-group text-center">
                    <span className="input-group-text" id="basic-addon1">
                      <img src="images/user-icon.svg" alt="icon" />
                    </span>
                    <input type="text" class="form-control" placeholder="Enter full name" aria-label="Username" aria-describedby="basic-addon1"
                      value={fullName}
                      onChange={(e) => {
                        const re = /^[a-zA-Z ]*$/;
                        if (
                          e.target.value === '' ||
                          re.test(e.target.value)
                        ) {
                          setFullName(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div class="input-group mb-3">
                  <div className="mb-3 input-group text-center">
                    <span className="input-group-text" id="basic-addon1">
                      <img src="images/phone-icon.svg" alt="icon" />
                    </span>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Enter number"
                      aria-describedby="basic-addon1"
                      aria-label="Username"
                      value={mobileNo}
                      onChange={(e) => {
                        var val = e.target.value;
                        var phoneformat = /^\d{1,10}$/;
                        if (val === "" || val.match(phoneformat)) {
                          setMobileNo(val);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

            </div>
            <hr />
            <div className="row">
              <div className="col-sm-12">
                {
                  paymentTypeArray.map((type, i) => (
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        value={paymentMode}
                        checked={paymentMode === type.label}
                        onChange={() => {
                          setPaymentMode(type.label);
                        }}
                        type="radio"
                        name="flexRadioDefault"
                        id={`flexRadioDefault1${i}`} />
                      <label class="form-check-label" for={`flexRadioDefault1${i}`}>
                        {type.label}
                      </label>
                    </div>
                  ))
                }
                {
                  paymentMode === 'Card' && (
                    <div class="input-group mb-3">
                      <select class="form-select" id="inputGroupSelect01" onChange={(e) => setPaymentType(e.target.value)}>
                        <option value=''>Select card</option>
                        {
                          cardPaymentArray.map((card) => (
                            <option value={card.label}>{card.label}</option>
                          ))
                        }
                      </select>
                    </div>
                  )
                }
                {
                  paymentMode === 'UPI' && (
                    <div class="input-group mb-3">
                      <select class="form-select" id="inputGroupSelect01" onChange={(e) => setPaymentType(e.target.value)}>
                        <option value='' >Select UPI method</option>
                        {
                          upiPaymentArray.map((card) => (
                            <option value={card.label}>{card.label}</option>
                          ))
                        }
                      </select>
                    </div>
                  )
                }
              </div>
            </div>
            <hr />
            <div className="row booking-details-section">
              {
                tickets && tickets.map((ticket, i) => (
                  <div className="col-sm-6">
                    <div className="row">
                      <>
                        <div className="col-sm-6">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" name="inlineCheckbox11" type="checkbox" id={`inlineCheckbox11${i}`} value="option1"
                              onChange={(e) => { handleCheckBox(e, i); getSubtotal() }}
                            />
                            <label class="form-check-label" for={`inlineCheckbox11${i}`}>{ticket.name}</label>
                          </div>
                        </div>
                        {
                          ticket.isCkecked && (
                            <div className="col-sm-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Enter quantity"
                                  maxLength={7}
                                  value={tickets[i].quantity}
                                  onChange={(e) => {
                                    var val = e.target.value;
                                    var positiveNumber = /^[1-9]+[0-9]*$/;
                                    if (val == "" || val.match(positiveNumber)) {
                                      handleTicketQuantity(i, val);
                                      getSubtotal()
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          )
                        }
                      </>
                    </div>
                  </div>
                ))}
            </div>
            <div className="row justify-content-center mt-4">
              <button
                type="submit"
                class="btn btn-primary w-auto book-n-print-ticket"
                onClick={(e) => ticketRequest(e)}
              >
                Book &amp; Print Ticket
              </button>
            </div>

          </div>
          <div className="col-sm-3 sticky-content-sidebar">
            <ul className="position-relative w-100 content-sidebar-price-data">
              <li className="d-flex justify-content-bitween">
                <b>Ticket type</b> <b><span>Rate</span></b>
              </li>
              {
                tickets && tickets.map((ticket) => (
                  <li className="d-flex justify-content-bitween">{ticket
                    .name} <span>{ticket.price == 0 ? 'Free' : `₹${ticket.price}`}</span> </li>
                ))}
            </ul>
            <hr />
            <span style={{ color: 'white', fontWeight: 'bold' }}>Ticket Summary</span>
            <table className="content-sidebar-table">
              <thead>
                <tr>
                  <td>Sr. No.</td>
                  <td>Title</td>
                  <td>Qty</td>
                  <td>Price</td>
                </tr>
              </thead>
              <tbody>
                {
                  tickets && tickets.map((ticket, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{ticket.name}</td>
                      <td>{ticket.quantity ? ticket.quantity : Number(0)}</td>
                      <td>{ticket.price ? Number(ticket.quantity) * Number(ticket.price) : Number(0)}</td>
                    </tr>
                  ))
                }
                <tr>
                  <td colSpan={3}><span style={{ fontWeight: 'bold' }}>Subtotal</span></td>
                  <td>{`₹${subTotalPrice}`}</td>
                </tr>
                <tr>
                  <td colSpan={3}><span style={{ fontWeight: 'bold' }}>Tax %</span></td>
                  <td>0 %</td>
                </tr>
                <tr>
                  <td colSpan={3}><span style={{ fontWeight: 'bold' }}>Total</span></td>
                  <td>{`₹${totalPrice}`}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}

export default BookTicket;
