import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import { addDays } from "date-fns";
import swal from "sweetalert";
import axios from "axios";
import "../../i18n";
import Api from "../../../helper/api";
import AuthApi from "../../../helper/authApi";
import Loader from "../../../components/Loader";
import { Datepicker } from "../../components/FormInputs";
import customFunctions from "../../../helper/customFunctions";
import { Button } from "react-bootstrap";
import translate from "translate";

function TicketBooking() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectLanguage] = useState("");
  console.log("🚀 ~ TicketBooking ~ selectedLanguage:", selectedLanguage)
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [mobileNo, setMobileNo] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const uniqueId = uuidv4();
  const [randomNuber, setRandomNumber] = useState("");
  const [isShowLoader, setShowLoader] = useState(false);
  const [finishStatus, setfinishStatus] = useState(false);
  const [email, setEmail] = useState("");
  const [tickets, setTickets] = useState([]);
  const [gardenId, setGardenId] = useState("");
  console.log("🚀 ~ TicketBooking ~ tickets:", tickets)
  const [marathiCount, setMarathiCount] = useState(0);
  const [reason, setReason] = useState("");
  const [statusToggle, setStatusToggle] = useState(false);
  const [gardenList, setGardenList] = useState("");
  console.log("🚀 ~ TicketBooking ~ gardenList:", gardenList)
  const [name, setname] = useState("");
  const [sectedGardenName, setSelectedGardenName] = useState("");
  const [marathiName, setMarathiName] = useState("");
  console.log("🚀 ~ TicketBooking ~ gardenList:", sectedGardenName);
  const path = window.location.pathname;
  translate.engine = "deepl";
  translate.key = process.env.DEEPL_KEY;
  // const baseURL = "http://192.168.1.150:3000/api/";
  // const baseURL = "https://zooticket.pmc.gov.in/api/";
  const baseURL = Api.getNewUrl();
  const [formData, setFormData] = useState({
    Date: '',
    gardenId: '',
  })

  console.log("🚀 ~ TicketBooking ~ formData:", formData)
  useEffect(() => {
    localStorage.removeItem("ticket_data");
    // getTicketFees();
  }, [selectedLanguage, date]);

  useEffect(() => {
    if(formData.Date && formData.gardenId){
         getTicketFees();
    }
  }, [formData]);

  useEffect(() => {
    if (selectedLanguage) return;
    let currLanguage =
      customFunctions.getLocalStorage("lan") &&
      customFunctions.getLocalStorage("lan") == "mr"
        ? "mr"
        : customFunctions.getLocalStorage("lan") == "en"
        ? "en"
        : "mr";

    customFunctions.setLocalStorage("lan", currLanguage ? currLanguage : "mr");
    setSelectLanguage({
      label: currLanguage && currLanguage == "mr" ? "Marathi" : "English",
      value: currLanguage && currLanguage == "mr" ? "mr" : "en",
    });
  }, []);

  const getTicketFees = async () => {
    const bookingDate = customFunctions.getDate(date);
    const api = `${Api.ticketFees}/id?Date=${bookingDate}&gardenId=${Number(formData.gardenId)}`;
    const url = customFunctions.generateUrl(api);
    const { data, message } = await AuthApi.getDataFromServer(url);
    console.log("🚀 ~ getTicketFees ~ data:", data)

    let ticketsArray = []; // To store ticket data in required format
    let totalAmount = 0;
    let totalCount = 0;

    // Check if data exists
    if (data && data.data && data.data.result && data.data.result.length > 0) {
      ticketsArray = data.data.result.map((ticket) => {
        const ticketCount = 1; // Assume a default count of 1 (You can adjust this)
        const ticketPrice = ticket.ticket_prices[0]?.price || 0;

        totalAmount += ticketPrice * ticketCount;
        totalCount += ticketCount;

        return {
          name: ticket.name, // Use the ticket name from the result
          ticket_type_id: ticket.id, // Use the ticket type ID
          count: ticketCount, // Set the count (adjust as needed)
        };
      });
    }

    axios
      .get(api, {
        headers: {
          "session-key": customFunctions
            .encryptKey("PMCZOO2$t@rkD!g!t@1", "46251737DBA8B")
            .toString(),
        },
      })
      .then((response) => {
        if (
          response?.data?.data &&
          response?.data?.data?.result &&
          response?.data?.data?.result?.length
        ) {
          response?.data?.data?.result.map((dt) => {
            if (dt.name === "Disabled") {
              dt.price = Number(0);
            }
            dt.other_description = dt.name !== "Disabled" && "per head";
          });
          setTickets([...response?.data?.data?.result]);
        }
      })
      .catch((error) => {
        if (error.response && [401, 403].includes(error.response.status)) {
          swal("Unauthorized Access", "", "error");
        } else {
          swal("Something went wrong! Please try again later", "", "error");
        }
      });
  };
  

  //Creating a method to change the language onChnage from select box
  const changeLanguageHandler = (e) => {
    customFunctions.setLocalStorage("lan", e?.value);
    setSelectLanguage({
      label: e.label,
      value: e.value,
    });
    i18n.changeLanguage(e.value);
  };

  const increment = (index) => {
    const ticketsIncrementArr = [...tickets];
    if (ticketsIncrementArr[index].count == "999") return;
    if (ticketsIncrementArr[index].count) {
      ticketsIncrementArr[index].count++;
      setTickets(ticketsIncrementArr);
      getSubtotal();
    } else {
      ticketsIncrementArr[index].count = Number(0);
      ticketsIncrementArr[index].count++;
      // numberConvert(ticketsIncrementArr[index].count1++);
      setTickets(ticketsIncrementArr);
      getSubtotal();
    }
    // if(customFunctions.getLocalStorage('lan') == 'mr'){
    //   if (ticketsIncrementArr[index].count) {
    //     let test = numberConvert(ticketsIncrementArr[index].count);
    //     setMarathiCount(test);
    //   }
    // }
  };

  const decrement = (index) => {
    const ticketsDecrementArr = [...tickets];
    if (ticketsDecrementArr[index].count == "" || null) {
      ticketsDecrementArr[index].count = Number(0);
    }
    if (ticketsDecrementArr[index].count > 0) {
      ticketsDecrementArr[index].count--;
      setTickets(ticketsDecrementArr);
      getSubtotal();
    }
    // if(customFunctions.getLocalStorage('lan') == 'mr'){
    //   if (ticketsDecrementArr[index].count) {
    //     let test = numberConvert(ticketsDecrementArr[index].count);
    //     setMarathiCount(test);
    //   }
    // }
  };

  
  const handleClick = (e) => {
    e.preventDefault();
    // setIsOpen(!isOpen);
  };


  const getSubtotal = () => {
    let subTotal =
      tickets &&
      tickets.length > 0 &&
      tickets.reduce(
        (prevValue, curValue) =>
          prevValue +
          curValue?.ticket_prices[0]?.price *
            (curValue.count ? curValue.count : Number(0)),
        0
      );
    setSubTotalPrice(subTotal);
    let calculateTax = (subTotal * Number(tax)) / 100;
    setTotalPrice(subTotal + calculateTax);
  };

  const putTicketRequest = async (status, response) => {
    const resPayment = jwt_decode(response);
    console.log("🚀 ~ putTicketRequest ~ resPayment:", resPayment)
    console.log("🚀 ~ putTicketRequest ~ response:", response)
    status = resPayment.transaction_error_type;
    setShowLoader(true);
    const payload = {
      id: Number(customFunctions.getLocalStorage("paymentId")),
      transaction_status: resPayment.transaction_error_type,
      // transaction_status: "success",
      transaction_response: resPayment,
      // transaction_response: {},
    };

    await axios
      .put(`${baseURL}ticket/booking/update_ticket_status`, payload, {
        headers: { "content-type": "application/json" },
      })
      .then((res) => {
        setShowLoader(false);
        if (res) {
          setShowLoader(false);
          swal(
            resPayment.transaction_error_type === "success"
              ? t("Payment has been done successfully")
              : // "QR and UPI"
                t("Payment has been failed"),
            "",
            resPayment.transaction_error_type === "success"
              ? "success"
              : "error"
          );
          if (res.data.data === "Transaction failed") {
            return true;
          }
          customFunctions.removeLocalStorage("paymentId");
          customFunctions.setDataToLocalStorage(
            "ticket_data",
            res.data.data,
            true
          );
          navigate("/ticket", { state: path });
        }
      })
      .catch(function (error) {
        if (error) {
          setShowLoader(false);
          swal(
            error.response.data.message
              ? error.response.data.message
              : "Something went wrong! Please try again later",
            "",
            "error"
          );
          navigate("/ticket/fail", { state: path });
        }
      });
  };

  const ticketRequest = async (txnData, type) => {
    console.log("🚀 ~ ticketRequest ~ type:", type)
    setShowLoader(true);
  
    // Initialize the base payload structure
    const payload = {
      name: name.trim(),
      mobile_no: mobileNo,
      email: email ? email : "",
      count: [],
      total_count: 0,
      total_amount: totalPrice || 0,
      booking_date: date.toISOString(),
      transaction_status: type === "disabled" ? "success" : "pending",
      transaction_response: "success",
      booking_type: path === "/ticket-booking/qr" ? "qr" : "online",
      gardenId: Number(formData.gardenId),
      user_id: null,
      counterstaff_id: Number(0),
      payment_type: "",
      orderid: txnData.orderid,
      counter_id: 0,
      counter_name: ""
    };
    let totalCount = 0;
    let totalAmount = 0;
    tickets.forEach((ticket) => {
      const ticketPrice = ticket?.ticket_prices?.[0]?.price || 0;
      totalCount += ticket.count || 0;
      totalAmount += (ticket.count || 0) * ticketPrice;
  
      payload.count.push({
        name: ticket.name,
        ticket_type_id: ticket.id, 
        count: ticket.count || 0
      });
    });
  
    payload.total_count = totalCount;
    payload.total_amount = totalAmount;
  
    // Make the API request
    try {
      const url = `${baseURL}ticket/booking/request`;
      const response = await axios.post(url, payload, {
        headers: {
          "content-type": "application/json",
          "session-key": customFunctions
          .encryptKey("PMCZOO2$t@rkD!g!t@1", "46251737DBA8B")
          .toString(),
        },
      });
      console.log("🚀 ~ ticketRequest ~ response:", response)
      
      if (response?.data?.code === 203) {
        setShowLoader(false);
        swal("Online ticket booking is closed for today", "", "error");
        return false;
      }
  
      setShowLoader(false);
      if (response && type === "disabled") {
        swal("Payment has been done successfully", "", "success");
        customFunctions.setDataToLocalStorage("ticket_data", response.data.data, true);
        navigate("/ticket", { state: path });
      } else if (response?.data?.code === 200) {
        swal("Payment has been done successfully", "", "success");
        customFunctions.setLocalStorage("paymentId", response.data.data.id);
        paymentMethod(response.data.data);
        return true;
      }
        console.log("🚀 ~ ticketRequest ~ response.data.data:", response.data.data)
  
    } catch (error) {
      customFunctions.setDataToLocalStorage("error", error);
      setShowLoader(false);
      swal(
        error?.response?.data?.message || "Something went wrong! Please try again later",
        "",
        "error"
      );
    }
  
    return false;
  };
  
  const paymentMethod = async (data) => {
    console.log("🚀 ~ paymentMethod ~ data:", data)
    let decoded = jwt_decode(data.data.billdeskData);
    console.log("🚀 ~ paymentMethod ~ decoded:", decoded)
    // var result = await ticketRequest(data, "pending");
    // if (result === false) return;
    if (data.success !== true) return;

    var flow_config = {
      merchantId: decoded.mercid,
      bdOrderId: decoded.bdorderid,
      authToken: decoded.links[1].headers.authorization,
      childWindow: false,
      // returnUrl: "http://www.demo.com/api/pgresponse",
      retryCount: 3,
      prefs: { payment_categories: ["upi", "qr", "card", "nb"] },
    };

    //  var flow_config = {
    //    merchantId: "PMCZOO",
    //    bdOrderId: "OA3Q3EXRKGEM",
    //    authToken: "OToken e1fd4088391ecbfdb9762fe485a1cb38d248d8489062f316426812870ea622ad44164e9b9c95cf728e45f2efa18b1fc11bd6169cc281e0e60e739e4eecf4c172be1c2241abcb968fe8dbdc45379fb6ff6449871f662980924a33268e9e5def8f6ff2f5a0eed854997efbae4e1216cfed01d6559a3e1cecba38ee9dc1ab1187e4857da0b42c5ca58afb8d2a0f9ad44061b95a1b6eda283cd2476b647a82db70565d76fd4c.70675f706172616d5f656e6333",
    //    childWindow: false,
    //    // returnUrl: "http://www.demo.com/api/pgresponse",
    //    retryCount: 3,
    //    prefs: { payment_categories: ["upi", "qr", "card", "nb"] },
    //  };

    // if (!result) return;
    var responseHandler = function (txn) {
      if (txn.status == 200 && txn.txnResponse.status !== "0399") {
        putTicketRequest("success", txn.txnResponse.transaction_response);
      } else if (txn.status == 200 && txn.txnResponse.status == "0399") {
        putTicketRequest("fail", txn.txnResponse.transaction_response);
      }
    };

    var config = {
      responseHandler: responseHandler,
      // merchantLogo: "data:image/png;base64:eqwewqesddhgjdxsc==",
      flowConfig: flow_config,
      flowType: "payments",
    };
    // let myWindow = window.loadBillDeskSdk(config);
    // const timer = setInterval(function () {
    //   if (myWindow.closed) {
    //     clearInterval(timer);
    //     window.close();
    //     // alert("closed");
    //   }
    // }, 1000);

    console.log("window---", window.loadBillDeskSdk(config));
  };

  const validateForm = () => {
    setShowLoader(true);
    const disableValid = tickets.filter((ticket, i) => {
      if (ticket.name === "Disabled" && ticket.count && ticket.count > 0) {
        return ticket;
      }
    });

    const otherTicket = tickets.filter((ticket, i) => {
      if (ticket.name !== "Disabled" && ticket.count && ticket.count !== 0) {
        return ticket;
      }
    });

    const err = tickets.some((t) => t.count > 0);
    let valid = true;
    if (selectedDate === null || selectedDate === "") {
      swal(t("Please select date"), "", "error");
      valid = false;
      setShowLoader(false);
      return;
    } else if (name === "") {
      swal(t("Please enter your full name"), "", "error");
      valid = false;
      setShowLoader(false);
      return;
    } else if (!customFunctions.validateMobileNumber(mobileNo)) {
      swal(t("Please enter valid mobile number"), "", "error");
      valid = false;
      setShowLoader(false);
      return;
    } else if (mobileNo === "" || mobileNo.length < 10) {
      swal(
        `${
          mobileNo === ""
            ? t("Please enter your mobile number")
            : t("Please enter valid mobile number")
        }`,
        "",
        "error"
      );
      valid = false;
      setShowLoader(false);
      return;
    } else if (email !== "" && !customFunctions.validateEmail(email)) {
      swal(t("Please enter correct email address"), "", "error");
      valid = false;
      setShowLoader(false);
      return;
    } else if (!err) {
      swal(t("Please select atleast one ticket"), "", "error");
      valid = false;
      setShowLoader(false);
      return;
    }
    if (
      disableValid &&
      disableValid.length > 0 &&
      otherTicket &&
      otherTicket.length === 0
    ) {
      ticketRequest("", "disabled");
      return;
    }

    if (valid) {
      // getBilldeskCreate();
      ticketRequest("", "pending");
    }
  };

  // const getBilldeskCreate = () => {
  //   axios
  //     .get(
  //       `${baseURL}order/payment/create?amount=${Number(
  //         totalPrice
  //       )}&name=${name}&mobile=${mobileNo}`
  //     )
  //     .then((response) => {
  //       console.log("🚀 ~ .then ~ response:", response)
  //       if (response?.data?.data) {
  //         setShowLoader(false);
  //         let decoded = jwt_decode(response?.data?.data?.data);
  //         console.log("🚀 ~ .then ~ decoded:", decoded)
  //         decoded.orderid = response?.data?.data?.orderId || null;
  //         console.log("🚀 ~ .then ~ decoded.orderid:", decoded.orderid)
  //         paymentMethod(decoded);
  //       }
  //     })
  //     .catch((err) => {
  //       setShowLoader(false);
  //       swal("Something went wrong! Please try again later", "", "error");
  //     });
  // };

  const getBilldeskCreate = () => {
    axios
      .get(
        `${baseURL}order/payment/create?amount=${Number(totalPrice)}&name=${name}&mobile=${mobileNo}`,
        {headers: {
          'Authorization': localStorage.getItem("access_token"), // If token-based auth is used
          // 'Content-Type': 'application/json', // Adjust based on API requirements
          // Add other headers as necessary
        }}
      )
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        if (response?.data?.data) {
          console.log("🚀 ~ .then ~ response?.data?.data:", response?.data?.data)
          setShowLoader(false);
          let jwtToken = response.data.data.data;
          let decoded = jwt_decode(jwtToken);
          console.log("🚀 ~ .then ~ decoded:", decoded)
          
          // Log the decoded JWT to see its structure
          console.log("🚀 ~ .then ~ decoded===========:", jwtToken);
          
          // Get orderId from the response directly
          const orderId = response?.data?.data?.orderId || null;
          console.log("🚀 ~ .then ~ orderId:", orderId);
  
          // Now add orderId to decoded or create a new object
          const paymentDetails = {
            ...decoded,
            orderId: orderId // Add the orderId to the object
          };
          
          console.log("🚀 ~ .then ~ paymentDetails:", paymentDetails);
          paymentMethod(paymentDetails);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        swal("Something went wrong! Please try again later", "", "error");
      });
  };
  
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (
        window.confirm("Are you sure you want to leave? data will be lost.")
      ) {
        setfinishStatus(true);
        navigate(-1);
        location.reload();
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 3;
  };

  const numberConvert = (number) => {
    let numberConvertArr = number.toString().split("");
    let newNum = [];

    numberConvertArr &&
      numberConvertArr?.length &&
      numberConvertArr.map((num) => {
        return newNum.push(t(num));
      });
    let numberCon = newNum.join("");
    return t(numberCon);
  };

  useLayoutEffect(() => {
    // getSetting();
    getGardenList();
  }, []);

  const getSetting = async () => {
    setShowLoader(true);

    const api = `${Api.settingUrl}?zoo_id=2`;
    const url = customFunctions.generateUrl(api);
    const { data, message } = await AuthApi.getDataFromServer(url);
    let status = data?.data?.setting_value == "true" ? true : false;
    setStatusToggle(status);
    if (data?.success) {
      setShowLoader(false);
      setReason(data?.data?.description);
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getGardenList = async () => {
    setShowLoader(true);
    const api = Api.gardenListUrl;
    const url = customFunctions.generateUrl(api);
    const { data, message } = await AuthApi.getDataFromServer(url);
    console.log("🚀 ~ getGardenList ~ data:", data);
    if (data?.success) {
      setShowLoader(false);
      setGardenList(data?.data?.rows);
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };
 
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const garden = gardenList.find((g) => g.id === Number(value)); 
    setSelectedGardenName(garden ? garden.name : "");
    setMarathiName(garden ? garden.title_marathi : "")
  };
  
  const handleDateChange = (date) => {
    const formattedDate = customFunctions.getDate(date);
  
    setFormData((prevData) => ({
      ...prevData,
      Date: formattedDate, 
    }));
    
    setSelectedDate(formattedDate);
  };

  return (
    <>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div className="admin-ticket-booking">
        <header>
          <div className="container-fluid">
            <div className="d-flex justify-content-center">
              <div className="me-3 main-logo">
                <img src="/images/pmclogo.png" alt="logo" />
              </div>
              <div className=" main-logo">
                <img src="/images/dummyimage.webp" alt="logo" />
              </div>

              <Button
                className="language-button"
                onClick={(e) => {
                  selectedLanguage?.value == "mr"
                    ? changeLanguageHandler({
                        label: "English",
                        value: "en",
                      })
                    : changeLanguageHandler({
                        label: "Marathi",
                        value: "mr",
                      });
                }}
              >
                {selectedLanguage?.value == "en" ? "मराठी" : "English"}
              </Button>
            </div>
          </div>
        </header>
        <section className="position-relative w-100 ">
          <div className="container">
            {/* <div className="summery-header position-relative w-100 text-center">
              <div className="langauge-block">
          
              </div>
              <h1 className="mb-3">{t("title")}</h1>
              <hr className="d-none d-md-block" />
              <p className="d-md-block">
                <h4 className="text-warning text-decoration-none info">
                  {t("information")}
                </h4>
              </p>
            </div> */}
          </div>
        </section>

        {statusToggle ? (
          <MaintenanceMode message={reason} />
        ) : (
          <>
            <section className="position-relative w-100">
              <div className="container">
                <div className="ticket-form-section position-relative w-100">
                  <div className="row summyform-wrapper justify-content-center">
                    <div className="col-sm-4 d-none d-md-none text-center date-picker-mobile-wrap main-date-picker">
                      <a
                        href="#"
                        onClick={handleClick}
                        className="date-picker-mobile datepicker-show"
                      >
                        <img src="/images/calender-white-icon.svg" alt="icon" />
                        &nbsp; {selectedDate || null}
                      </a>
                      <Datepicker
                        selected={date || null}
                        dateFormat="dd/MM/yyyy"
                        containerClass="datepicker-hide hide"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        minDate={customFunctions.getMinDate()}
                        maxDate={addDays(new Date(), 30)}
                        // maxDate={new Date('Sun Dec 31 2024 17:00:00 GMT+0530 (India Standard Time')}
                        filterDate={isWeekday}
                        inline
                      />
                    </div>
                    <div className="col-4 col-sm-4 col-xl-4 d-md-block main-date-picker position-relative">
                      <div className="mb-3 input-group text-center datepicker-show">
                        <span className="input-group-text" id="basic-addon1">
                          <img
                            src="/images/calender-white-icon.svg"
                            alt="icon"
                          />
                        </span>
                        <input
                          type="text"
                          // disabled
                          readOnly
                          onChange={() => {}}
                          className="form-control"
                          value={selectedDate || null}
                          placeholder={t("Please select the date*")}
                        />
                      </div>
                      <div className="booking-datepicker">
                        <Datepicker
                          selected={date || null}
                          containerClass="datepicker-hide hide"
                          dateFormat="dd/MM/yyyy"
                          minDate={customFunctions.getMinDate()}
                          maxDate={addDays(new Date(), 30)}
                          // maxDate={new Date('Sun Dec 31 2024 17:00:00 GMT+0530 (India Standard Time)')}
                          // filterDate={isWeekday}
                          value={formData?.Date}
                          onChange={handleDateChange}
                          inline
                        />
                      </div>
                    </div>
                    
                    <div className="col-12 col-sm-5 col-xl-4 d-md-block">
                      <div className="mb-3 input-group text-center">
                        <select
                           id="name"
                           name="gardenId" 
                           className="form-control"
                           value={formData?.gardenId || ""}
                           onChange={handleChange} 
                        >
                          <option value="" hidden>
                            {t("Please select the garden")}
                          </option>
                          {gardenList?.length > 0 && gardenList.map((garden) => (
                            console.log("🚀 ~ TicketBooking ~ garden:", garden),
                            <option key={garden.id} value={garden.id}>
                              {selectedLanguage?.value == "mr" ? garden.title_marathi : garden.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {formData?.gardenId && 
                    <div className="col-md-12 text-center">
                      <h2 className="h2 fw-bold text-white pb-4 my-4 text-capitalize border-bottom border-white">{selectedLanguage?.value == "mr" ? marathiName : sectedGardenName}</h2>
                      </div> }
                  </div>
                </div>
                
                <div className="ticket-form-section position-relative w-100">
                  {tickets.length > 0 && 
                <div className="row summyform-wrapper justify-content-center mb-4">
                <div className="col-4 col-sm-5 col-xl-4 d-md-block">
                <div className="mb-3 input-group text-center">
                        <span className="input-group-text" id="basic-addon1">
                          <img src="/images/user-icon-ticket.svg" alt="icon" />
                        </span>
                        <input
                          autoComplete="newfullName"
                          type="text"
                          className="form-control"
                          placeholder={t("Enter full name*")}
                          value={name || ""}
                          onChange={(e) => {
                            const re = /^[a-zA-Z ]*$/;
                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              setname(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-4 col-sm-5 col-xl-4 d-md-block">
                    <div className="mb-3 input-group text-center">
                        <span className="input-group-text" id="basic-addon1">
                          <img src="/images/phone-icon-ticket.svg" alt="icon" />
                        </span>
                        <input
                          type="text"
                          className="form-control "
                          placeholder={t("Enter number*")}
                          value={mobileNo || ""}
                          onChange={(e) => {
                            var val = e.target.value;
                            var phoneformat = /^\d{1,10}$/;
                            if (val === "" || val.match(phoneformat)) {
                              setMobileNo(val);
                            }
                          }}
                        />
                      </div>
                    </div>
                    </div>}
                  <div className="row mb-4">
                    {tickets &&
                      tickets.length > 0 &&
                      tickets.map((tk, i) => (
                        console.log("🚀 ~ TicketBooking ~ tk:", tk),
                        <div key={i} className="col-6 col-md-6 col-lg-4">
                          <div className="card ticket-block border-0 bg-transparent">
                            <div className="row g-0 h-100">
                              <div className="col-8 col-md-8">
                                <div className="card-body bg-white h-100">
                                  {tk.name && (
                                    <h3
                                      className={
                                        !tk.description
                                          ? "cs-border-bottom"
                                          : ""
                                      }
                                    >
                                      {selectedLanguage.value == "mr" ? tk.name_marathi : tk.name}
                                    </h3>
                                  )}
                                  {tk.description && (
                                    <h6 className="cs-border-bottom">
                                      {selectedLanguage.value !== "mr"
                                        ? tk.description
                                        : ""}
                                    </h6>
                                  )}
                                  {tk?.ticket_prices[0]?.price == 0 ? (
                                    <h2>{t("Free")}</h2>
                                  ) : (
                                    <h2>₹{t(tk?.ticket_prices[0]?.price)}</h2>
                                  )}
                                  {tk.other_description && (
                                    <p>{t("other_description")}</p>
                                  )}
                                </div>
                              </div>
                              <div className="col-4 col-md-4 ">
                                <div className="quantity green-bg">
                                  <a
                                    href="/"
                                    className="quantity__minus"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      decrement(i);
                                    }}
                                  >
                                    <img
                                      src="/images/minus-icon.svg"
                                      alt="icon"
                                    />
                                  </a>
                                  <input
                                    name="quantity"
                                    placeholder="0"
                                    type="text"
                                    autoComplete="newQuantity"
                                    onChange={(e) => {
                                      let ticketCount = [...tickets];
                                      ticketCount[i].count =
                                        customFunctions.numberMaskingDecimalBookTicket(
                                          e.target.value
                                        );
                                      getSubtotal();
                                      setTickets([...ticketCount]);
                                    }}
                                    className="quantity__input"
                                    value={
                                      customFunctions.getLocalStorage("lan") ==
                                      "mr"
                                        ? tickets[i].count
                                        : tickets[i].count
                                    }
                                    maxLength={3}
                                  />
                                  <a
                                    href="#"
                                    className="quantity__plus"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      increment(i);
                                    }}
                                  >
                                    <img
                                      src="/images/plus-icon.svg"
                                      alt="icon"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* {tickets.length > 0 &&
                     <div className="col-12">
                     <p className="alert alert-warning badge-warning border-0 text-dark">
                       <strong>
                         {t("Note: Free entry for disabled person.")}
                       </strong>
                     </p>
                   </div>
                  } */}
                  {(
                    tickets &&
                    tickets.length > 0 &&
                    tickets.filter((t) => t.count && t.count !== 0)
                  ).length > 0 && (
                    <div className="devider-border d-none d-md-block"></div>
                  )}
                </div>
              </div>
            </section>
            {(
              tickets &&
              tickets.length > 0 &&
              tickets.filter((t) => t.count && t.count !== 0)
            ).length > 0 && (
              <section className="position-relative w-100">
                <div className="container">
                  <div className="ticket-order-summary  position-relative w-100 ">
                    <h2 className="text-center fw-bold">{t("summary")}</h2>
                    <div className="cutsom-table">
                      <table
                        className="w-100"
                        border="0"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        <thead>
                          <tr>
                            <th>{t("tb_title")}</th>
                            <th>{t("tb_rate")}</th>
                            <th>{t("tb_qty")}</th>
                            <th>{t("tb_price")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tickets &&
                            tickets.length > 0 &&
                            tickets.map(
                              (tk, i) =>
                                tickets[i].count > 0 && (
                                  <tr key={i}>
                                    <td>{selectedLanguage.value == "mr" ? tk.name_marathi : tk.name }</td>
                                    {tk?.ticket_prices[0]?.price === "Free" ? (
                                      <td>{t("price")}</td>
                                    ) : (
                                      <td>{`₹${tk?.ticket_prices[0]?.price}`}</td>
                                    )}
                                    <td>{tk.count}</td>
                                    <td>
                                      {tk?.ticket_prices[0]?.price === "Free"
                                        ? "₹0"
                                        : "₹" +
                                          Number(tk?.ticket_prices[0]?.price) *
                                            Number(tk.count)}
                                    </td>
                                  </tr>
                                )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 offset-sm-6">
                      <div className="total-table">
                        <table
                          className="w-100"
                          border="0"
                          cellSpacing="0"
                          cellPadding="0"
                        >
                          <tbody>
                            <tr>
                              <td>{t("subtotal")}</td>
                              <td>₹{subTotalPrice}</td>
                            </tr>
                            <tr>
                              <td>{t("tax")} %</td>
                              <td>{tax}%</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>{t("total")}</td>
                              <td>₹{totalPrice}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <a
                        href="/"
                        onClick={async (e) => {
                          e.preventDefault();
                          await validateForm();
                        }}
                        className="btn btn-primary text-center d-block mt-4 light-green-bg pay-now-btn"
                      >
                        {t("paynow")}
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </>
        )}
      </div>
    </>
  );
}

const MaintenanceMode = ({ message }) => {
  return (
    <>
      <div className="d-flex justify-content-center summery-header">
        <h1>{"Site is under maintenance..."}</h1>
      </div>
    </>
  );
};

export default TicketBooking;
