import React from 'react';

const Footer = (props) => (
  <React.Fragment>
    <footer className="footer">
      <span className="text-muted d-none d-sm-inline-block float-right">
      Copyright © {new Date().getFullYear()} PMC. All Rights Reserved. 
      </span>
    </footer>
  </React.Fragment>
);
export default Footer;
