import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { DropdownInput } from '../../components/FormInputs';
import customFunctions from '../../helper/customFunctions';

const ReportPopup = (props) => {

  const [firstMonth, setFirstMonth] = useState(null);
  const [secondMonth, setSecondMonth] = useState(null);
  const [firstMonthData, setFirstMonthData] = useState([]);
  const [secondMonthData, setSecondMonthData] = useState([]);
  const [firstMonthErr, setFirstMonthErr] = useState('');
  const [secondMonthErr, setSecondMonthErr] = useState('');
  const monthData = [
    {
      label: 'Jan',
      value: '01'
    },
    {
      label: 'Feb',
      value: '02'
    },
    {
      label: 'Mar',
      value: '03'
    },
    {
      label: 'Apr',
      value: '04'
    },
    {
      label: 'May',
      value: '05'
    },
    {
      label: 'Jun',
      value: '06'
    },
    {
      label: 'Jul',
      value: '07'
    },
    {
      label: 'Aug',
      value: '08'
    },
    {
      label: 'Sep',
      value: '09'
    },
    {
      label: 'Oct',
      value: '10'
    },
    {
      label: 'Nov',
      value: '11'
    },
    {
      label: 'Dec',
      value: '12'
    },
  ]

  useEffect(() => {
    const currentMonth = new Date().getMonth() + Number(1);
    var result = monthData.filter((month) => {
      return Number(month.value) <= Number(currentMonth);
    })
    setFirstMonthData(result);
  }, [])

  useEffect(() => {
    if (!firstMonth) return;
    var result = firstMonthData.filter((month) => {
      return month.label !== firstMonth.label
    });
    setSecondMonthData(result);
  }, [firstMonth]);

  const validate = (e) => {
    e.preventDefault();
    let firstMonthErr = '', secondMonthErr = '', isValid = true;

    if (!firstMonth && !secondMonth) {
      firstMonthErr = 'Please select first month';
      setFirstMonthErr(firstMonthErr);
      isValid = false
    }

    if (firstMonth &&
      customFunctions.checkIfEmpty(secondMonth)) {
      secondMonthErr = 'Please select second month';
      setSecondMonthErr(secondMonthErr);
      isValid = false
    }

    setFirstMonthErr(firstMonthErr);
    setSecondMonthErr(secondMonthErr);

    if (isValid) {
      setFirstMonthErr('');
      setSecondMonthErr('');
      onSubmit();
    }
  }

  const onSubmit = () => {
    props.callback(firstMonth.value, secondMonth.value);
    props.popupClose();
  }

  return (
    <>
      <div>
        <Modal
          className={'filter-popup'}
          size="md"
          centered
          onHide={props.popupClose}
          show={props.compareMonth}
          keyboard={false}
          backdrop="static"
        >
          <div className="add-task-popup schedule-filter-popup">
            <Modal.Header className="col-12">
              <span style={{ fontWeight: 'bold' }}>
                Select the months you want to compare!
              </span>
              <button onClick={() => props.popupClose()} type="button" class="btn-close"></button>
            </Modal.Header>
            <Modal.Body>
              <>
                <Row sm={12}>
                  <Col sm={6} className="admin-datepicker">
                    <DropdownInput
                      label="First Month"
                      options={firstMonthData}
                      defaultValue={firstMonth}
                      onSelectHandler={(select) => {
                        setSecondMonth(null);
                        setFirstMonth(select);
                      }}
                    />
                    <span style={{ color: 'red' }}>{firstMonthErr}</span>
                  </Col>

                  <Col sm={6} className="admin-datepicker">
                    <DropdownInput
                      label="Second Month"
                      options={secondMonthData}
                      defaultValue={secondMonth}
                      onSelectHandler={(select) => {
                        setSecondMonth(select);
                      }}
                      disabled={!firstMonth}
                    />
                    <span style={{ color: 'red' }}>{secondMonthErr}</span>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ textAlign: 'center' }}>
                    <Button
                      onClick={(e) => {
                        validate(e);
                      }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </>
            </Modal.Body>
          </div>
        </Modal>
      </div >
    </>
  )
}

export default ReportPopup;