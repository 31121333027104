import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Pie, Bar, Line } from "react-chartjs-2";
import { Button, Form } from "react-bootstrap";
import swal from "sweetalert";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  registerables,
} from "chart.js";
import { Helmet } from "../../components/FormInputs";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import customFunctions from "../../helper/customFunctions";
import { Card } from "react-bootstrap";
import MultipleArea from "../../components/pieChart/multiAreaChart";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CountUp from "react-countup";
import moment from "moment";
import CompareMonthComponent from "./compareMonth.component";
import { DropdownInput, Datepicker } from "../../components/FormInputs";
import Loader from "../../components/Loader/index";
import HolidayComponent from "./holiday.component";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import OnlineTicketPopUp from "./onlineTicket.component";

ChartJS.register(...registerables);
ChartJS.register(
  ArcElement,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  LinearScale
);

function Dashboard() {
  const ref = useRef();
  const [title, setTitle] = useState();
  const [isShowLoader, setShowLoader] = useState(false);
  const [onlineTicketToggle, setOnlineTicketToggle] = useState(false);
  const [ticketToggle, setTicketToggle] = useState("day");
  const [visitorToggle, setVisitorToggle] = useState("day");
  const [ticketFilterToggle, setTicketFilterToggle] = useState("");
  const [totalMonthCount, setTotalMonthCount] = useState(null);
  const [totalMonthVisitors, setTotalMonthVisitors] = useState(Number(0));
  const [totalZooStaff, setTotalZooStaff] = useState(null);
  const [value, onChange] = useState(new Date());
  const [onlineTicketPercentage, setOnlineTicketPercentage] = useState(0);
  const [offlineTicketPercentage, setOfflineTicketPercentage] = useState(0);
  const [qrTicketPercentage, setQrTicketPercentage] = useState(0);
  const [adultTicketPercentage, setAdultTicketPercentage] = useState(0);
  const [childTicketPercentage, setChildTicketPercentage] = useState(0);
  const [disabledTicketPercentage, setDisabledTicketPercentage] = useState(0);
  const [foreignerTicketPercentage, setForeignerTicketPercentage] = useState(0);
  const [zooId, setZooId] = useState(null);
  const [incomeStatus, setIncomeStatus] = useState({
    onlineIncome: [],
    offlineIncome: [],
    qrIncome: [],
  });
  const [ticketCompareArray, setTicketCompareArray] = useState([]);
  const [ticketCompareData, setTicketCompareData] = useState({});
  const [ticketCompareOption, setTicketCompareOption] = useState({});
  const [lineCompareData, setLineCompareData] = useState({});
  const [lineCompareToggle, setLineCompareToggle] = useState("year");
  const [monthlyOnlineCount, setMonthlyOnlineCount] = useState(0);
  const [monthlyOfflineCount, setMonthlyOfflineCount] = useState(0);
  const [monthlyQRCount, setMonthlyQRCount] = useState(0);
  const [monthlyAdultCount, setMonthlyAdultCount] = useState(0);
  const [monthlyChildCount, setMonthlyChildCount] = useState(0);
  const [monthlyDisabledCount, setMonthlyDisabledCount] = useState(0);
  const [monthlyForeignerCount, setMonthlyForeignerCount] = useState(0);
  const [totalMonthlyCount, setTotalMonthlyCount] = useState(0);
  
  const [staffData, setStaffData] = useState([]);
  const [visitorValues, setVisitorValues] = useState({
    adultCount: Number(0),
    childCount: Number(0),
    disabledCount: Number(0),
    foreignerCount: Number(0),
    visitorTotal: Number(0),
  });
  const [ticketValues, setTicketValues] = useState({
    offlineTicketCount: Number(0),
    onlineTicketCount: Number(0),
    qrTicketCount: Number(0),
    ticketTotal: Number(0),
  });
  const [count, forceUpdate] = useState(0);
  const [compareMonth, setCompareMonth] = useState(false);
  const [monthArray, setMonthArray] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [redeemCount, setRedeemCount] = useState(null);
  const [ticketStartDate, setTicketStartDate] = useState(null);
  const [ticketEndDate, setTicketEndDate] = useState(null);
  const [visitorStartDate, setVisitorStartDate] = useState(null);
  const [visitorEndDate, setVisitorEndDate] = useState(null);
  const [barStartDate, setBarStartDate] = useState(null);
  const [barEndDate, setBarEndDate] = useState(null);
  const [collectionStartDate, setCollectionStartDate] = useState(null);
  const [collectionEndDate, setCollectionEndDate] = useState(null);
  const [barGraphData, setBarGraphData] = useState({});
  const [countData, setCountData] = useState({
    online: [],
    offline: [],
    qr: [],
    date: [],
  });
  const [yearCountData, setYearCountData] = useState({
    online: [],
    offline: [],
    qr: [],
  });
  const [holidayListData, setHolidayListData] = useState([]);
  const [holidayData, setHolidayData] = useState([]);
  const navigate = useNavigate();
  const currentMonth = new Date().getMonth() + Number(1);
  const currentYear = new Date().getFullYear();
  const donutFilter = [
    {
      label: "Today",
      value: "day",
    },
    {
      label: "Current Month",
      value: "month",
    },
    {
      label: "Current Year",
      value: "year",
    },
    {
      label: "Custom Date",
      value: "custom",
    },
  ];

  let compareData1 = {
    labels: [],
    datasets: [],
  };

  const chartFilter = [
    {
      label: "Month",
      value: "month",
    },
    {
      label: "Year",
      value: "year",
    },
    {
      label: "Custom Date",
      value: "custom",
    },
  ];

  useEffect(() => {
    const loginToken = customFunctions.getLocalStorage("access_token");
    if (!loginToken) {
      navigate("/login");
      return;
    }
    setTitle("Dashboard");
    const zoo = customFunctions.getLocalStorage("Zoo_id");
    setZooId(zoo);
  }, []);

  useEffect(() => {
    if (!zooId) return;
    getReportCount();
    getMonthlyCount();
    getMonthlyVisitors();
    getTotalZooStaff();
    getIncomeStatus();
    getStaffMembers();
    getHolidayList();
  }, [zooId]);

  useEffect(() => {
    ticketCompareBarData();
  }, [ticketCompareArray]);

  useEffect(() => {
    if (!ticketFilterToggle) return;
    getReportCount(ticketFilterToggle);
  }, [ticketFilterToggle, ticketToggle, visitorToggle]);

  useEffect(() => {
    if (ticketStartDate && ticketEndDate) getReportCount("customTicket");
  }, [ticketStartDate, ticketEndDate]);

  useEffect(() => {
    if (visitorStartDate && visitorEndDate) getReportCount("customVisitor");
  }, [visitorStartDate, visitorEndDate]);

  useEffect(() => {
    getSelectedDateCollectionAmount();
  }, [collectionStartDate, collectionEndDate]);

  useEffect(() => {
    setBarGraphData({
      labels: countData.date,
      datasets: [
        {
          label: "Online Ticket",
          backgroundColor: "#fb7b00",
          borderWidth: 1,
          stack: 1,
          hoverBackgroundColor: "#fb7b00",
          hoverBorderColor: "#fb7b00",
          data:
            countData.online && countData.online.length ? countData.online : [],
        },
        {
          label: "Offline Ticket",
          backgroundColor: "#0895e1",
          borderWidth: 1,
          stack: 1,
          hoverBackgroundColor: "#0895e1",
          hoverBorderColor: "#0895e1",
          data:
            countData.offline && countData.offline.length
              ? countData.offline
              : [],
        },
        {
          label: "QR Ticket",
          backgroundColor: "#156539",
          borderWidth: 1,
          stack: 1,
          hoverBackgroundColor: "#156539",
          hoverBorderColor: "#156539",
          data: countData.qr && countData.qr.length ? countData.qr : [],
        },
      ],
    });
  }, [countData]);

  useEffect(() => {
    if (barEndDate && barStartDate) {
      getChartData();
    }
  }, [barEndDate, barStartDate]);

  const getChartData = async () => {
    const postData = {
      zoo_id: Number(zooId),
      start_date: customFunctions.getDate(barStartDate),
      end_date: customFunctions.getDate(barEndDate),
    };
    const payload = customFunctions.cleanObject(postData);
    const endPoint = Api.chartDataCount;
    const url = customFunctions.generateUrl(endPoint, payload);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      const onlineCountArr = [];
      const offlineCountArr = [];
      const qrCountArr = [];
      const dateArr = [];
      data &&
        data.data &&
        data.data.getData &&
        data.data.getData.length &&
        data.data.getData.map((gd) => {
          onlineCountArr.push(
            gd.onCount ? gd.onCount : gd.onlineCount ? gd.onlineCount : 0
          );
          offlineCountArr.push(
            gd.offCount ? gd.offCount : gd.offlineCount ? gd.offlineCount : 0
          );
          dateArr.push(gd.date ? gd.date : "");
          qrCountArr.push(gd.qrCount ? gd.qrCount : 0);
        });
      setCountData({
        online: onlineCountArr,
        offline: offlineCountArr,
        qr: qrCountArr,
        date: dateArr,
      });
    } else {
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getTotalZooStaff = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.users}?page=1&limit=100&role_id=2`
    );
    if (data && data.success === true) {
      setTotalZooStaff(data.data.count);
    } else {
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getMonthlyVisitors = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.bookingReports}?zoo_id=${zooId}&month=${currentMonth}&year=${currentYear}&page=1&limit=100&transaction_status=success`
    );
    if (data && data.success === true) {
      setTotalMonthVisitors(data.data.countArray[0].totalVisiter);
    } else {
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getMonthlyCount = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.dashboardCount}?zoo_id=${zooId}&month=${currentMonth}&year=${currentYear}`
    );
    if (data && data.success === true && data.data) {
      setTotalMonthCount(data.data.count.tickettotalcount);
      setMonthlyOnlineCount(data.data.count.onlinecount);
      setMonthlyOfflineCount(data.data.count.offlinecount);
      setMonthlyQRCount(data.data.count.qrcount);
      setMonthlyAdultCount(data.data.count.adult_count);
      setMonthlyChildCount(data.data.count.child_count);
      setMonthlyDisabledCount(data.data.count.disabledperson_count);
      setMonthlyForeignerCount(data.data.count.foreigner_count);
      setTotalMonthlyCount(data?.data?.count?.totalVisiters)
    } else {
      swal(message || "Something went wrong", "", "error");
    }
  };

  const getReportCount = async (type = "") => {
    let postData = {};
    if (type === "ticket") {
      postData = {
        zoo_id: Number(zooId),
        start_date:
          ticketToggle === "day" ? customFunctions.getDate(new Date()) : "",
        month: ticketToggle === "month" ? Number(currentMonth) : "",
        year:
          ticketToggle === "year" || ticketToggle === "month"
            ? Number(currentYear)
            : "",
      };
    } else if (type === "customTicket" && ticketStartDate && ticketEndDate) {
      postData = {
        zoo_id: Number(zooId),
        start_date:
          ticketToggle === "custom"
            ? customFunctions.getDate(ticketStartDate)
            : "",
        end_date:
          ticketToggle === "custom"
            ? customFunctions.getDate(ticketEndDate)
            : "",
      };
    } else if (type === "customVisitor" && visitorStartDate && visitorEndDate) {
      postData = {
        zoo_id: Number(zooId),
        start_date:
          visitorToggle === "custom"
            ? customFunctions.getDate(visitorStartDate)
            : "",
        end_date:
          visitorToggle === "custom"
            ? customFunctions.getDate(visitorEndDate)
            : "",
      };
    } else if (type === "visitor") {
      postData = {
        zoo_id: Number(zooId),
        start_date:
          visitorToggle === "day" ? customFunctions.getDate(new Date()) : "",
        month: visitorToggle === "month" ? Number(currentMonth) : "",
        year:
          visitorToggle === "year" || visitorToggle === "month"
            ? Number(currentYear)
            : "",
      };
    } else {
      postData = {
        zoo_id: Number(zooId),
        start_date: customFunctions.getDate(new Date()),
      };
    }

    const payload = customFunctions.cleanObject(postData);
    const endPoint = Api.dashboardCount;
    const url = customFunctions.generateUrl(endPoint, payload);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.success) {
      if (data.data && data.data.count) {
        if (type === "ticket") {
          const ticketValues = {
            offlineTicketCount: data.data.count.offlinecount,
            onlineTicketCount: data.data.count.onlinecount,
            qrTicketCount: data.data.count.qrcount,
            ticketTotal: data.data.count.tickettotalcount,
          };
          setTicketValues(ticketValues);
          setOnlineTicketPercentage(data.data.count.onlineTicketPer);
          setOfflineTicketPercentage(data.data.count.offlineTicketPer);
          setQrTicketPercentage(data.data.count.qrTicketPer);
        } else if (type === "visitor") {
          setAdultTicketPercentage(data.data.count.adultPer);
          setChildTicketPercentage(data.data.count.childPer);
          setDisabledTicketPercentage(data.data.count.disabledPer);
          setForeignerTicketPercentage(data.data.count.foreignerPer);
          const visitorValues = {
            adultCount: data.data.count.adult_count,
            childCount: data.data.count.child_count,
            disabledCount: data.data.count.disabledperson_count,
            foreignerCount: data.data.count.foreigner_count,
            visitorTotal: data.data.count.totalVisiters,
          };
          setVisitorValues(visitorValues);
        } else if (type === "customTicket") {
          const ticketValues = {
            offlineTicketCount: data.data.count.offlinecount,
            onlineTicketCount: data.data.count.onlinecount,
            qrTicketCount: data.data.count.qrcount,
            ticketTotal: data.data.count.tickettotalcount,
          };
          setTicketValues(ticketValues);
          setOnlineTicketPercentage(data.data.count.onlineTicketPer);
          setOfflineTicketPercentage(data.data.count.offlineTicketPer);
          setQrTicketPercentage(data.data.count.qrTicketPer);
        } else if (type === "customVisitor") {
          setAdultTicketPercentage(data.data.count.adultPer);
          setChildTicketPercentage(data.data.count.childPer);
          setDisabledTicketPercentage(data.data.count.disabledPer);
          setForeignerTicketPercentage(data.data.count.foreignerPer);
          const visitorValues = {
            adultCount: data.data.count.adult_count,
            childCount: data.data.count.child_count,
            disabledCount: data.data.count.disabledperson_count,
            foreignerCount: data.data.count.foreigner_count,
            visitorTotal: data.data.count.totalVisiters,
          };
          setVisitorValues(visitorValues);
        } else {
          setOnlineTicketPercentage(data.data.count.onlineTicketPer);
          setOfflineTicketPercentage(data.data.count.offlineTicketPer);
          setQrTicketPercentage(data.data.count.qrTicketPer);
          setAdultTicketPercentage(data.data.count.adultPer);
          setChildTicketPercentage(data.data.count.childPer);
          setDisabledTicketPercentage(data.data.count.disabledPer);
          setForeignerTicketPercentage(data.data.count.foreignerPer);
          const visitorValues = {
            adultCount: data.data.count.adult_count,
            childCount: data.data.count.child_count,
            disabledCount: data.data.count.disabledperson_count,
            foreignerCount: data.data.count.foreigner_count,
            visitorTotal: data.data.count.totalVisiters,
          };
          setVisitorValues(visitorValues);
          const ticketValues = {
            offlineTicketCount: data.data.count.offlinecount,
            onlineTicketCount: data.data.count.onlinecount,
            qrTicketCount: data.data.count.qrcount,
            ticketTotal: data.data.count.tickettotalcount,
          };
          setTicketValues(ticketValues);
        }
      }
    } else {
      swal(message || "Something went wrong, Please try again", "", "error");
    }
  };

  const ticketCompareBarData = () => {
    const labels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const datasets = [
      {
        label: "Online Ticket",
        backgroundColor: "#fb7b00",
        borderWidth: 1,
        stack: 1,
        hoverBackgroundColor: "#fb7b00",
        hoverBorderColor: "#fb7b00",
        data:
          yearCountData.online && yearCountData.online.length
            ? yearCountData.online
            : [],
      },
      {
        label: "Offline Ticket",
        backgroundColor: "#0895e1",
        borderWidth: 1,
        stack: 1,
        hoverBackgroundColor: "#0895e1",
        hoverBorderColor: "#0895e1",
        data:
          countData.offline && yearCountData.offline.length
            ? yearCountData.offline
            : [],
      },
      {
        label: "QR Ticket",
        backgroundColor: "#156539",
        borderWidth: 1,
        stack: 1,
        hoverBackgroundColor: "#156539",
        hoverBorderColor: "#156539",
        data:
          yearCountData.qr && yearCountData.qr.length ? yearCountData.qr : [],
      },
    ];

    // const datasets = [
    //   {
    //     label: "Ticket Count",
    //     data: ticketCompareArray,
    //     // data: [890, 505, 490, 399, 756, 258, 456, 600, 850, 456, 854, 956],
    //     backgroundColor: ["#FB7B00"],
    //     borderColor: ["rgb(255, 99, 132)"],
    //   },
    // ];

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Ticket Count",
            color: "black",
            font: {
              size: 13,
              family: "Helvetica, Arial, sans-serif",
              weight: 500,
            },
          },
        },
        x: {
          title: {
            display: true,
            text: "Months",
            color: "black",
            font: {
              size: 13,
              family: "Helvetica, Arial, sans-serif",
              weight: 500,
            },
          },
        },
      },
    };

    var compData = {
      labels: labels,
      datasets: datasets,
    };

    setTicketCompareData(compData);
    setTicketCompareOption(options);
  };

  const getIncomeStatus = async () => {
    const postData = {
      zoo_id: Number(zooId),
      year: "year",
    };
    // if (collectionStartDate && collectionEndDate) {
    //   postData.start_date = customFunctions.getDate(collectionStartDate);
    //   postData.end_date = customFunctions.getDate(collectionEndDate);
    // }
    const payload = customFunctions.cleanObject(postData);
    const endPoint = Api.chartDataCount;
    const url = customFunctions.generateUrl(endPoint, payload);
    const { data, message } = await AuthApi.getDataFromServer(url);
    {
      if (data && data.success === true) {
        const incomearray = {
          onlineIncome: [],
          offlineIncome: [],
          qrIncome: [],
        };
        const ticketArray = [];
        const ticketQRArray = [];
        const ticketOnlineArray = [];
        const ticketOffileArray = [];

        if (
          data &&
          data.data &&
          data.data.getData &&
          data.data.getData.length > 0
        ) {
          data.data.getData.map((amount) => {
            incomearray.onlineIncome.push(amount.ticketOnlineAmount);
            incomearray.offlineIncome.push(amount.ticketOfflineAmount);
            incomearray.qrIncome.push(amount.ticketQrAmount);
            ticketArray.push(amount.totalTicketCount);
            ticketOnlineArray.push(amount.onlineCount);
            ticketOffileArray.push(amount.offlineCount);
            ticketQRArray.push(amount.qrCount);
          });
        }
        setIncomeStatus(incomearray);
        setTicketCompareArray(ticketArray);
        setYearCountData({
          qr: ticketQRArray,
          online: ticketOnlineArray,
          offline: ticketOffileArray,
        });
      } else {
        swal(message || "Something went wrong, Please try again", "", "error");
      }
    }
  };

  const getSelectedDateCollectionAmount = async () => {
    const postData = {
      zoo_id: Number(zooId),
      year: "year",
    };
    if (collectionStartDate && collectionEndDate) {
      postData.start_date = customFunctions.getDate(collectionStartDate);
      postData.end_date = customFunctions.getDate(collectionEndDate);
    }
    const payload = customFunctions.cleanObject(postData);
    const endPoint = Api.chartDataCount;
    const url = customFunctions.generateUrl(endPoint, payload);
    const { data, message } = await AuthApi.getDataFromServer(url);
    {
      if (data && data.success === true) {
        const incomearray = {
          onlineIncome: [],
          offlineIncome: [],
          qrIncome: [],
        };
        const ticketArray = [];

        if (
          data &&
          data.data &&
          data.data.getData &&
          data.data.getData.length > 0
        ) {
          data.data.getData.map((amount) => {
            incomearray.onlineIncome.push(amount.ticketOnlineAmount);
            incomearray.offlineIncome.push(amount.ticketOfflineAmount);
            incomearray.qrIncome.push(amount.ticketQrAmount);
            ticketArray.push(amount.totalTicketCount);
          });
        }
        setIncomeStatus(incomearray);
        setTicketCompareArray(ticketArray);
      } else {
        swal(message || "Something went wrong, Please try again", "", "error");
      }
    }
  };

  const monthData = [
    {
      label: "Jan",
      value: "01",
    },
    {
      label: "Feb",
      value: "02",
    },
    {
      label: "Mar",
      value: "03",
    },
    {
      label: "Apr",
      value: "04",
    },
    {
      label: "May",
      value: "05",
    },
    {
      label: "Jun",
      value: "06",
    },
    {
      label: "Jul",
      value: "07",
    },
    {
      label: "Aug",
      value: "08",
    },
    {
      label: "Sep",
      value: "09",
    },
    {
      label: "Oct",
      value: "10",
    },
    {
      label: "Nov",
      value: "11",
    },
    {
      label: "Dec",
      value: "12",
    },
  ];

  useEffect(() => {
    const currentMonth = new Date().getMonth() + Number(1);
    // var result = monthData.filter((month) => {
    //   return Number(month.value) <= Number(currentMonth);
    // });

    var selectedMonth = monthData.filter((month) => {
      return Number(month.value) == Number(currentMonth);
    });

    setSelectedMonth(selectedMonth[0]);
    // setMonthArray(result);
  }, []);

  useEffect(() => {
    if (lineCompareToggle === "month") {
      getBookingCompareData(selectedMonth);
    }
  }, [lineCompareToggle, selectedMonth]);

  const getBookingCompareData = async (selected) => {
    setShowLoader(true);
    let endPoint = `${
      Api.monthBookingGraphData
    }?zoo_id=${zooId}&month1=${Number(selected.value)}`;
    // if (firstMonth && secondMonth) {
    //   endPoint += `&month1=${firstMonth}&month2=${secondMonth}`
    // }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data.data && data.data.month1) {
        prepareMonthCompareData(data.data.month1);
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  useEffect(() => {
    getRedeemCount();
  }, []);

  const getRedeemCount = async (selected) => {
    setShowLoader(true);
    let endPoint = `${Api.redeemUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success === true) {
      setShowLoader(false);
      if (data.data) {
        setRedeemCount(data.data.redeemCount);
      }
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  const prepareMonthCompareData = (compareData) => {
    const datasets = [
      {
        label: "Online",
        data: [],
        backgroundColor: "#fb7b00",
        tension: 0.3,
        // fill: true,
        borderColor: "#fb7b00",
      },
      {
        label: "Offline",
        data: [],
        backgroundColor: "#0895e1",
        tension: 0.3,
        // fill: false,
        borderColor: "#0895e1",
      },
      {
        label: "QR",
        data: [],
        backgroundColor: "#156539",
        tension: 0.3,
        // fill: false,
        borderColor: "#156539",
      },
    ];
    var currentMonthDate = [];
    var offline = [];
    var qr = [];
    var compareLabel = [];
    compareData &&
      compareData &&
      compareData.length &&
      compareData.map((ch) => {
        currentMonthDate.push(ch.date);
        datasets.map((dt, i) => {
          if (dt.label === "Online") {
            datasets[i].data.push(ch.onCount.toString());
          }

          if (dt.label === "Offline") {
            datasets[i].data.push(ch.offCount.toString());
          }

          if (dt.label === "QR") {
            datasets[i].data.push(ch.qrCount.toString());
          }
        });
      });

    // compareLabel = current.concat(previous.filter((item) => current.indexOf(item) < 0))
    compareData1 = {
      labels: currentMonthDate,
      datasets: datasets,
    };
    setLineCompareData(compareData1);
  };

  const getStaffMembers = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.users}?page=1&limit=100&role_id=2`
    );
    if (
      data &&
      data.data &&
      data.data.response &&
      data.data.response.rows &&
      data.data.response.rows.length > 0
    ) {
      setStaffData(data.data.response.rows);
      forceUpdate(count + 1);
    } else {
      setStaffData([]);
      swal(message, "", "error");
    }
  };

  const getHolidayList = async () => {
    setShowLoader(true);
    let endpoint = `${Api.holiday}/list?page=1&limit=1000`;
    const { data, message } = await AuthApi.getDataFromServer(endpoint);
    const list = [];
    if (data.data && data.data.rows && data.data.rows.length > 0) {
      setShowLoader(false);
      data &&
        data.data &&
        data.data.rows.map((item) => {
          list.push(customFunctions.getDMYDateForCalendar(item.date));
        });
      setHolidayData(data.data.rows);
      setHolidayListData(list);
      forceUpdate(count + 1);
    } else {
      setShowLoader(false);
      setHolidayListData([]);
      setHolidayData([]);
      swal(message, "", "error");
    }
  };

  const downloadOnlineTicketReport = async (date) => {
    // setShowLoader(true);
    const postData = {
      booking_date: customFunctions.getDate(date),
      zoo_id: zooId,
    };
    // return
    const payload = customFunctions.cleanObject(postData);
    const endPoint = Api.onlineTicketReportUrl;
    const url = customFunctions.generateUrl(endPoint, payload);
    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data) {
      setShowLoader(false);
      window.open(data.data.filepath, "_blank");
    } else {
      setShowLoader(false);
      swal(message || "Something went wrong", "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <NotificationContainer />
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <div className="page-header">
        <div className="title-breadcrumb-section d-flex justify-content-between">
          <span>
            <h2 className="main-content-title tx-24 mg-b-5 mb-4">Dashboard</h2>
          </span>
          <span>
            <h2
              className="main-content-title tx-24 mg-b-5 mb-4 underline"
              style={{ cursor: "pointer" }}
              onClick={() => setOnlineTicketToggle(true)}
            >
              Download Online Ticket Report
            </h2>
          </span>
        </div>
      </div>
      <section className="content-area">
        <div className="row">
          <div class="col-sm-4">
            <div class="card dashboard-block11">
              <div class="card-title flex-shrink-0">
                <div class="align-items-center d-flex">
                  <img src="images/ticket.svg" />
                  <h4>
                    Monthly Tickets <br />{" "}
                    <span className="test">
                      {new Date().toLocaleString("default", { month: "long" })}{" "}
                      {new Date().getFullYear()}{" "}
                    </span>
                  </h4>
                  <h2>
                    <span>
                      <CountUp
                        start={
                          Number(totalMonthCount) > 10
                            ? Number(totalMonthCount) - 10
                            : 0
                        }
                        end={totalMonthCount}
                      />
                    </span>
                  </h2>
                </div>
              </div>
              <div class="card-body">
                <ul class="d-flex">
                  <li>
                    <span>Online</span>
                    <span class="ticket-count">
                      <CountUp
                        start={
                          Number(monthlyOnlineCount) > 10
                            ? Number(monthlyOnlineCount) - 10
                            : 0
                        }
                        end={monthlyOnlineCount}
                      />
                    </span>
                  </li>
                  <li>
                    <span>Offline</span>
                    <span class="ticket-count">
                      <CountUp
                        start={
                          Number(monthlyOfflineCount) > 10
                            ? Number(monthlyOfflineCount) - 10
                            : 0
                        }
                        end={monthlyOfflineCount}
                      />
                    </span>
                  </li>
                  <li>
                    <span>QR</span>
                    <span class="ticket-count">
                      <CountUp
                        start={
                          Number(monthlyQRCount) > 10
                            ? Number(monthlyQRCount) - 10
                            : 0
                        }
                        end={monthlyQRCount}
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="card dashboard-block11">
              <div class="card-title flex-shrink-0">
                <div class="align-items-center d-flex">
                  <img src="images/visitor.svg" />
                  <h4>
                    Monthly Visitors
                    <br />
                    <span className="test">
                      {new Date().toLocaleString("default", {
                        month: "long",
                      })}{" "}
                      {new Date().getFullYear()}
                    </span>
                  </h4>
                  <h2>
                    <span>
                      <CountUp
                        start={
                          Number(totalMonthlyCount) > 10
                            ? Number(totalMonthlyCount) - 10
                            : 0
                        }
                        end={totalMonthlyCount}
                      />
                    </span>
                  </h2>
                </div>
              </div>
              <div class="card-body">
                <ul class="d-flex">
                  <li>
                    <span>Adult</span>
                    <span class="ticket-count">
                      <CountUp
                        start={
                          Number(monthlyAdultCount) > 10
                            ? Number(monthlyAdultCount) - 10
                            : 0
                        }
                        end={monthlyAdultCount}
                      />
                    </span>
                  </li>
                  <li>
                    <span>Children</span>
                    <span class="ticket-count">
                      <CountUp
                        start={
                          Number(monthlyChildCount) > 10
                            ? Number(monthlyChildCount) - 10
                            : 0
                        }
                        end={monthlyChildCount}
                      />
                    </span>
                  </li>
                  {/* <li>
                    <span>Disabled</span>
                    <span class="ticket-count">
                      <CountUp
                        start={
                          Number(monthlyDisabledCount) > 10
                            ? Number(monthlyDisabledCount) - 10
                            : 0
                        }
                        end={monthlyDisabledCount}
                      />
                    </span>
                  </li> */}
                  <li>
                    <span>Foreigner</span>
                    <span class="ticket-count">
                      <CountUp
                        start={
                          Number(monthlyForeignerCount) > 10
                            ? Number(monthlyForeignerCount) - 10
                            : 0
                        }
                        end={monthlyForeignerCount}
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="card dashboard-block11 dashboard-block2">
              <div class="card-title flex-shrink-0">
                <div class="align-items-center d-flex">
                  <img src="images/staff.svg" />
                  <h4>Zoo Staff</h4>
                  <h2>
                    <span>
                      <CountUp
                        start={
                          Number(totalZooStaff) > 10
                            ? Number(totalZooStaff) - 10
                            : 0
                        }
                        end={totalZooStaff}
                      />
                    </span>
                  </h2>
                </div>
              </div>
            </div>
            <div class="card dashboard-block11 dashboard-block2">
              <div class="card-title flex-shrink-0">
                <div class="align-items-center d-flex">
                  <img src="images/animal-inventory.svg" />
                  <h4>Animal Inventory</h4>
                  <h2>
                    <span>
                      <CountUp start={100 - 10} end={100} />
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4">
            <div className="card charts-wrapper height-100">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h4 className="">
                      <span>Total Tickets</span>
                    </h4>
                    <h4>
                      <span className="total-text">
                        {ticketValues.ticketTotal}
                      </span>{" "}
                    </h4>
                  </div>
                  <div className="col pie-select">
                    <Form.Select
                      className="form-control charts-select"
                      value={ticketToggle}
                      onChange={(e) => {
                        setTicketToggle(e.target.value);
                        if (e.target.value === "custom") {
                          setTicketStartDate(new Date());
                          setTicketEndDate(new Date());
                          setTicketFilterToggle("");
                        } else {
                          setTicketStartDate(null);
                          setTicketEndDate(null);
                          setTicketFilterToggle("ticket");
                        }
                      }}
                    >
                      {donutFilter.map((ticket) => (
                        <option value={ticket.value}>{ticket.label}</option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                {ticketToggle === "custom" ? (
                  <div className="row mt-2 mb-0">
                    <div className="col">
                      <Datepicker
                        label="From Date"
                        className="form-control"
                        selected={ticketStartDate}
                        placeholder="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                        onChange={(e) => {
                          setTicketStartDate(e);
                          setTicketEndDate(null);
                        }}
                      />
                    </div>
                    <div className="col">
                      <Datepicker
                        label="To Date"
                        className="form-control"
                        selected={ticketEndDate}
                        minDate={ticketStartDate}
                        placeholder="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                        onChange={(e) => {
                          setTicketEndDate(e);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                <hr />
                <div className="row">
                  <div className="col">
                    <div className="pie-chart">
                      <Pie
                        style={{ maxHeight: "250px" }}
                        data={{
                          labels: ["Online Sold", "Counter Sold", "QR Sold"],
                          datasets: [
                            {
                              label: "Ticket Status",
                              backgroundColor: [
                                "#FB7B00",
                                "#0895E1",
                                "#156539",
                              ],
                              hoverBackgroundColor: [
                                "#FB7B00",
                                "#0895E1",
                                "#156539",
                              ],
                              data: [
                                Number(onlineTicketPercentage) || 0,
                                Number(offlineTicketPercentage) || 0,
                                Number(qrTicketPercentage) || 0,
                              ],
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          scaleBeginAtZero: true,
                          // maintainAspectRatio: true,
                          plugins: {
                            legend: {
                              // position: 'right',
                              display: false,
                            },
                            datalabels: {
                              backgroundColor: function (context) {
                                return context.dataset.backgroundColor;
                              },
                              formatter: (val) => `${val}%`,
                              borderRadius: 25,
                              borderWidth: 3,
                              color: "black",
                              font: {
                                weight: "bold",
                              },
                              padding: 6,
                            },
                            tooltip: {
                              callbacks: {
                                label: (ttItem) =>
                                  `${ttItem.label}: ${ttItem.parsed}%`,
                              },
                            },
                          },
                        }}
                      />
                      <div className="data-count">
                        <ul className="data-count-wrapper">
                          <li className="online-sold">
                            <span>Online Ticket</span>
                            <span className="ticket-count">
                              {ticketValues.onlineTicketCount}
                            </span>
                          </li>
                          <li className="offline-sold">
                            <span>Offline Ticket</span>
                            <span className="ticket-count">
                              {ticketValues.offlineTicketCount}
                            </span>
                          </li>
                          <li className="qr-sold">
                            <span>QR Ticket</span>
                            <span className="ticket-count">
                              {ticketValues.qrTicketCount}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div className="card charts-wrapper height-100">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h4 className="">Total Visitors</h4>
                    <h4>
                      <span className="total-text">
                        {visitorValues.visitorTotal}
                      </span>{" "}
                    </h4>
                  </div>
                  <div className="col pie-select">
                    <Form.Select
                      className="form-control charts-select"
                      value={visitorToggle}
                      onChange={(e) => {
                        setVisitorToggle(e.target.value);
                        if (e.target.value === "custom") {
                          setVisitorStartDate(new Date());
                          setVisitorEndDate(new Date());
                          setTicketFilterToggle("");
                        } else {
                          setVisitorStartDate(null);
                          setVisitorEndDate(null);
                          setTicketFilterToggle("visitor");
                        }
                      }}
                    >
                      {donutFilter.map((ticket) => (
                        <option value={ticket.value}>{ticket.label}</option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                {visitorToggle === "custom" ? (
                  <div className="row mt-2 mb-0">
                    <div className="col">
                      <Datepicker
                        label="From Date"
                        className="form-control"
                        selected={visitorStartDate}
                        placeholder="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                        onChange={(e) => {
                          setVisitorStartDate(e);
                          setVisitorEndDate(null);
                        }}
                      />
                    </div>
                    <div className="col">
                      <Datepicker
                        label="To Date"
                        className="form-control"
                        minDate={visitorStartDate}
                        selected={visitorEndDate}
                        placeholder="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                        onChange={(e) => {
                          setVisitorEndDate(e);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                <hr />
                <div className="row">
                  <div className="col">
                    <div className="pie-chart">
                      <Pie
                        style={{ maxHeight: "250px" }}
                        data={{
                          labels: [
                            "Adult",
                            "Children",
                            // "Disabled",
                            "Foreigner",
                          ],
                          datasets: [
                            {
                              label: "Ticket Status",
                              backgroundColor: [
                                "#FB7B00",
                                "#0895E1",
                                // "#156539",
                                "#BB658F",
                              ],
                              hoverBackgroundColor: [
                                "#FB7B00",
                                "#0895E1",
                                // "#156539",
                                "#BB658F",
                              ],
                              data: [
                                Number(adultTicketPercentage) || 0,
                                Number(childTicketPercentage) || 0,
                                // Number(disabledTicketPercentage) || 0,
                                Number(foreignerTicketPercentage) || 0,
                              ],
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          scaleBeginAtZero: true,
                          // maintainAspectRatio: true,
                          plugins: {
                            legend: {
                              // position: 'right',
                              display: false,
                            },
                            datalabels: {
                              backgroundColor: function (context) {
                                return context.dataset.backgroundColor;
                              },
                              formatter: (val) => `${val}%`,
                              borderRadius: 25,
                              borderWidth: 3,
                              color: "black",
                              font: {
                                weight: "bold",
                              },
                              padding: 6,
                            },
                            tooltip: {
                              callbacks: {
                                label: (ttItem) =>
                                  `${ttItem.label}: ${ttItem.parsed}%`,
                              },
                            },
                          },
                        }}
                      />
                      <div className="data-count">
                        <ul className="data-count-wrapper">
                          <li className="audult-tkt">
                            <span>Adult</span>
                            <span className="ticket-count">
                              {visitorValues.adultCount}
                            </span>
                          </li>
                          <li className="child-tkt">
                            <span>Children</span>
                            <span className="ticket-count">
                              {visitorValues.childCount}
                            </span>
                          </li>
                          {/* <li className="disabled-tkt">
                            <span>Disabled</span>
                            <span className="ticket-count">
                              {visitorValues.disabledCount}
                            </span>
                          </li> */}
                          <li className="foreigner-tkt">
                            <span>Foreigner</span>
                            <span className="ticket-count">
                              {visitorValues.foreignerCount}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-4 h-100">
            <div class="card dashboard-block11 dashboard-block2">
              <div class="card-title flex-shrink-0">
                <div class="align-items-center d-flex">
                  <img src="images/ticket.svg" />
                  <h4>Redeem Count</h4>
                  <h2>
                    <span>{redeemCount}</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="card calender-block  h-100 ">
              <div className="card-body">
                <h4 className="mb-0"> Calendar </h4>
                <hr />
                <Calendar
                  onChange={onChange}
                  value={value}
                  tileClassName={({ date, view }) => {
                    if (
                      holidayListData.find(
                        (x) => x === moment(date).format("DD-MM-YYYY")
                      )
                    ) {
                      return "highlight";
                    }
                  }}
                  // onClickDay={(value, event) => {
                  //   let result = holidayData.filter((item) => {
                  //     return (
                  //       customFunctions.getDMYDate(item.date).toString() ==
                  //       customFunctions.getDMYDate(value).toString()
                  //     );
                  //   });
                  //   if (result && result.length > 0) {
                  //     NotificationManager.success(result[0].event, "", 2000);
                  //   }
                  // }}

                  // tileDisabled={({ date }) => date.getDay() === 0}
                  // tileContent={({ activeStartDate, date, view }) => {
                  //   let result;
                  //   if (
                  //     (result = holidayListData.find(
                  //       (x) => x === moment(date).format("DD-MM-YYYY")
                  //     ))
                  //   ) {
                  //     return (
                  //       console.log("result", result),
                  //       (
                  //         <p
                  //           onMouseOver={() => console.log("over")}
                  //           onMouseEnter={
                  //             //do whatever you want
                  //             console.log("hi")
                  //           }
                  //           className="holiday-font"
                  //         >
                  //           {result}
                  //         </p>
                  //       )
                  //     );
                  //   }
                  // }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8">
            <Card className="height-100">
              <Card.Body>
                <div className="d-flex bd-highlight">
                  <div className="p-2 flex-grow-1 bd-highlight">
                    <h4 className="mb-0"> Ticket Comparision (Current Year)</h4>
                  </div>
                  <div className="p-2 bd-highlight">
                    <Form.Select
                      className={
                        lineCompareToggle == "year"
                          ? "form-control charts-select w-auto pull-right ticket-comparision-right"
                          : "form-control charts-select w-auto pull-right"
                      }
                      value={lineCompareToggle}
                      onChange={async (e) => {
                        if (
                          e.target.value === "year" ||
                          e.target.value === "month"
                        ) {
                          // var selectedMonth = monthData.filter((month) => {
                          //   return Number(month.value) == Number(currentMonth);
                          // });
                          // setSelectedMonth(selectedMonth[0]);
                          // await setBarGraphData({});
                          setCountData({
                            online: [],
                            offline: [],
                            qr: [],
                            date: [],
                          });
                          setBarEndDate(null);
                          setBarStartDate(null);
                        }
                        setLineCompareToggle(e.target.value);
                      }}
                    >
                      {chartFilter.map((ticket) => (
                        <option value={ticket.value}>{ticket.label}</option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="p-2 bd-highlight">
                    {lineCompareToggle == "month" && (
                      // <Button
                      // onClick={() => setCompareMonth(true)}
                      // >
                      //   Compare Month
                      // </Button>

                      <DropdownInput
                        placeholder="Select Month"
                        options={monthData}
                        defaultValue={selectedMonth}
                        onSelectHandler={(select) => {
                          setSelectedMonth(select);
                        }}
                      />
                    )}
                  </div>
                </div>
                {lineCompareToggle === "custom" ? (
                  <div className="row mt-2 mb-0">
                    <div className="col">
                      <Datepicker
                        label="From Date"
                        className="form-control"
                        selected={barStartDate}
                        placeholder="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                        // isClearable={true}
                        minDate={new Date(`${currentYear}/01/01`)}
                        maxDate={new Date(`${currentYear}/12/31`)}
                        onChange={(e) => {
                          setBarEndDate(null);
                          setBarStartDate(e);
                        }}
                      />
                    </div>
                    <div className="col">
                      <Datepicker
                        label="To Date"
                        className="form-control"
                        minDate={barStartDate}
                        maxDate={new Date(`${currentYear}/12/31`)}
                        selected={barEndDate}
                        placeholder="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                        onChange={(e) => {
                          setBarEndDate(e);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                <hr />
                <div className="row">
                  {lineCompareToggle === "year" &&
                    ticketCompareData &&
                    Object.values(ticketCompareData).length > 0 && (
                      <>
                        <div className="col-sm-12">
                          <Bar
                            options={ticketCompareOption}
                            data={ticketCompareData}
                            style={{ maxHeight: "250px" }}
                          />
                        </div>
                        {/* <div className="col-sm-4">
                          <div class="card bg-orange">
                            <div class="card-body">
                              <h4 className="text-center"> May 2022</h4>
                              <hr />
                              <ul class="dashboard-block2">
                                <li>
                                  {" "}
                                  Total Ticket <span class="count-text">20000</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="card bg-purple">
                            <div class="card-body">
                              <h4 className="text-center">April 2022</h4>
                              <hr />
                              <ul class="dashboard-block2">
                                <li>
                                  {" "}
                                  Total Income <span class="count-text">20000</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </>
                    )}
                  {lineCompareToggle === "custom" &&
                    barGraphData &&
                    Object.values(barGraphData).length > 0 && (
                      <>
                        <div className="col-sm-12">
                          <Bar
                            options={{
                              scales: {
                                x: {
                                  title: {
                                    display: true,
                                    text: "Days",
                                    color: "black",
                                    font: {
                                      size: 14,
                                      family: "Roboto",
                                    },
                                  },
                                },
                                y: {
                                  title: {
                                    display: true,
                                    text: "Ticket Count",
                                    color: "black",
                                    font: {
                                      size: 14,
                                      family: "Roboto",
                                    },
                                  },
                                },
                              },
                              responsive: true,
                              // type: "bar",
                              plugins: {
                                legend: {
                                  position: "bottom",
                                },
                                title: {
                                  display: true,
                                  text: "",
                                },
                                tooltip: {
                                  callbacks: {
                                    title: function (tooltipItem, data) {
                                      let compDate =
                                        new Date(barStartDate).getMonth() +
                                          1 ===
                                        new Date(barEndDate).getMonth() + 1;
                                      return compDate
                                        ? `${customFunctions.getMonthName(
                                            new Date(barStartDate).getMonth() +
                                              1
                                          )}` +
                                            "-" +
                                            tooltipItem[0].label
                                        : tooltipItem[0].label;
                                    },
                                  },
                                },
                              },
                            }}
                            // options={{
                            //   responsive: true,
                            //   legend: {
                            //     display: false,
                            //   },
                            //   type: "bar",
                            //   scales: {
                            //     xAxes: [{
                            //         stacked: true
                            //     }],
                            //     yAxes: [{
                            //         stacked: true
                            //     }]
                            // }
                            // }}
                            data={barGraphData}
                            style={{ maxHeight: "250px" }}
                          />
                        </div>
                      </>
                    )}
                  {lineCompareToggle === "month" && (
                    <>
                      <div className="">
                        <div className="charts-wrapper">
                          <div className="">
                            <div
                              className=""
                              style={{
                                fontFamily: "sans-serif",
                                textAlign: "center",
                              }}
                            >
                              {lineCompareData &&
                                Object.values(lineCompareData).length > 0 && (
                                  <Line
                                    data={lineCompareData}
                                    options={{
                                      scales: {
                                        x: {
                                          title: {
                                            display: true,
                                            text: "Days",
                                            color: "black",
                                            font: {
                                              size: 14,
                                              family: "Roboto",
                                            },
                                          },
                                        },
                                        y: {
                                          title: {
                                            display: true,
                                            text: "Ticket Count",
                                            color: "black",
                                            font: {
                                              size: 14,
                                              family: "Roboto",
                                            },
                                          },
                                        },
                                      },
                                      responsive: true,
                                      plugins: {
                                        legend: {
                                          position: "bottom",
                                        },
                                        title: {
                                          display: true,
                                          text: "",
                                        },
                                        tooltip: {
                                          callbacks: {
                                            title: function (
                                              tooltipItem,
                                              data
                                            ) {
                                              return (
                                                `${selectedMonth.label}` +
                                                "-" +
                                                tooltipItem[0].label
                                              );
                                            },
                                          },
                                        },
                                      },
                                    }}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-4">
            <div className="card height-100">
              <div className="card-body">
                <h4 className="mb-0"> Upcoming Holidays </h4>
                <hr />
                <div
                  className={
                    lineCompareToggle == "year"
                      ? "upcomming-holidays"
                      : "upcomming-holidays-month"
                  }
                >
                  <HolidayComponent />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-8">
            <div className="card">
              <div className="card-body">
                <div className="col">
                  <h4 className="mb-0"> Collection Amount (Current Year) </h4>
                  <div className="row mt-2 mb-0">
                    <div className="col">
                      <Datepicker
                        label="From Date"
                        className="form-control"
                        selected={collectionStartDate}
                        minDate={new Date(`${currentYear}/01/01`)}
                        maxDate={new Date(`${currentYear}/12/31`)}
                        placeholder="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                        // isClearable={true}
                        onChange={(e) => {
                          setCollectionEndDate(null);
                          setCollectionStartDate(e);
                        }}
                      />
                    </div>
                    <div className="col">
                      <Datepicker
                        label="To Date"
                        className="form-control"
                        // isClearable={true}
                        selected={collectionEndDate}
                        minDate={collectionStartDate}
                        maxDate={new Date(`${currentYear}/12/31`)}
                        placeholder="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                        onChange={(e) => {
                          setCollectionEndDate(e);
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                </div>
                {incomeStatus && Object.values(incomeStatus).length > 0 && (
                  <MultipleArea monthIncome={incomeStatus} />
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card height-100">
              <div className="card-body">
                <h4 className="mb-0"> Zoo Staff Members </h4>
                <hr />
                <div className="upcomming-holidays">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Department</th>
                      </tr>
                    </thead>
                    {staffData && staffData.length > 0 ? (
                      <tbody>
                        {staffData.map((staff) => (
                          <>
                            <tr class="trow ">
                              <td class="tcol ">{staff.name}</td>
                              <td class="tcol ">
                                <span>{staff.counter_name || "-"}</span>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    ) : (
                      <tr class="trow ">
                        <td
                          className="tcol text-center text-dark p-2"
                          colSpan={2}
                        >
                          No record found
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {compareMonth && (
        <CompareMonthComponent
          compareMonth={compareMonth}
          callback={(firstMonth, secondMonth) => {
            setLineCompareToggle("month");
            getBookingCompareData(firstMonth, secondMonth);
          }}
          popupClose={() => setCompareMonth(!compareMonth)}
        />
      )}
      {onlineTicketToggle && (
        <OnlineTicketPopUp
          show={onlineTicketToggle}
          popupClose={() => {
            setOnlineTicketToggle(!onlineTicketToggle);
          }}
          callBack={(date) => downloadOnlineTicketReport(date)}
        />
      )}
    </>
  );
}

export default Dashboard;
