import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "./containers/Dashboard/dashboard.component";
import Login from "./containers/authentication/Login/login.component";
import ForgetPassword from "./containers/authentication/Forget/forgetPassword.component";
import NotFound from "./containers/not-found/notFound.component";
import User from "./containers/User/user.component";
import AddUser from "./containers/User/addUser.component";
import Setting from "./containers/Setting/setting.component";
import Layout from "./components/Layout/layout.component";
import BookTicket from "./containers/BookTicket/bookTicket.component";
import CounterStaff from "./containers/CounterStaff";
import AddCounterStaff from "./containers/CounterStaff/addCounterStaff";
import ResetPassword from "./containers/authentication/Forget/resetPassword";
import Reports from "./containers/Reports/bookingReports";
import Profile from "./containers/Profile/profile.component";
import ManagePassword from "./containers/Profile/changePassword.component";
import Ticket from "./TicketBooking/containers/dashboard/ticket.component";
import customFunctions from "./helper/customFunctions";
import ScrollComponent from "./containers/scroll";
import Counter from "./containers/Counter";
import AddCounter from "./containers/Counter/addCounter.component";
import HolidayList from "./containers/Holiday/index";
import AddHoliday from "./containers/Holiday/addHoliday.component";
import TicketBooking from "./TicketBooking/containers/dashboard/TicketBooking";
import TransactionFailcomponent from "./TicketBooking/containers/dashboard/transactionFail.component";
import MaintenanceSetting from "./containers/Setting/maintenanceSetting.component";
import BilldeskReport from "./containers/BilldeskSettlementReport/billdeskReport";

function RouterFile() {
  let navigate = useNavigate();
  let params = window.location.pathname;
  let token = customFunctions.getLocalStorage("access_token");

  let layout =
    params !== "/" &&
    params !== "/ticket" &&
    params !== "/ticket-booking/qr" &&
    params !== "/ticket/fail" ? (

      <Layout>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/password/reset/:token" element={<ResetPassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/change-password" element={<ManagePassword />} />
          {/* <Route path="/visitors" element={<User />} /> */}
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/edit-user" element={<AddUser />} />
          <Route index path="/configuration" element={<Setting />} />
          {/* <Route index path="/book-ticket" element={<BookTicket />} /> */}
          <Route index path="/counter-staff" element={<CounterStaff />} />
          <Route
            index
            path="/add-counter-staff"
            element={<AddCounterStaff />}
          />
          <Route
            index
            path="/edit-counter-staff"
            element={<AddCounterStaff />}
          />
          <Route path="/reports" element={<Reports />} />
          <Route path="/scroll-lock" element={<ScrollComponent />} />
          <Route path="/counter" element={<Counter />} />
          <Route path="/add-counter" element={<AddCounter />} />
          <Route path="/edit-counter" element={<AddCounter />} />
          <Route path="/add-holiday" element={<AddHoliday />} />
          <Route path="/edit-holiday" element={<AddHoliday />} />
          <Route path="/holidays" element={<HolidayList />} />
          <Route path="/setting" element={<MaintenanceSetting />} />
          <Route path="/billdesk-settlement-report" element={<BilldeskReport />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    ) : (
      <Routes>
        <Route path="/" element={<TicketBooking />} />
        <Route path="/ticket-booking/qr" element={<TicketBooking />} />
        <Route path="/ticket/fail" element={<TransactionFailcomponent />} />
        <Route path="/ticket" element={<Ticket />} />
      </Routes>
    );

  return layout;
}

export default RouterFile;
