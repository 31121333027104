import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Image,
  Card,
  Form,
  Col,
  Row,
  Button,
  Accordion,
} from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet, TextInput } from "../../components/FormInputs";

function CounterStaff() {
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [title, setTitle] = useState("");
  const [statusToggle, setStatusToggle] = useState(false);
  const [statusId, setStatusId] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [count, forceUpdate] = useState(0);
  const [filterErr, setFilterErr] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState([]);
  const [applyBulkAction, setApplyBulkAction] = useState(false);

  const items = [{ to: "/", label: "Dashboard" }, { label: "Counter Staff" }];

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);
  const columns = [
    // {
    //   label: "Sr. No.",
    //   id: "index",
    // },
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Email",
      id: "email",
    },
    {
      label: "Mobile No",
      id: "mobile",
    },
    {
      label: "Counter Name",
      id: "counterName",
    },
    {
      label: "Status",
      id: "status",
    },
  ];

  useEffect(() => {
    setTitle("Counter Staff");
    const roleId = customFunctions.getLocalStorage("role_id");
    setRoleId(roleId);
  }, []);

  useEffect(() => {
    getUser(1);
  }, [roleId]);

  useEffect(() => {
    setTableData();
  }, [staffData]);

  const getUser = async (pageNo, clearFilter = false) => {
    if (!roleId) return;
    const page = pageNo || 1;
    setShowLoader(true);
    const hasSearch = searchKeyword !== "" && !clearFilter;
    let endpoint = hasSearch
      ? `${Api.users}?page=${page}&limit=${pagination.itemsPerPage}&role_id=2&keyword=${searchKeyword}`
      : `${Api.users}?page=${page}&limit=${pagination.itemsPerPage}&role_id=2`;
    const { data, message } = await AuthApi.getDataFromServer(endpoint);
    if (
      data &&
      data.data &&
      data.data.response &&
      data.data.response.rows &&
      data.data.response.rows.length > 0
    ) {
      setShowLoader(false);
      setPagination({
        currentPage: data.data.page,
        itemsPerPage: data.data.limit,
        totalItems: data.data.count,
      });
      setStaffData(data.data.response.rows);
      forceUpdate(count + 1);
    } else {
      setShowLoader(false);
      setStaffData([]);
      swal(message, "", "error");
    }
  };

  const setTableData = () => {
    const rowsData = [];
    staffData.map((dt, i) => {
      rowsData.push({
        id: dt.id,
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        name: (
          <>
            <a
              href="/"
              className="pl-2 ms-1"
              title="Edit Counter Staff"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate("/edit-counter-staff", {
                  state: dt.id,
                });
              }}
            >
              {dt.name}
              {dt.isCounterAdmin == "1" ? " (Counter Admin)" : ""}
            </a>
          </>
        ),
        email: dt.email,
        mobile: dt.mobile,
        counterName: dt.counter_name || "-",
        status: (
          <>
            <div className="switch-check">
              <Form.Check
                type="switch"
                id="custom-switch"
                checked={dt.isActive == "1"}
                onChange={() => {
                  setStatusToggle(true);
                  setStatusId(dt.isActive == "1" ? "Inactive" : "Active");
                  setStaffId(dt.id);
                }}
                title={dt.isActive == "1" ? "Active" : "Inactive"}
              />
            </div>
          </>
        ),
      });
    });
    setRows([...rowsData]);
  };

  const deleteUser = (id) => {
    if (id) {
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to change the status?",
        icon: "warning",
        dangerMode: true,
        buttons: true,
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result) {
          const { data, message } = await AuthApi.deleteDataFromServer(
            `${Api.users}?id=${id}`
          );
          if (data) {
            swal("User deleted successfully", "", "success", {
              closeOnClickOutside: false,
              allowOutsideClick: false,
              buttons: false,
              timer: 2000,
            }).then(() => {
              swal.close();
              getUser(pagination.currentPage);
            });
          } else {
            swal(message, "", "error", {
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
          }
        }
      });
    }
  };

  const changeStatus = async () => {
    if (!staffId) return;
    const isActive = statusId == "Active" ? "1" : "0";
    const endpoint = `${Api.userActiveInactive}?user_id=${staffId}&isactive=${isActive}`;
    const { data, message } = await AuthApi.putDataToServer(endpoint);
    if (data) {
      swal("Counter staff status changed successfully", "", "success", {
        closeOnClickOutside: false,
        allowOutsideClick: false,
      }).then(() => {
        setStatusToggle(false);
        setStaffId(null);
        setStatusId("");
        getUser(1);
      });
    } else {
      swal(message, "", "error");
    }
  };

  const filterSearch = (e) => {
    e.preventDefault();
    let filterSearchErr = "";
    let isValid = true;
    if (customFunctions.checkIfEmpty(searchKeyword)) {
      filterSearchErr = "Please enter name, email or mobile";
      setFilterErr(filterSearchErr);
      isValid = false;
    }

    if (isValid) {
      setFilterErr("");
      getUser(1);
    }
  };

  const onClear = (e) => {
    setFilterErr("");
    setSearchKeyword("");
    getUser(1, true);
    forceUpdate(count + 1);
  };

  const actions = [
    {
      actionTitle: "Delete",
      actionCallback: () => {
        setApplyBulkAction(true);
      },
    },
  ];

  const bulkDelete = async () => {
    const items = [];
    staffData &&
      staffData.map((id) => {
        if (selectedWorkOrderId.includes(id.id.toString())) {
          items.push(id.id);
        }
        return id;
      });

    const postData = {
      id: items,
    };
    const endPoint = `${Api.staffBulkDelete}`;
    const callback = AuthApi.deleteDataFromServer;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success === true) {
      setApplyBulkAction(false);
      swal("Counter-staff member deleted successfully!", "", "success");
      getUser();
    } else {
      setApplyBulkAction(false);
      swal(message, "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      {(statusToggle || applyBulkAction) && (
        <div className="sweetalert">
          <SweetAlert
            title={
              applyBulkAction
                ? "Are you sure, do you want to delete counter-staff member ?"
                : "Are you sure, do you want to change the status ?"
            }
            error
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              applyBulkAction ? bulkDelete() : changeStatus();
            }}
            onCancel={() => {
              setStatusToggle(false);
              setApplyBulkAction(false);
            }}
          />
        </div>
      )}

      <section className="content-area">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="card-title mb-0">Filters</h5>
            </Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={(e) => filterSearch(e)}>
                {filterErr && (
                  <div className="p-3  bg-danger text-white bg-danger-main table-red-bg mb-3">
                    <div className="img-text">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={(e) => onClear(e)}
                        src="/images/fail.svg"
                        alt="Success"
                      />{" "}
                      {filterErr}
                    </div>
                  </div>
                )}
                <Row className="mt-3">
                  <Col sm={3}>
                    <TextInput
                      value={searchKeyword}
                      placeholder="Search by name, email or mobile"
                      onInputChange={(e) => {
                        setSearchKeyword(e.target.value);
                      }}
                    />
                  </Col>

                  <Col sm={3}>
                    <div className="filter-btn pull-right filter-btn-report">
                      <Button variant="primary" type="submit" className="ms-4">
                        Apply
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        className="ms-4"
                        onClick={(e) => onClear(e)}
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="#"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  customFunctions.removeLocalStorage("counterEditId");
                  navigate("/add-counter-staff");
                }}
              >
                Add Counter Staff
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              rows={rows}
              showPagination
              showCheckbox
              bulkActions={actions}
              onSelectRowsIndexes={(indexes) => {
                setSelectedWorkOrderId(indexes);
              }}
              paginationProps={{
                ...pagination,
                activePage: pagination.currentPage,
                onPageChange: (pageNo) => {
                  getUser(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
    </>
  );
}

export default CounterStaff;
