import axios from 'axios';
import customFucntions from '../../helper/customFunctions';
import Swal from "sweetalert2";

export default {
  setupInterceptors: async () => {
    axios.interceptors.request.use(
      async (config) => {
        // const requestConfig = config;
        // requestConfig.headers['Content-Type'] = 'application/json';

        const configuration = config;
        if (configuration.url.indexOf('upload') > -1) {
          configuration.headers['Content-Type'] = 'multipart/form-data'; // eslint-disable-line
        } else {
          configuration.headers['Content-Type'] = 'application/json'; // eslint-disable-line
        }

        const token = customFucntions.getLocalStorage('access_token');
        if (token) configuration.headers['x-access-token'] = `${token}`; // eslint-disable-line
        return configuration;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const data = await error.response;

          if (
            data &&
            (data?.status === 401 ||
              data?.status === 403 ||
              data?.statusText === 'Unauthorized')
          ) {
            customFucntions.localStorageClear();
            if (window.location.pathname !== '/login') {
              localStorage.clear();
              // window.location.replace = '/login';
              window.location.reload();
            }
            return Promise.reject(error.response.data);
          }
        return Promise.reject(error.response);
      }
    );
  },
};
