/* eslint-disable */

import { Component } from 'react'

let environment = 'development'
export default class Api extends Component {
  static s3UrlnoProfile =
    'https://empportal-serverless.s3.ap-south-1.amazonaws.com/noProfile.jpeg'

  static newUrl = Api.getNewUrl()
  static loginUrl = `${Api.newUrl}users/login/`
  static logoutUrl = `${Api.newUrl}users/logout/`
  static verifyToken = `${Api.newUrl}users/verifytoken`
  static forgetPasswordUrl = `${Api.newUrl}users/forgotpassword`
  static resetPasswordUrl = `${Api.newUrl}users/resetpassword`
  static changePasswordUrl = `${Api.newUrl}users/createnewpassword`
  static cameraFees = `${Api.newUrl}camera/fees`
  static ticketFees = `${Api.newUrl}ticket/fees`
  static vehicleFees = `${Api.newUrl}vehicle/fees`
  static users = `${Api.newUrl}users`
  static ticketBookingRequest = `${Api.newUrl}ticket/booking/request`
  static userActiveInactive = `${Api.newUrl}users/activeinactive`
  static bookingReports = `${Api.newUrl}ticket/booking/get_ticket_reports`
  static staffUser = `${Api.newUrl}users/getUserByCounterstaffId`
  static csvPdfDownload = `${Api.newUrl}ticket/booking/get_reports`
  static allTickets = `${Api.newUrl}ticket/fees/id`
  static dashboardCount = `${Api.newUrl}ticket/booking/get_booking_type_count`
  static chartDataCount = `${Api.newUrl}ticket/booking/get_chart_data`
  static counterList = `${Api.newUrl}counter`
  static scrollLock = `${Api.newUrl}users/scroll_lock`
  static scrollLockStatus = `${Api.newUrl}users/getScrollLockStatus`
  static counterTicketCount = `${Api.newUrl}ticket/booking/ticket_counter_count`
  static staffBulkDelete = `${Api.newUrl}users/id`
  static bookingCompareData = `${Api.newUrl}ticket/booking/compareData`
  static counterActiveInactive = `${Api.newUrl}counter/active/inactive/counter`
  static counterAdminStatus = `${Api.newUrl}users/is_counter_admin`
  static monthBookingGraphData = `${Api.newUrl}ticket/booking/prepare/month/data`
  static counterData = `${Api.newUrl}users/addCounterData`
  static counterHistory = `${Api.newUrl}ticket/booking/ticket_scroll_lock_count`
  static holiday = `${Api.newUrl}holiday`
  static settingUrl = `${Api.newUrl}settings`
  static redeemUrl = `${Api.newUrl}dashboard/redeem/count`
  static onlineTicketReportUrl = `${Api.newUrl}dashboard/online/report`
  static uploadCSV = `${Api.newUrl}ticket/booking/uploadcsv`
  static billdeskReportDataUrl = `${Api.newUrl}ticket/booking/billdeskData`
  static dailyReportDataUrl = `${Api.newUrl}ticket/booking//get_ticket_daily/`
  static dailyTicketsUrl = `${Api.newUrl}ticket/booking/diffdata`
   static gardenListUrl = `${Api.newUrl}garden/all`

  constructor(props) {
    super(props)
    this.getNewUrl = this.getNewUrl.bind(this)
  }
  static getNewUrl() {
    let url = ''
    switch (environment) {
      case 'production':

      url=`https://ticketsapi.reviewtestlink.com/api/`

        // url = 'http://192.168.1.114:3001/api/'

        // url = process.env.REACT_APP_API_URL;
        // url = "https://zooticket.pmc.gov.in/api/";

        //production endpoint
        // url = "https://zooticket.pmc.gov.in/api/";

        // stagging endpoint
        // url = 'https://stagingzooticket.pmc.gov.in/api/'

        break
      case 'development':

      url=`https://ticketsapi.reviewtestlink.com/api/`

        // url = process.env.REACT_APP_API_URL_DEV;
        // url = 'http://192.168.1.114:3001/api/'

        //production endpoint
        // url = "https://zooticket.pmc.gov.in/api/";

        // stagging endpoint
        // url = 'https://stagingzooticket.pmc.gov.in/api/'

        // local endpoint
        // url = "http://192.168.1.158:3000/api/"

        break
      default:
        url=`https://ticketsapi.reviewtestlink.com/api/`

        // url = 'http://192.168.1.114:3001/api/'

        // url = process.env.REACT_APP_API_URL_DEV;
        // url = 'http://13.234.126.109:3000/';

        //production endpoint
        // url = "https://zooticket.pmc.gov.in/api/";

        // stagging endpoint
        // url = 'https://stagingzooticket.pmc.gov.in/api/'

        // local endpoint
        //  url = "http://192.168.1.159:3000/api/";

        break
    }
    return url
  }
}
