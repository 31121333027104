import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import { Card } from "react-bootstrap";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Table from "../../components/Table/simpletable.component";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import { Helmet } from "../../components/FormInputs";

function HolidayList() {
  const navigate = useNavigate();
  const [isShowLoader, setShowLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [holidayData, setHolidayData] = useState([]);
  const [title, setTitle] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [statusToggle, setStatusToggle] = useState(false);
  const [count, forceUpdate] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [applyBulkAction, setApplyBulkAction] = useState(false);
  const items = [{ to: "/", label: "Dashboard" }, { label: "Holidays" }];
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState([]);

  const initialPagination = {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const columns = [
    {
      label: "id",
      id: "Id",
      isDisplay: false,
    },
    {
      label: "Name",
      id: "event",
      render: (row) => {
        const rowData = row;
        return (
          <>
            <a
              href="javascript:void(0)"
              className="pl-2 ms-1"
              title="Edit Holiday"
              onClick={(e) => {
                e.preventDefault();
                navigate("/edit-holiday", {
                  state: { id: rowData.id },
                });
              }}
            >
              {rowData.event}
            </a>
          </>
        );
      },
    },
    {
      label: "Date",
      id: "date",
      render: (row) => {
        const rowData = row;
        return <p>{customFunctions.getDMYDate(rowData.date)}</p>;
      },
    },
    {
      label: "Description",
      id: "reason",
    },
  ];

  useEffect(() => {
    setTitle("Holidays");
    const roleId = customFunctions.getLocalStorage("role_id");
    setRoleId(roleId);
  }, []);

  useEffect(() => {
    getHoliday(1);
  }, [roleId]);

  useEffect(() => {
    setTableData();
  }, [holidayData]);

  const getHoliday = async (pageNo, clearFilter = false) => {
    if (!roleId) return;
    const page = pageNo || 1;
    setShowLoader(true);
    let endpoint = `${Api.holiday}/list?page=${page}&limit=${pagination.itemsPerPage}&findAll=true`;
    const { data, message } = await AuthApi.getDataFromServer(endpoint);
    if (data && data.data) {
      if (data && data.data) {
        setShowLoader(false);
        setPagination({
          currentPage: data.data.page,
          itemsPerPage: Number(data.data.limit),
          totalItems: data.data.count,
        });
      }
      if (data.data && data.data.rows && data.data.rows.length > 0) {
        setHolidayData(data.data.rows);
      } else {
        setHolidayData([]);
      }
      forceUpdate(count + 1);
    } else {
      setShowLoader(false);
      setHolidayData([]);
      swal(message, "", "error");
    }
  };

  const setTableData = () => {
    const rowsData = [];
    holidayData.map((dt, i) => {
      rowsData.push({
        index: (pagination.currentPage - 1) * pagination.itemsPerPage + (i + 1),
        ...dt,
      });
    });
    setRows([...rowsData]);
  };

  const actions = [
    {
      actionTitle: "Delete",
      actionCallback: () => {
        setApplyBulkAction(true);
      },
    },
  ];

  const bulkDelete = async () => {
    const items = [];
    holidayData &&
      holidayData.map((id) => {
        if (selectedWorkOrderId.includes(id.id.toString())) {
          items.push(id.id);
        }
        return id;
      });

    const postData = {
      id: items,
    };
    const endPoint = `${Api.holiday}/id`;
    const callback = AuthApi.deleteDataFromServer;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success === true) {
      setApplyBulkAction(false);
      swal(
        holidayData
          ? "Holidays deleted successfully!"
          : "Holiday deleted successfully!",
        "",
        "success"
      );
      getHoliday();
    } else {
      setApplyBulkAction(false);
      swal(message, "", "error");
    }
  };

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      {(statusToggle || applyBulkAction) && (
        <div className="sweetalert">
          <SweetAlert
            title={
              applyBulkAction
                ? "Are you sure, do you want to delete Holiday ?"
                : "Are you sure, do you want to change the status ?"
            }
            error
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              applyBulkAction ? bulkDelete() : changeStatus();
            }}
            onCancel={() => {
              setStatusToggle(false);
              setApplyBulkAction(false);
            }}
          />
        </div>
      )}

      {showDelete && (
        <div className="sweetalert">
          <SweetAlert
            title="Are you sure that you want to delete holiday ?"
            error
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              bulkDelete();
            }}
            onCancel={() => {
              setShowDelete(false);
            }}
          />
        </div>
      )}

      <section className="content-area">
        <Card className="mt-3">
          <Card.Header>
            <div className="d-flex align-items-center justify-content-end">
              <a
                href="#"
                className="underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/add-holiday");
                }}
              >
                Add Holiday
              </a>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              columns={columns}
              rows={rows}
              showPagination
              showCheckbox
              bulkActions={actions}
              onSelectRowsIndexes={(indexes) => {
                setSelectedWorkOrderId(indexes);
              }}
              paginationProps={{
                ...pagination,
                activePage: pagination.currentPage,
                onPageChange: (pageNo) => {
                  getHoliday(pageNo);
                },
              }}
              dropdownContainerClass="mb-3"
              emptyRender={() => (
                <p className="text-center mb-0">No record found</p>
              )}
            />
          </Card.Body>
        </Card>
      </section>
    </>
  );
}

export default HolidayList;
