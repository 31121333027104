import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Col, Row, Button } from 'react-bootstrap';
import { Helmet } from "../../components/FormInputs";
import customFunctions from "../../helper/customFunctions";
import AuthApi from "../../helper/authApi";
import Api from "../../helper/api";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader/index";
import axios from "axios";

function Setting() {
  const [isShowLoader, setShowLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [tickets, setTickets] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const items = [{ to: "/", label: "Dashboard" }, { label: "Configuration" }];

  useEffect(() => {
    setTitle("Configuration");
    const roleId = customFunctions.getLocalStorage('role_id');
    setRoleId(roleId);
  }, []);

  useEffect(() => {
    getTicketFees();
  }, [roleId])

  const getTicketFees = async () => {
    if (!roleId) return;
    setShowLoader(true);
    const api = `${Api.ticketFees}/id`;
    const url = customFunctions.generateUrl(api);
    // const { data } = await AuthApi.getDataFromServer(url);
    // if (
    //   data &&
    //   data.data &&
    //   data.data.result &&
    //   data.data.result.length > 0
    // ) {
    //   setShowLoader(false);
    //   data.data.result.map((dt) => {
    //     dt.count = 0;
    //     if (dt.name === "Disabled") {
    //       dt.note = "On Providing Proof";
    //       dt.price = "Free";
    //     }
    //     dt.inputToggle = false;
    //     dt.inputValue = dt.price;
    //   });
    //   setTickets(data.data.result);
    // } else {
    //   setShowLoader(false);
    //   swal(message, "", "error");
    // }


    axios
      .get(api, {
        headers: {
          "session-key": customFunctions
            .encryptKey("PMCZOO2$t@rkD!g!t@1", "46251737DBA8B")
            .toString(),
        },
      })
      .then((response) => {
        if (
          response?.data?.data &&
          response?.data?.data?.result &&
          response?.data?.data?.result?.length
        ) {
          setShowLoader(false);
          response?.data?.data?.result?.map((dt) => {
            dt.count = 0;
            if (dt.name === "Disabled") {
              dt.note = "On Providing Proof";
              dt.price = "Free";
            }
            dt.inputToggle = false;
            //previos price
            // dt.inputValue = dt.price;
            dt.inputValue = dt?.ticket_prices[0]?.price;
          });
          setTickets(response?.data?.data?.result);
        }
      })
      .catch((error) => {
        if (error.response && [401, 403].includes(error.response.status)) {
          swal("Unauthorized Access", "", "error");
        } else {
          swal("Something went wrong! Please try again later", "", "error");
        }
      });
  };

  const updateToggle = (index) => {
    var ticketData = [...tickets];
    ticketData[index].inputToggle = !ticketData[index].inputToggle;
    if (ticketData[index].inputToggle == false) {
      //previos price
      // ticketData[index].inputValue = ticketData[index].price;
      ticketData[index].inputValue = ticketData[index]?.ticket_prices[0]?.price;
    }
    setTickets(ticketData)
  }

  const updatePrice = async (e, ticket) => {
    e.preventDefault();
    if (ticket.inputValue == '') {
      swal('Please enter ticket price', '', 'error');
      return;
    }
    const payload = {
      id: Number(ticket.id),
      name: ticket.name,
      price: Number(ticket.inputValue)
    }
    const endpoint = Api.ticketFees;
    const { data, message } = await AuthApi.putDataToServer(endpoint, payload);
    if (data) {
      swal('Ticket price change successfully!', '', 'success');
      getTicketFees();
    }
    else {
      swal(message, '', 'error');
    }
  }

  return (
    <>
      <Helmet title={title} />
      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area">
        <div className="row setting-wrap">
          {tickets &&
            tickets.length > 0 &&
            tickets.map((tk, i) => (
              <div className="col-sm-4">
                <div key={i} className="card ticket-info border-0">
                  <div>
                    {
                      //previos price
                      // tk.price !== "Free" && (
                        tk?.ticket_prices[0]?.price !== "Free" && (
                        <img
                          src="/images/edit.svg"
                          height="15px"
                          width="15px"
                          className="card-edit-icon"
                          onClick={() => updateToggle(i)}
                          title='Edit ticket price'
                        />
                      )
                    }
                  </div>

                  <div className="card-body bg-white h-100 p-3">
                    {
                      <>
                        <h3 className={!tk.note ? "cs-border-bottom" : ""}>{tk.name}</h3>
                        {tk.description ? (
                          <h6 className="cs-border-bottom">{tk.description}</h6>
                        ) :
                          (<h6 className="cs-border-bottom">per head</h6>)
                        }
                      </>
                    }
                    {
                      //previos price
                      // tk.price === "Free" ? <h2>Free</h2> :
                      tk?.ticket_prices[0]?.price === "Free" ? <h2>Free</h2> :
                        tk.inputToggle ?
                          <>
                            <Row className="pricing-cont-wrap">
                              <Col >
                                <input
                                  type="text"
                                  maxLength={8}
                                  className="form-control"
                                  placeholder="Enter Price"
                                  value={tk.inputValue}
                                  onChange={(e) => {
                                    var ticketData = [...tickets];
                                    var val = e.target.value;
                                    var positiveNumber = /^[0-9]+[0-9]*$/;
                                    if (val == "" || val.match(positiveNumber)) {
                                      ticketData[i].inputValue = val;
                                      setTickets(ticketData);
                                    }
                                  }}

                                // onChange={(e) => {
                                //   var ticketData = [...tickets];
                                //   ticketData[i].inputValue = customFunctions.numberMaskingDecimal(e.target.value);
                                //   setTickets(ticketData);
                                // }}
                                />
                              </Col>
                              <Col>
                                <Button onClick={(e) => updatePrice(e, tk)} >
                                  Submit
                                </Button>
                              </Col>
                            </Row>
                          </> :
                          //previos price
                          // <><h2>₹{tk.price}</h2> </>
                          <><h2>₹{tk?.ticket_prices[0]?.price}</h2> </>
                    }
                    {tk.other_note && <p>per head</p>}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </section>
    </>
  );
}

export default Setting;
