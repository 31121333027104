import React, { useState } from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import { Datepicker } from "../../components/FormInputs";

const OnlineTicketPopUp = (props) => {
  const { show } = props;
  const [selectedDate, setSelectedDate] = useState(new Date().setDate(new Date().getDate() - 3));
  const [selectedDateErr, setSelectedDateErr] = useState('');

  const validateForm = () => {
    if (!selectedDate) {
      setSelectedDateErr('The date field is required');
      return
    }
    else {
      props.popupClose();
      props.callBack(selectedDate);
    }
  }

  return (
    <>
      <Modal
        className={''}
        size={'md'}
        show={show}
        onHide={props.popupClose}
        centered
      >
        <div className="add-task-popup">
          <Modal.Header closeButton >
            <Modal.Title>
              <div className="title">
                <h5>{'Select Date'}</h5>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Row className="mb-4">
                <Col sm={9} className="admin-datepicker">
                  <Form.Label>Date  <span style={{ color: 'red' }}>{'*'}</span>{' '}</Form.Label>
                  <Datepicker
                    className="form-control"
                    selected={selectedDate}
                    onChange={(e) => {
                      setSelectedDateErr('')
                      setSelectedDate(e);
                    }}
                    autoComplete="off"
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    maxDate={new Date().setDate(new Date().getDate() - 3)}
                    stopInputChange={(e) => { e.preventDefault(); }}
                  />
                  {
                    selectedDateErr && (
                      <span className="text-danger">{selectedDateErr}</span>
                    )
                  }
                </Col>
              </Row>

              <Row className="d-flex">
                <Col>
                  <div className="form-group mt-1">
                    <Button
                      type="submit"
                      variant="primary"
                      onClick={(e) => { e.preventDefault(); validateForm() }}
                    >
                      Submit
                    </Button>
                    <Button
                      onClick={(e) => {
                        props.popupClose();
                      }}
                      variant="secondary"
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          </Modal.Body>
        </div>
      </Modal>
    </>
  )
}

export default OnlineTicketPopUp;

